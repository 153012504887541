import ApiHelper from "@/api/ApiHelper"

export default {
    async reportById(table, reportId, filter, clist = []) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + localStorage.token, },
        };

        let data = JSON.stringify({
            filter: filter,
            token: localStorage.token,
            clist: clist,
        })

        // return Api.post("/v1/qb/trading/reports/" + reportId + "/run?table_id=" + table, 
        // data, headers);
        let url = "/v1/qb/trading/reports/" + reportId + "/run?table_id=" + table;

        return await ApiHelper.getResult(url, data, headers);
    },
    async getOwnersByCargoRid(rid) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        // return Api.post("/v1/cargoes/" + rid + "/offers", 
        // data, headers);

        let url = "/v1/cargoes/" + rid + "/offers";
        return await ApiHelper.getResult(url, data, headers);
    },

    async updateFilterForOwnerByCargoRid(rid, filter) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
            filter: filter,
        })

        // return Api.post("/v1/cargoes/" + rid + "/filter", 
        // data, headers);
        let url = "/v1/cargoes/" + rid + "/filter";
        return await ApiHelper.getResult(url, data, headers);
    },

    async getCargoByRid(rid) {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        // return Api.get("/v1/cargoes/" + rid, 
        // data, headers);
        let url = "/v1/cargoes/" + rid;
        return await ApiHelper.getResult(url, data, headers);
    },

    async deleteByRid(rid) {
        var headers = {
            method: "DELETE",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        // return Api.delete("/v1/cargoes/rid/" + rid, 
        // data, headers);
        let url = "/v1/cargoes/rid/" + rid;
        return await ApiHelper.getResult(url, data, headers);
    },

    async getCargoSummaryByRid(rid) {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        // return Api.get("/v1/cargoes/" + rid + "/offers/summary", 
        // data, headers);
        let url = "/v1/cargoes/" + rid + "/offers/summary";
        return await ApiHelper.getResult(url, data, headers);
    },

    async addQuoteForCargoByID(id, fid) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
            fid: fid,
        })

        // return Api.post("/v1/cargoes/" + id + "/quote", 
        // data, headers);
        let url = "/v1/cargoes/" + id + "/quote";
        return await ApiHelper.getResult(url, data, headers);
    },

}