import ApiHelper from "@/api/ApiHelper"

export default {
    async getCharterers() {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { 
                "Content-Type": "application/json", 
                "Authorization": "Bearer " + localStorage.token, 
            },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        });

        let url = "/v1/fm/charterers";

        return await ApiHelper.getResult(url, data, headers);
    },

    async getCharterer(id) {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { 
                "Content-Type": "application/json", 
                "Authorization": "Bearer " + localStorage.token, 
            },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        });

        let url = `/v1/fm/charterers/${id}`;

        return await ApiHelper.getResult(url, data, headers);
    },

    async addCharterer(newCharterer) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { 
                "Content-Type": "application/json", 
                "Authorization": "Bearer " + localStorage.token, 
            },
        };

        let data = JSON.stringify({
            token: localStorage.token,
            ...newCharterer,
        });

        let url = "/v1/fm/charterers";

        return await ApiHelper.getResult(url, data, headers);
    },

    async deleteCharterer(id) {
        var headers = {
            method: "DELETE",
            redirect: "follow",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token, 
            },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        });

        let url = `/v1/fm/charterers/${id}`;

        return await ApiHelper.getResult(url, data, headers);
    },

    async updateCharterer(id, updateCharterer) {
        var headers = {
            method: "PUT",
            redirect: "follow",
            headers: { 
                "Content-Type": "application/json", 
                "Authorization": "Bearer " + localStorage.token, 
            },
        };

        let data = JSON.stringify({
            token: localStorage.token,
            ...updateCharterer,
        });

        let url = `/v1/fm/charterers/${id}`;

        return await ApiHelper.getResult(url, data, headers);
    },
}