
const fieldsMap = [
    {
      id: 1,
      title: "Nomination",
      show: false,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                key: 484,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 605,
                width: 100,
                value: "",
              },
            ],
            [
                // {
              //   componentType: "multichoice-rel",
              //   labelId: 96,
              //   nameId: 6, // lookupTargetFieldId
              //   forFieldId: 95, // lookupReferenceFieldId
              //   tableId: "brxis6w8r", // masterTableId
              //   // masterTableKeyFid
              //   // "lookupReferenceFieldId": 95,
              //   // "lookupTargetFieldId": 6,
              //   // key: 96,
              //   key: 95,
              //   width: 25,
              //   value: "",
              //   toggleShip: true,
              // },
              {
                componentType: "multichoice-rel",
                labelId: 96,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 95, // lookupReferenceFieldId
                tableId: "brxis6w8r", // masterTableId
                // masterTableKeyFid
                // "lookupReferenceFieldId": 95,
                // "lookupTargetFieldId": 6,
                // key: 96,
                key: 95,
                width: 100,
                value: "",
                toggleShip: true,
              },
              {
                key: 1171,
                width: 25,
                value: "",
                toggleShip: false,
              },
              {
                key: 771,
                width: 25,
                value: "",
              },
              {
                key: 777,
                width: 25,
                value: "",
              },
              {
                key: 807,
                width: 25,
                value: "",
              },
              {
                key: 1012,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 239,
                width: 25,
                value: "",
                toggleShip: true,
              },
              {
                key: 598,
                width: 25,
                value: "",
                toggleShip: true,
              },
              {
                key: 599,
                width: 25,
                value: "",
                toggleShip: true,
              },
              {
                key: 621,
                width: 25,
                value: "",
                toggleShip: true,
              },
            ],
            [
              {
                key: 622,
                width: 25,
                value: "",
                toggleShip: true,
              },
              {
                key: 620,
                width: 25,
                value: "",
                toggleShip: true,
              },
              {
                key: 1220,
                width: 25,
                value: "",
                toggleShip: true,
              },
              {
                key: 1221,
                width: 25,
                value: "",
                toggleShip: true,
              },
            ],
            [
              {
                key: 1172,
                width: 25,
                value: "",
                toggleShip: false,
              },
              {
                key: 1173,
                width: 25,
                value: "",
                toggleShip: false,
              },
              {
                key: 1174,
                width: 25,
                value: "",
                toggleShip: false,
              },
              {
                key: 1175,
                width: 25,
                value: "",
                toggleShip: false,
              },
            ],
            [
              {
                key: 1176,
                width: 25,
                value: "",
                toggleShip: false,
              },
              {
                key: 1186,
                width: 25,
                value: "",
                toggleShip: false,
              },
              {
                key: 1177,
                width: 25,
                value: "",
                toggleShip: false,
              },
              {
                key: 1223,
                width: 25,
                value: "",
                toggleShip: false,
              },
              {
                key: 1224,
                width: 25,
                value: "",
                toggleShip: false,
              },
            ],
            /* [
              {
                key: 544,
                width: 25,
                value: "",
              },
            ], */
            // [
            //   {
            //     componentType: "multichoice-rel",
            //     labelId: 544,
            //     nameId: 6, // lookupTargetFieldId
            //     forFieldId: 543, // lookupReferenceFieldId
            //     tableId: "brxixht2m", // masterTableId
            //     key: 543,
            //     width: 100,
            //     value: "",
            //   },
            // ],
            [
              {
                key: 100,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 464,
                width: 25,
                value: "",
              },

              {
                key: 465,
                width: 25,
                value: "",
              },
            ],

            [
              {
                key: 101,
                width: 100,
                value: "",
              },
              {
                key: 135,
                width: 25,
                value: "",
              },
            ],

            /* {
              key: 464,
              width: 50,
              value: "",
            }, */
            /* [
              {
                componentType: "multichoice-rel",
                labelId: 464,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 463, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 463,
                width: 50,
                value: "",
              },

              {
                key: 465,
                width: 50,
                value: "",
              },
              {
                key: 101,
                width: 50,
                value: "",
              },
              {
                key: 135,
                width: 50,
                value: "",
              },
            ], */
          ],
        },
        {
          title: "Vessel Specs Check",
          showTitle: true,
          body: [
            [
              {
                key: 745,
                width: 100,
                value: "",
              },
              {
                key: 600,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 736,
                width: 25,
                value: "",
              },
              {
                key: 715,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 740,
                width: 25,
                value: "",
              },
              {
                key: 716,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 744,
                width: 25,
                value: "",
              },
              {
                key: 717,
                width: 25,
                value: "",
              },
            ],
          ],
        },
        
        {
          title: "Arrival Itinerary",
          showTitle: true,
          body: [
            /* [
              {
                key: 105,
                width: 100,
                value: "",
              },
              {
                key: 625,
                width: 100,
                value: "",
              },
            ], */
            [
              {
                key: 106,
                width: 25,
                value: "",
              },
              {
                key: 1170,
                width: 25,
                value: "",
              },
              {
                key: 249,
                width: 25,
                value: "",
              },
              {
                key: 155,
                width: 25,
                value: "",
              },
              {
                key: 117,
                width: 25,
                value: "",
              },
              {
                key: 252,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 602,
                width: 100,
                value: "",
              },
              {
                key: 629,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 769,
                width: 100,
                value: "",
              },
            ],
          ],
        },
        {
          title: "Arrival Itinerary",
          showTitle: false,
          body: [
            [
              {
                key: 105,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 625,
                width: 100,
                value: "",
              },
            ],
          ],
        },
        {
          title: "Sailing Itinerary",
          showTitle: true,
          body: [
            [
              {
                key: 153,
                width: 100,
                value: "",
              },
              {
                key: 157,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 125,
                width: 100,
                value: "",
              },
              {
                key: 256,
                width: 100,
                value: "",
              },
              {
                key: 747,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 107,
                width: 100,
                value: "",
              },
              {
                key: 255,
                width: 100,
                value: "",
              },
              {
                key: 156,
                width: 100,
                value: "",
              },
              {
                key: 124,
                width: 100,
                value: "",
              },
              {
                key: 154,
                width: 100,
                value: "",
              },
              {
                key: 158,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 590,
                width: 100,
                value: "",
              },
              {
                key: 260,
                width: 100,
                value: "",
              },
              {
                key: 591,
                width: 100,
                value: "",
              },
              {
                key: 592,
                width: 100,
                value: "",
              },
              {
                key: 593,
                width: 100,
                value: "",
              },
              {
                key: 594,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 126,
                width: 100,
                value: "",
              },
              {
                key: 259,
                width: 100,
                value: "",
              },
              {
                key: 144,
                width: 100,
                value: "",
              },
            ],
            // [
            //   {
            //     componentType: "googleMap",
            //     width: 100,
            //     value: "",
            //   },
            // ],
          ],
        },
        {
          title: "Sailing Itinerary",
          showTitle: false,
          body: [
            [
              {
                componentType: "googleMap",
                width: 100,
                value: "",
              },
            ],
          ],
        },
        {
          title: "Agents",
          showTitle: true,
          body: [
            /* {
              key: 94,
              width: 100,
              value: "",
            }, */
            [
              {
                componentType: "multichoice-rel",
                labelId: 94,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 93, // lookupReferenceFieldId
                tableId: "brxi28a76", // masterTableId
                key: 93,
                width: 100,
                value: "",
              },

              /* {
              key: 324,
              width: 50,
              value: "",
            }, */
              {
                componentType: "multichoice-rel",
                labelId: 324,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 323, // lookupReferenceFieldId
                tableId: "brxi28a76", // masterTableId
                key: 323,
                width: 50,
                value: "",
              },
            ],
            [
              /* {
              key: 494,
              width: 50,
              value: "",
            }, */
              {
                componentType: "multichoice-rel",
                labelId: 494,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 493, // lookupReferenceFieldId
                tableId: "brxi28a76", // masterTableId
                key: 493,
                width: 50,
                value: "",
              },
            ],
          ],
        },
        {
          title: "Estimated Intake & Pre-Stow",
          showTitle: true,
          body: [
            [
              {
                key: 102,
                width: 25,
                value: "",
              },

              /* {
              key: 496,
              width: 25,
              value: "",
            }, */
              {
                componentType: "multichoice-rel",
                labelId: 496,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 495, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 495,
                width: 25,
                value: "",
              },

              {
                key: 325,
                width: 25,
                value: "",
              },

              {
                key: 498,
                width: 25,
                value: "",
              },
              {
                key: 327,
                width: 25,
                value: "",
              },
              {
                key: 500,
                width: 25,
                value: "",
              },

              {
                key: 474,
                width: 25,
                value: "",
              },
              {
                key: 475,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 233,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 415,
                width: 50,
                value: "",
              },
            ],
            [
              {
                key: 417,
                width: 50,
                value: "",
              },
              {
                key: 735,
                width: 50,
                value: "",
              },
            ],
            [
              {
                key: 103,
                width: 50,
                value: "",
              },
              /* {
                key: 507,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 507,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 506, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 506,
                width: 50,
                value: "",
              },
            ],
            [
              {
                key: 333,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 509,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 509,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 508, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 508,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 335,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 511,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 511,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 510, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 510,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 501,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 513,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 513,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 512, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 512,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 503,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 515,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 515,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 514, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 514,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 504,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 517,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 517,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 516, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 516,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 505,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 519,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 519,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 518, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 518,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 502,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 521,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 521,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 520, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 520,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 1105,
                width: 25,
                value: "",
              },
              {
                key: 1104,
                width: 25,
                value: "",
              },
              {
                key: 1107,
                width: 25,
                value: "",
              },
              {
                key: 1106,
                width: 25,
                value: "",
              },
            ],
          ],
        },
        {
          title: "Stow",
          showTitle: true,
          body: [
            /* [
              {
                key: 419,
                width: 50,
                value: "",
              },
            ], */
            [
              {
                key: 526,
                width: 50,
                value: "",
              },
              {
                componentType: "multichoice-rel",
                labelId: 542,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 541, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 541,
                width: 50,
                value: "",
              },
            ] ,
            [
              {
                key: 104,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 528,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 528,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 527, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 527,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 328,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 530,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 530,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 529, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 529,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 331,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 532,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 532,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 531, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 531,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 525,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 534,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 534,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 533, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 533,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 524,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 536,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 536,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 535, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 535,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 523,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 538,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 538,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 537, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 537,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],

            [
              {
                key: 522,
                width: 50,
                value: "",
                toggleShow: true,
              },
              /* {
                key: 540,
                width: 50,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 540,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 539, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 539,
                width: 50,
                value: "",
                toggleShow: true,
              },
            ],
            [
              {
                key: 419,
                width: 50,
                value: "",
              },
            ],
            [
              // {
              //   key: 526,
              //   width: 50,
              //   value: "",
              // },
              // /* {
              //   key: 542,
              //   width: 50,
              //   value: "",
              // }, */
              // {
              //   componentType: "multichoice-rel",
              //   labelId: 542,
              //   nameId: 9, // lookupTargetFieldId
              //   forFieldId: 541, // lookupReferenceFieldId
              //   tableId: "brx74qi6y", // masterTableId
              //   key: 541,
              //   width: 50,
              //   value: "",
              // },
              {
                key: 706,
                width: 50,
                value: "",
              },
              {
                key: 707,
                width: 50,
                value: "",
              },
            ],
          ],
        },
      ],
    },
    {
      id: 3,
      title: "Freight Fixtures",
      show: true,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                key: 338,
                width: 50,
                value: "",
              },
              {
                key: 365,
                width: 50,
                value: "",
              },
              {
                key: 808,
                width: 50,
                value: "",
              },
            ],
            [
              /* {
              key: 91,
              width: 100,
              value: "",
            }, */
              // {
              //   componentType: "multichoice-rel",
              //   labelId: 92,
              //   nameId: 6, // lookupTargetFieldId
              //   forFieldId: 91, // lookupReferenceFieldId
              //   tableId: "brxixht2m", // masterTableId
              //   key: 91,
              //   width: 100,
              //   value: "",
              // },
              {
                componentType: "multichoice-rel",
                labelId: 1197,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 1196, // lookupReferenceFieldId
                tableId: "bs7zzssmr", // masterTableId
                key: 1196,
                width: 100,
                value: "",
              },
            ],
            /* [
              {
                key: 339,
                width: 100,
                value: "",
              },
            ], */

            [
              {
                key: 352,
                width: 25,
                value: "",
              },
              {
                key: 353,
                width: 25,
                value: "",
              },

              {
                componentType: "multichoice-rel",
                labelId: 375,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 374, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 374,
                width: 25,
                value: "",
              },

              {
                key: 472,
                width: 25,
                value: "",
              },
            ],

            [
              {
                key: 366,
                width: 25,
                value: "",
              },
              {
                key: 368,
                width: 25,
                value: "",
              },

              {
                componentType: "multichoice-rel",
                labelId: 378,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 377, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 377,
                width: 25,
                value: "",
              },

              {
                key: 473,
                width: 25,
                value: "",
              },
            ],

            [
              {
                key: 367,
                width: 25,
                value: "",
              },
              {
                key: 369,
                width: 25,
                value: "",
              },

              {
                componentType: "multichoice-rel",
                labelId: 381,
                nameId: 9, // lookupTargetFieldId
                forFieldId: 380, // lookupReferenceFieldId
                tableId: "brx74qi6y", // masterTableId
                key: 380,
                width: 25,
                value: "",
              },
            ],

            // /* {
            //   key: 374,
            //   width: 25,
            //   value: "",
            // }, */
            // [
            //   {
            //     key: 341,
            //     width: 100,
            //     value: "",
            //   },
            //   {
            //     key: 342,
            //     width: 100,
            //     value: "",
            //   },
            // ],

            // [
            //   {
            //     key: 346,
            //     width: 50,
            //     value: "",
            //   },
            //   {
            //     key: 486,
            //     width: 50,
            //     value: "",
            //   },
            // ],

            // [
            //   {
            //     key: 347,
            //     width: 50,
            //     value: "",
            //   },
            //   {
            //     key: 487,
            //     width: 50,
            //     value: "",
            //   },
            // ],
            // [
            //   {
            //     key: 343,
            //     width: 100,
            //     value: "",
            //   },
            //   {
            //     key: 803,
            //     width: 100,
            //     value: "",
            //   },
            // ],
            // /* [
            //   {
            //     key: 803,
            //     width: 100,
            //     value: "",
            //   },
            // ], */
            // [
            //   {
            //     key: 718,
            //     width: 50,
            //     value: "",
            //   },
            //   {
            //     key: 751,
            //     width: 50,
            //     value: "",
            //   },
            // ],
            // [
            //   {
            //     key: 709,
            //     width: 50,
            //     value: "",
            //   },
            //   {
            //     key: 710,
            //     width: 50,
            //     value: "",
            //   },
            //   {
            //     key: 711,
            //     width: 50,
            //     value: "",
            //   },
            // ],
          ],
        },
        {
          title: "Vessel",
          showTitle: true,
          body: [
            [
              {
                key: 709,
                width: 50,
                value: "",
              },
              {
                key: 710,
                width: 50,
                value: "",
              },
              {
                key: 711,
                width: 50,
                value: "",
              },
            ],

            [
              {
                key: 970,
                width: 50,
                value: "",
              },
              {
                key: 971,
                width: 50,
                value: "",
              },
            ],

            [
              {
                key: 972,
                width: 50,
                value: "",
              },
            ],

            // [
            //   {
            //     key: 366,
            //     width: 25,
            //     value: "",
            //   },
            //   {
            //     key: 368,
            //     width: 25,
            //     value: "",
            //   },

            //   {
            //     componentType: "multichoice-rel",
            //     labelId: 378,
            //     nameId: 9, // lookupTargetFieldId
            //     forFieldId: 377, // lookupReferenceFieldId
            //     tableId: "brx74qi6y", // masterTableId
            //     key: 377,
            //     width: 25,
            //     value: "",
            //   },

            //   {
            //     key: 473,
            //     width: 25,
            //     value: "",
            //   },
            // ],

            // [
            //   {
            //     key: 367,
            //     width: 25,
            //     value: "",
            //   },
            //   {
            //     key: 369,
            //     width: 25,
            //     value: "",
            //   },

            //   {
            //     componentType: "multichoice-rel",
            //     labelId: 381,
            //     nameId: 9, // lookupTargetFieldId
            //     forFieldId: 380, // lookupReferenceFieldId
            //     tableId: "brx74qi6y", // masterTableId
            //     key: 380,
            //     width: 25,
            //     value: "",
            //   },
            // ],

            // /* {
            //   key: 374,
            //   width: 25,
            //   value: "",
            // }, */
            // [
            //   {
            //     key: 341,
            //     width: 100,
            //     value: "",
            //   },
            //   {
            //     key: 342,
            //     width: 100,
            //     value: "",
            //   },
            // ],

            // [
            //   {
            //     key: 346,
            //     width: 50,
            //     value: "",
            //   },
            //   {
            //     key: 486,
            //     width: 50,
            //     value: "",
            //   },
            // ],

            // [
            //   {
            //     key: 347,
            //     width: 50,
            //     value: "",
            //   },
            //   {
            //     key: 487,
            //     width: 50,
            //     value: "",
            //   },
            // ],
            // [
            //   {
            //     key: 343,
            //     width: 100,
            //     value: "",
            //   },
            //   {
            //     key: 803,
            //     width: 100,
            //     value: "",
            //   },
            // ],
            // /* [
            //   {
            //     key: 803,
            //     width: 100,
            //     value: "",
            //   },
            // ], */
            // [
            //   {
            //     key: 718,
            //     width: 50,
            //     value: "",
            //   },
            //   {
            //     key: 751,
            //     width: 50,
            //     value: "",
            //   },
            // ],
            
          ],
        },
        {
          title: "Load",
          showTitle: true,
          body: [
            [
              {
                key: 149,
                width: 100,
                value: "",
              },
            ],

            [
              {
                key: 341,
                width: 50,
                value: "",
              },
              {
                key: 342,
                width: 50,
                value: "",
              },
              {
                label: "Potential Layday",
                key: 1024,
                width: 50,
                value: "",
              },
              {
                label: "Potential Cancelling",
                key: 1025,
                width: 50,
                value: "",
              },
              {
                label: "Forecast ETA",
                key: 1198,
                width: 50,
                value: "",
              },
            ],

            [
              {
                key: 346,
                width: 50,
                value: "",
              },
              {
                key: 486,
                width: 50,
                value: "",
              },
              {
                key: 890,
                width: 50,
                value: "",
              },
              {
                key: 894,
                width: 50,
                value: "",
              },
            ],
          ],
        },
        {
          title: "Load Options",
          showTitle: true,
          body: [
            [
              {
                key: 1035,
                width: 25,
                value: "",
              },
              {
                key: 1040,
                width: 25,
                value: "",
              },
              {
                key: 1045,
                width: 25,
                value: "",
              },
              {
                key: 1050,
                width: 25,
                value: "",
              }
            ],
            [
              {
                key: 1036,
                width: 25,
                value: "",
              },
              {
                key: 1041,
                width: 25,
                value: "",
              },
              {
                key: 1046,
                width: 25,
                value: "",
              },
              {
                key: 1051,
                width: 25,
                value: "",
              },
              {
                key: 1056,
                width: 25,
                value: "",
              }
            ],
            [
              {
                key: 1037,
                width: 25,
                value: "",
              },
              {
                key: 1042,
                width: 25,
                value: "",
              },
              {
                key: 1047,
                width: 25,
                value: "",
              },
              {
                key: 1052,
                width: 25,
                value: "",
              },
              {
                key: 1057,
                width: 25,
                value: "",
              }
            ],
            [
              {
                key: 1038,
                width: 25,
                value: "",
              },
              {
                key: 1043,
                width: 25,
                value: "",
              },
              {
                key: 1048,
                width: 25,
                value: "",
              },
              {
                key: 1053,
                width: 25,
                value: "",
              },
              {
                key: 1058,
                width: 25,
                value: "",
              }
            ],
            [
              {
                key: 1039,
                width: 25,
                value: "",
              },
              {
                key: 1044,
                width: 25,
                value: "",
              },
              {
                key: 1049,
                width: 25,
                value: "",
              },
              {
                key: 1054,
                width: 25,
                value: "",
              },
              {
                key: 1059,
                width: 25,
                value: "",
              }
            ],
            [
              {
                key: 1082,
                width: 25,
                value: "",
              },
              {
                key: 1083,
                width: 25,
                value: "",
              },
              {
                key: 1084,
                width: 25,
                value: "",
              },
              {
                key: 1085,
                width: 25,
                value: "",
              },
              {
                key: 1086,
                width: 25,
                value: "",
              }
            ],
          ]
        },
        {
          title: "Discharge",
          showTitle: true,
          body: [
            [
              {
                key: 150,
                width: 100,
                value: "",
              },
            ],

            [
              {
                key: 347,
                width: 50,
                value: "",
              },
              {
                key: 487,
                width: 50,
                value: "",
              },
              {
                key: 891,
                width: 50,
                value: "",
              },
              {
                key: 895,
                width: 50,
                value: "",
              },
            ],

            [
              {
                key: 718,
                width: 50,
                value: "",
              },
              {
                key: 751,
                width: 50,
                value: "",
              },
            ],

            [
              {
                key: 983,
                width: 50,
                value: "",
              },
            ],

            [
              {
                key: 984,
                width: 50,
                value: "",
              },
            ],
          ],
        },
        {
          title: "Laytime",
          showTitle: true,
          body: [
            [
              {
                key: 979,
                width: 50,
                value: "",
              },
              {
                key: 980,
                width: 50,
                value: "",
              },
            ],

            [
              {
                key: 981,
                width: 50,
                value: "",
              },
            ],
            [
              {
                key: 982,
                width: 50,
                value: "",
              },
            ],
          ],
        },
        {
          title: "Freight",
          showTitle: true,
          body: [
            [
              {
                key: 344,
                width: 100,
                value: "",
              },
              {
                key: 714,
                width: 100,
                value: "",
              },
              {
                key: 708,
                width: 100,
                value: "",
              },
              {
                key: 762,
                width: 100,
                value: "",
              },
            ],

            [
              {
                key: 355,
                width: 50,
                value: "",
              },
              {
                key: 356,
                width: 50,
                value: "",
              },
            ],

            [
              {
                key: 701,
                width: 50,
                value: "",
              },
              {
                key: 702,
                width: 50,
                value: "",
              },
            ],

            [
              {
                key: 345,
                width: 50,
                value: "",
              },
            ],

            [
              {
                key: 587,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 761,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 343,
                width: 100,
                value: "",
              },
              {
                key: 1213,
                width: 100,
                value: "",
              },
            ],
          ],
        },
        {
          title: "Reminders",
          showTitle: true,
          body: [
            [
              {
                key: 349,
                width: 50,
                value: "",
              },
              {
                key: 359,
                width: 50,
                value: "",
              },
            ],

            [
              {
                key: 348,
                width: 50,
                value: "",
              },
              {
                key: 360,
                width: 50,
                value: "",
              },
            ],
            [
              {
                key: 361,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 1006,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 362,
                width: 100,
                value: "",
              },
              {
                key: 809,
                width: 100,
                value: "",
              },
              {
                key: 812,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 652,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 1160,
                width: 100,
                value: "",
              },
              {
                key: 1164,
                width: 100,
                value: "",
              }
            ],
          ],
        },
        {
          title: "Tasks",
          showTitle: true,
          body: [
            // [
            //   {
            //     key: 191,
            //     width: 50,
            //     value: "",
            //   },
            // ],

            [
              {
                key: 275,
                width: 50,
                value: "",
              },
            ],

            [
              {
                key: 276,
                width: 100,
                value: "",
              },
            ],
          ],
        },
      ],
    },
    {
      id: 2,
      title: "Ports",
      show: true,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            /* {
              key: 138,
              width: 25,
              value: "",
            }, */
            [
              {
                componentType: "multichoice-rel",
                labelId: 138,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 137, // lookupReferenceFieldId
                tableId: "br3isji2e", // masterTableId
                key: 137,
                width: 25,
                value: "",
              },

              /* {
              key: 273,
              width: 25,
              value: "",
            }, */
              {
                componentType: "multichoice-rel",
                labelId: 273,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 271, // lookupReferenceFieldId
                tableId: "br3isji2e", // masterTableId
                key: 271,
                width: 25,
                value: "",
              },

              /* {
              key: 148,
              width: 25,
              value: "",
            }, */
              {
                componentType: "multichoice-rel",
                labelId: 148,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 147, // lookupReferenceFieldId
                tableId: "br3isn8nb", // masterTableId
                key: 147,
                width: 25,
                value: "",
              },

              {
                key: 149,
                width: 25,
                value: "",
              },
            ],
            [
              /* {
              key: 142,
              width: 50,
              value: "",
            }, */
              {
                componentType: "multichoice-rel",
                labelId: 142,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 141, // lookupReferenceFieldId
                tableId: "br3isji2e", // masterTableId
                key: 141,
                width: 50,
                value: "",
              },

              /* {
              key: 144,
              width: 50,
              value: "",
            }, */
              {
                componentType: "multichoice-rel",
                labelId: 144,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 143, // lookupReferenceFieldId
                tableId: "br3isji2e", // masterTableId
                key: 143,
                width: 50,
                value: "",
              },
            ],
            [
              /* {
              key: 140,
              width: 25,
              value: "",
            }, */
              {
                componentType: "multichoice-rel",
                labelId: 140,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 139, // lookupReferenceFieldId
                tableId: "br3isji2e", // masterTableId
                key: 139,
                width: 25,
                value: "",
              },
              {
                componentType: "multichoice-rel",
                labelId: 274,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 272, // lookupReferenceFieldId
                tableId: "br3isji2e", // masterTableId
                key: 272,
                width: 25,
                value: "",
              },
              {
                componentType: "multichoice-rel",
                labelId: 146,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 145, // lookupReferenceFieldId
                tableId: "br3isn8nb", // masterTableId
                key: 145,
                width: 25,
                value: "",
              },
              {
                key: 150,
                width: 25,
                value: "",
              },
            ],

            /* {
              key: 274,
              width: 25,
              value: "",
            }, */
            [

              /* {
              key: 146,
              width: 25,
              value: "",
            }, */
            ],
          ],
        },
        /* {
          title: "Estimated Intake",
          showTitle: true,
          body: [
            {
              key: 102,
              width: 50,
              value: "",
            },
            {
              key: 496,
              width: 50,
              value: "",
            },
          ],
        }, */
      ],
    },
    {
      id: 22,
      title: "Accounting",
      show: true,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                key: 363,
                width: 50,
                value: "",
              },
              {
                key: 364,
                width: 50,
                value: "",
              },
            ],
            [
              {
                key: 766,
                width: 50,
                value: "",
              },
              {
                key: 1222,
                width: 50,
                value: "",
              },
              {
                key: 767,
                width: 50,
                value: "",
              },
            ],
            [
              {
                key: 703,
                width: 50,
                value: "",
              },
            ],
            [
              {
                key: 1199,
                width: 50,
                value: "",
              },
            ],
            [
              {
                key: 1200,
                width: 50,
                value: "",
              },
            ],
          ],
        },
      ],
    },

    {
      id: 4,
      title: "Laytime",
      show: true,
      body: [
        {
          title: "Facts",
          showTitle: true,
          body: [
            [
              {
                key: 840,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 870,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 876,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 843,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 844,
                width: 25,
                value: "",
              },
            ],
          ],
        },
        {
          title: "Vessel Laytime",
          showTitle: true,
          body: [
            [
              {
                key: 851,
                width: 25,
                value: "",
              },
              {
                componentType: "holidaysReport",
                type: "load",
                customId: "851_hr",
                width: 100,
                value: "",
                startDateId: 153,
                startAltDateId: 106,
                endDateId: 157,
                endAltDateId: 117,
              },
            ],
            [
              {
                key: 282,
                label: "Charterers",
                width: 25,
                value: "",
              },
              {
                key: 719,
                label: "Modified",
                width: 25,
                value: "",
              },
              {
                key: 285,
                label: "Owners",
                width: 25,
                value: "",
              },

              {
                key: 853,
                label: "Modified",
                width: 50,
                value: "",
              },
              {
                key: 563,
                label: "Result",
                width: 50,
                value: "",
              },
              // {
              //   key: 850,
              //   label: "Notes",
              //   width: 50,
              //   value: "",
              // },
            ],

            [
              {
                key: 852,
                width: 25,
                value: "",
              },
              {
                componentType: "holidaysReport",
                type: "load",
                customId: "852_hr",
                width: 100,
                value: "",
                startDateId: 917,
                startAltDateId: 106,
                endDateId: 918,
                endAltDateId: 117,
              },
            ],
            [
              {
                key: 845,
                label: "Charterers",
                width: 25,
                value: "",
              },
              {
                key: 848,
                label: "Modified",
                width: 25,
                value: "",
              },
              {
                key: 846,
                label: "Owners",
                width: 25,
                value: "",
              },

              {
                key: 720,
                label: "Modified",
                width: 50,
                value: "",
              },
              {
                key: 849,
                label: "Result",
                width: 50,
                value: "",
              },
              // {
              //   key: 648,
              //   label: "Notes",
              //   width: 50,
              //   value: "",
              // },
            ],

            [
              {
                key: 835,
                width: 25,
                value: "",
              },
              
              {
                componentType: "holidaysReport",
                type: "discharge",
                width: 100,
                value: "",
                startDateId: 154,
                startAltDateId: 107,
                endDateId: 158,
                endAltDateId: 124,
              },
            ],

            [
              {
                key: 288,
                label: "Charterers",
                width: 25,
                value: "",
              },
              {
                key: 721,
                label: "Modified",
                width: 25,
                value: "",
              },
              {
                key: 290,
                label: "Owners",
                width: 50,
                value: "",
              },
              {
                key: 722,
                label: "Modified",
                width: 25,
                value: "",
              },
              {
                key: 567,
                label: "Result",
                width: 25,
                value: "",
              },
              // {
              //   key: 650,
              //   label: "Notes",
              //   width: 50,
              //   value: "",
              // },
            ],

            [
              {
                key: 837,
                width: 25,
                value: "",
              },
              
              {
                componentType: "holidaysReport",
                customId: "837_hr",
                type: "discharge",
                width: 100,
                value: "",
                startDateId: 593,
                startAltDateId: 107,
                endDateId: 594,
                endAltDateId: 124,
              },
            ],

            [
              {
                key: 826,
                label: "Charterers",
                width: 25,
                value: "",
              },
              {
                key: 832,
                label: "Modified",
                width: 25,
                value: "",
              },
              {
                key: 827,
                label: "Owners",
                width: 50,
                value: "",
              },
              {
                key: 833,
                label: "Modified",
                width: 25,
                value: "",
              },
              {
                key: 829,
                label: "Result",
                width: 25,
                value: "",
              },
              {
                key: 831,
                label: "Notes",
                width: 50,
                value: "",
              },
            ],
          ],
        },
        {
          title: "Commodity Laytime",
          showTitle: true,
          body: [
            [
              {
                componentType: "text",
                // key: 466,
                width: 100,
                value: "FOB",
              },
            ],
            [
              {
                componentType: "text",
                // key: 466,
                width: 100,
                value: "CNF",
              },
            ],
           /*  [
              {
                key: 280,
                width: 25,
                value: "",
              },
              {
                key: 724,
                width: 25,
                value: "",
              },
              {
                key: 278,
                width: 50,
                value: "",
              },
              {
                key: 726,
                width: 25,
                value: "",
              },
              {
                key: 565,
                width: 25,
                value: "",
              },
              {
                key: 649,
                width: 50,
                value: "",
              },
            ], */

            [
              {
                key: 867,
                width: 25,
                value: "",
              },
            ],

            [
              {
                key: 294,
                width: 25,
                label: "Charterers",
                value: "",
              },
              {
                key: 723,
                width: 25,
                label: "Modified",
                value: "",
              },
              {
                key: 292,
                width: 50,
                label: "Buyers",
                value: "",
              },
              {
                key: 725,
                width: 25,
                label: "Modified",
                value: "",
              },
              {
                key: 569,
                width: 25,
                label: "Result",
                value: "",
              },
              // {
              //   key: 651,
              //   width: 50,
              //   label: "Notes",
              //   value: "",
              // },
            ],

            [
              {
                key: 646,
                width: 100,
                value: "",
              },
            ],
          ],
        },
        /* {
          title: "Holydays",
          showTitle: false,
          body: [
            [
              {
                componentType: "holidaysReport",
                width: 100,
                value: "",
              },
            ],
          ],
        }, */
      ],
    },
    {
      id: 5,
      title: "Commodity Contract",
      show: true,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                key: 404,
                width: 25,
                value: "",
              },
              // {
              //   key: 405,
              //   width: 25,
              //   value: "",
              // },
              // {
              //   key: 406,
              //   width: 25,
              //   value: "",
              // },
              {
                key: 407,
                width: 25,
                value: "",
                colspan: 1,
              },
            ],
            [
              {
                componentType: "documentFilter",
                // key: 466,
                width: 100,
                value: "",
              },
            ],
          ],
        },

        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                componentType: "documentReportAll",
                // key: 466,
                width: 100,
                value: "",
              },
            ],
          ],
        },

        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                componentType: "documentReport20",
                // key: 466,
                width: 100,
                value: "",
              },
            ],
          ],
        },
      ],
    },
    /* {
      id: 23,
      title: "Map",
      show: true,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                componentType: "googleMap",
                width: 100,
                value: "",
              },
            ],
          ],
        },
      ],
    }, */
    { 
      id: 24,
      title: "header",
      show: false,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                key: 42,
                width: 25,
                value: "",
              },
            ],
          ],
        },
      ],
    },
    /*  {
      id: 6,
      title: "Dates",
      show: true,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                key: 659,
                width: 25,
                value: "",
              },
              {
                key: 660,
                width: 25,
                value: "",
              },
              {
                key: 661,
                width: 25,
                value: "",
              },
              {
                key: 662,
                width: 25,
                value: "",
              },
              {
                key: 663,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 664,
                width: 25,
                value: "",
              },
              {
                key: 665,
                width: 25,
                value: "",
              },
              {
                key: 666,
                width: 25,
                value: "",
              },
              {
                key: 667,
                width: 25,
                value: "",
              },
              {
                key: 668,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 669,
                width: 25,
                value: "",
              },
              {
                key: 670,
                width: 25,
                value: "",
              },
              {
                key: 671,
                width: 25,
                value: "",
              },
              {
                key: 672,
                width: 25,
                value: "",
              },
              {
                key: 673,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 674,
                width: 25,
                value: "",
              },
              {
                key: 675,
                width: 25,
                value: "",
              },
              {
                key: 676,
                width: 25,
                value: "",
              },
              {
                key: 677,
                width: 25,
                value: "",
              },
              {
                key: 678,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 679,
                width: 25,
                value: "",
              },
              {
                key: 680,
                width: 25,
                value: "",
              },
              {
                key: 681,
                width: 25,
                value: "",
              },
              {
                key: 682,
                width: 25,
                value: "",
              },
              {
                key: 683,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 684,
                width: 25,
                value: "",
              },
              {
                key: 685,
                width: 25,
                value: "",
              },
              {
                key: 686,
                width: 25,
                value: "",
              },
              {
                key: 687,
                width: 25,
                value: "",
              },
              {
                key: 688,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 689,
                width: 25,
                value: "",
              },
              {
                key: 690,
                width: 25,
                value: "",
              },
              {
                key: 691,
                width: 25,
                value: "",
              },
              {
                key: 692,
                width: 25,
                value: "",
              },
              {
                key: 693,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 694,
                width: 25,
                value: "",
              },
              {
                key: 695,
                width: 25,
                value: "",
              },
              {
                key: 696,
                width: 25,
                value: "",
              },
              {
                key: 697,
                width: 25,
                value: "",
              },
              {
                key: 698,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 699,
                width: 25,
                value: "",
              },
              {
                key: 700,
                width: 25,
                value: "",
              },
            ],
          ],
        },
      ],
    }, */
    /* {
          key: 6,
          width: 50,
          value: "",
        },
        {
          key: 11,
          width: 50,
          value: "",
        },
        {
          key: 12,
          width: 50,
          value: "",
        },
        {
          key: 13,
          width: 50,
          value: "",
        },
        {
          key: 7,
          width: 50,
          value: "",
        },
        {
          key: 8,
          width: 50,
          value: "",
        },
        {
          key: 9,
          width: 50,
          value: "",
        },
        {
          key: 14,
          width: 50,
          value: "",
        }, */
  ];


export default {
    fieldsMap,
}