<template>
  <div class="report-panel">
    <div class="report__title" v-if="gettingReport || reportData.length != 0">
      <div class="report__collapse" @click="showBody = !showBody">
        <span v-if="showBody">-</span>
        <span v-else>+</span>
      </div>
      <h2 @click="showBody = !showBody">{{ title }}</h2>
      <div class="create-record-wrap">
        <button
          class="create-record"
          @click="
            () => {
              this.$router.push({
                name: 'CreateCargoRecord',
                params: {},
              });
            }
          "
        >
          New Cargo
        </button>
      </div>
    </div>
    <div class="preloader" v-if="gettingReport">
      <CustomLoader />
    </div>
    <div v-else-if="reportData.length != 0 && showBody">

      <input v-model="searchText" class="search-input" placeholder="Search..." @change="filteredItems()">

      <div class="report-body wrap-scroll">
        <table class="report-table">
          <thead>
            <tr class="report-table__head" style="position: sticky">
              <th class="report-table__sticky report-table__col-pencil"></th>
              <template
              v-for="(item, columnIndex) in clist"
              :key="item"

              >
                <th
                  v-if="this.isShow(item)"
                  :class="[this.styleForCol(item, columnIndex)]"
                >
                  <div>
                    {{ this.prepNameForColumnTitle(objFields[item].label) }}
                  </div>
                </th>
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-for="(group, index) in filteredItems" :key="index">
              <tr
                v-if="group.type == 'group'"
                class="header-block"
                @click="
                  () => {
                    this.showGroup[group.key] = !this.showGroup[group.key];
                  }
                "
              >
                <td class="report-table__sticky header-block__body" style="text-align: center; width: 50px;">
                  {{ (this.showGroup[group.key] ? "- " : "+ ") }}
                </td>
                <template
                  v-for="(item, columnIndex) in clist"
                  :key="item"
                >
                  <template
                    v-if="this.isShow(item)"
                  >
                    <td
                    v-if="columnIndex == 0"
                    :class="[this.styleForColHeader(item), 'header-block__body', 'report-table__sticky']"
                  >
                    <div style="padding-left: 8px;">
                      {{ group.text }}
                    </div>
                  </td>
                  <td 
                    v-else
                    :class="[this.styleForColHeader(item)]"
                    >
                   {{  }}
                  </td>
                  </template>
                </template>
                
                <!-- <td colspan="15" class="report-table__sticky header-block__body">
                  {{ (this.showGroup[group.key] ? "- " : "+ ") + group.text }}
                </td> -->
              </tr>
              <template v-for="(row, indexc) in group.children" :key="indexc">
                <tr v-if="row.type == 'record' && this.showGroup[group.key]">
                  <td class="report-table__sticky report-table__col-pencil">
                    <div
                      class="record__edit-button"
                      @click="
                        () => {
                          this.$router.push({
                            name: 'CargoRecord',
                            params: { rid: row.data[3].value, open: true },
                          });
                        }
                      "
                    ></div>
                  </td>
                  <template
                    v-for="(item, columnIndex) in clist"
                    :key="item">
                    
                    <td
                      v-if="this.isShow(item)"
                      :class="[this.styleForCol(item, columnIndex)]"
                    >
                      <div>
                        <!-- <div 
                  @input="(event) => textareaInput(event, index, item)" contentEditable="true">{{ row.data[item].value }}</div>
                <textarea v-model="row.data[item].value"></textarea> -->
                        <!-- <textarea
                  v-if="
                    this.objFields[item].fieldType == 'text' ||
                    this.objFields[item].fieldType == 'number'
                  "
                  :value="row.data[item].value"
                  @input="(event) => textareaInput(event, index, item)"
                ></textarea>
                <a
                  v-else-if="this.objFields[item].fieldType == 'url'"
                  :href="row.data[item].value"
                  >{{ this.objFields[item].label }}</a
                > -->
                        <div
                          v-if="
                            this.fidsForFFModal.includes(item) &&
                            !!row.data[item].value
                          "
                          class="item-modal report-table__item-modal"
                          @click="() => FFModal(index, item)"
                        >
                          <!-- @click="() => showParentRecords(index, item)" -->

                          <div class="item-modal__title">
                            {{ row.data[item].value }}
                          </div>
                          <!-- <div class="item-modal__img"></div> -->
                        </div>

                        <div
                          class="report-table__item-formula-wrap modal-contract-type"
                          v-else-if="this.objFields[item].fieldType == 'duration'"
                        >
                          <div class="report-table__item-formula_other">
                            {{
                              row.data[item].value
                                ? row.data[item].value / 1000 / 60 / 60 / 24
                                : 0
                            }}
                          </div>
                        </div>

                        <!-- <div
                    v-if="item == 173"
                    class="item-modal report-table__item-modal"
                    @click="() => showParentRecords(index, item)"
                  >
                    <div class="item-modal__title">
                      {{ row.data[item].value }}
                    </div>
                    <div class="item-modal__img"></div>
                  </div> -->
                        <div
                          v-else-if="
                            this.objFields[item].fieldType == 'multitext'
                          "
                          class="report-table__item-formula-wrap"
                        >
                          <div
                            class="report-table__item"
                            style="
                              display: block;
                              height: 100%;
                              width: 100%;
                              padding: 15px;
                            "
                          >
                            <div>{{ formatMultitext(row.data[item].value) }}</div>
                          </div>
                        </div>
                        <div v-else-if="this.objFields[item].mode == ''">
                          <a
                            v-if="this.objFields[item].fieldType == 'url'"
                            :href="row.data[item].value"
                            >{{ this.objFields[item].label }}</a
                          >
                          <a
                            v-else-if="this.objFields[item].fieldType == 'dblink'"
                            :href="row.data[item].value"
                            >{{ this.objFields[item].label }}</a
                          >
                          <div
                            class="report-table__item report-table__item-data-wrap"
                            v-else-if="this.objFields[item].fieldType == 'date'"
                          >
                            {{ formatDateFunc(row.data[item].value) }}
                            <!-- <DatePicker
                        v-model="row.data[item].value"
                        :enableTimePicker="false"
                        :format="(t) => formatDateFunc(t)"
                        disabled
                        @update:modelValue="
                          () => handleDate(row.data[item].value, index, item)
                        "
                      /> -->
                            <!-- <input type="date" v-model="row.data[item].value" /> -->
                          </div>
                          <div
                            class="report-table__item report-table__item-timestamp-wrap"
                            style="display: flex; justify-content: flex-start; align-items: center;"
                            v-else-if="
                              this.objFields[item].fieldType == 'timestamp' && item == 107
                            "
                          >
                            {{
                              formatDateTimeFunc(
                                row.data[item].value,
                                this.objFields[item]
                              )
                            }}
                            <div v-if="row.data[124].value" class="btn-log field-title__btn-log" @click="(e) => {}">
                              <span>!</span>
                              <div class="btn-log__body">
                                <div>
                                  {{ row.data[124].value }}
                                </div>
                              </div>
                            </div>
                            <!-- <DatePicker v-model="row.data[item].value" /> -->
                            <!-- <input type="datetime-local" v-model="row.data[item].value" /> -->
                            <!-- DATETIME -->
                          </div>
                          <div
                            class="report-table__item report-table__item-timestamp-wrap"
                            v-else-if="
                              this.objFields[item].fieldType == 'timestamp'
                            "
                          >
                            {{
                              formatDateTimeFunc(
                                row.data[item].value,
                                this.objFields[item]
                              )
                            }} test
                            <!-- <DatePicker v-model="row.data[item].value" /> -->
                            <!-- <input type="datetime-local" v-model="row.data[item].value" /> -->
                            <!-- DATETIME -->
                          </div>
                          <div
                            class="report-table__item-boolean-wrap"
                            v-else-if="
                              this.objFields[item].fieldType == 'checkbox'
                            "
                          >
                            <input
                              type="checkbox"
                              disabled
                              v-model="row.data[item].value"
                            />
                            <!-- {{ row.data[item].value }} -->
                          </div>
                          <div
                            class="report-table__item-text-multiple-choice-cargo-wrap"
                            v-else-if="
                              this.objFields[item].fieldType ==
                              'text-multiple-choice'
                            "
                          >
                            {{ row.data[item].value }}
                            <!-- <Multiselect
                        v-model="row.data[item].value"
                        :options="this.objFields[item].properties.choices"
                        clearOnSelect="false"
                        placeholder="Choose"
                        disabled
                        :close-on-select="false"
                      ></Multiselect> -->
                          </div>
                          <div
                            class="report-table__item-wrap"
                            v-else-if="
                              this.objFields[item].fieldType == 'currency'
                            "
                          >
                            <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                            <div
                              class="report-table__item"
                              style="
                                display: block;
                                height: 100%;
                                width: 100%;
                                padding: 15px;
                              "
                            >
                            <div v-if = "row.data[item].value % 1 !== 0">
                              {{
                                this.currencyFormat(
                                  row.data[item].value,
                                  this.objFields[item]
                                )
                              }}
                            </div>
                            <div v-else>
                              {{ row.data[item].value ? `$${row.data[item].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "" }}
                            </div>
                          </div>
                          </div>
                          <div class="report-table__item-wrap" v-else>
                            <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                            <div
                              class="report-table__item"
                              style="
                                display: block;
                                height: 100%;
                                width: 100%;
                                padding: 15px;
                              "
                            >
                              <div>
                                {{ row.data[item].value }}
                              </div>
                            </div>
                            <textarea
                              style="display: none"
                              :value="row.data[item].value"
                              :data-row="index"
                              :data-fid="item"
                              @input="
                                (event) => textareaInput(event, index, item)
                              "
                            ></textarea>
                            <!-- <div class="red">
                        {{ row.data[item].value }}
                      </div> -->
                            <!-- <button @click="() => showParentRecords(index, item)">
                        Edit parent record
                      </button>
                      <textarea
                        :value="row.data[item].value"
                        @input="(event) => textareaInput(event, index, item)"
                      ></textarea> -->
                          </div>
                          <!-- <textarea
                      v-else
                      :value="row.data[item].value"
                      @input="(event) => textareaInput(event, index, item)"
                    ></textarea> -->
                        </div>
                        <div v-else-if="this.objFields[item].mode == 'lookup'">
                          
                          <a
                            v-if="this.objFields[item].fieldType == 'url'"
                            :href="row.data[item].value"
                            >{{ this.objFields[item].label }}</a
                          >
                          <a
                            v-else-if="this.objFields[item].fieldType == 'dblink'"
                            :href="row.data[item].value"
                            >{{ this.objFields[item].label }}</a
                          >
                          <div
                            class="report-table__item-wrap"
                            v-else-if="
                              this.objFields[item].fieldType == 'currency'
                            "
                          >
                            <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                            <div
                              class="report-table__item"
                              style="
                                display: block;
                                height: 100%;
                                width: 100%;
                                padding: 15px;
                              "
                            >
                              <div>
                                {{
                                  this.currencyFormat(
                                    row.data[item].value,
                                    this.objFields[item]
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="report-table__item report-table__item-data-wrap"
                            v-if="this.objFields[item].fieldType == 'date'"
                          >
                            {{ formatDateFunc(row.data[item].value) }}
                          </div>
                          
                          <div
                            class="report-table__item report-table__item-data-wrap"
                            v-if="this.objFields[item].fieldType == 'timestamp'"
                          >
                            {{
                              formatDateTimeFunc(
                                row.data[item].value,
                                this.objFields[item]
                              )
                            }}
                          </div>
                          <div
                            class="report-table__item report-table__item_64 report-table__item-data-wrap"
                            v-else-if="
                              this.objFields[item].id == 64
                            "
                            v-html="row.data[item].value"
                          ></div>
                          <div class="report-table__item-wrap" v-else>
                            <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                            <div
                              class="report-table__item"
                              style="
                                display: block;
                                height: 100%;
                                width: 100%;
                                padding: 15px;
                              "
                            >
                              <div>{{ row.data[item].value }}</div>
                            </div>
                            <textarea
                              :data-row="index"
                              :data-fid="item"
                              style="display: none"
                              :value="row.data[item].value"
                              @input="
                                (event) => textareaInput(event, index, item)
                              "
                            ></textarea>
                            <!-- <div class="green">
                        {{ row.data[item].value }}
                      </div> -->
                            <!-- <button @click="() => showParentRecords(index, item)">
                        Edit parent record
                      </button>
                      <textarea
                        :value="row.data[item].value"
                        @input="(event) => textareaInput(event, index, item)"
                      ></textarea> -->
                          </div>
                        </div>
                        <div
                          v-else-if="this.objFields[item].mode == 'formula'"
                          class="report-table__item-formula-wrap"
                        >
                          <div
                            class="report-table__item-formula-rich-text-wrap"
                            v-if="this.objFields[item].fieldType == 'rich-text'"
                            v-html="row.data[item].value"
                          >
                          </div>
                          <div class="report-table__item-formula_other" v-else>
                            {{ row.data[item].value }}
                          </div>
                        </div>
                        <div
                          v-else-if="this.objFields[item].mode == 'summary'"
                          class="report-table__item-formula-wrap"
                        >
                          <div
                            class="report-table__item report-table__item-data-wrap"
                            v-if="this.objFields[item].fieldType == 'date'"
                          >
                            {{ formatDateFunc(row.data[item].value) }}
                          </div>
                          <div
                            class="report-table__item report-table__item-data-wrap"
                            v-else-if="
                              this.objFields[item].fieldType == 'timestamp'
                            "
                          >
                            {{
                              formatDateTimeFunc(
                                row.data[item].value,
                                this.objFields[item]
                              )
                            }}
                          </div>
                          <div
                            class="report-table__item-wrap"
                            v-else-if="
                              this.objFields[item].fieldType == 'currency'
                            "
                          >
                            <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                            <div
                              class="report-table__item"
                              style="
                                display: block;
                                height: 100%;
                                width: 100%;
                                padding: 15px;
                              "
                            >
                              <div>
                                {{
                                  this.currencyFormat(
                                    row.data[item].value,
                                    this.objFields[item]
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <div v-else class="report-table__item-formula_other">
                            {{ row.data[item].value }}
                          </div>
                        </div>
                        <a
                          v-else-if="this.objFields[item].fieldType == 'url'"
                          :href="row.data[item].value"
                          >{{ this.objFields[item].label }}</a
                        >
                        <div class="report-table__item-wrap" v-else>
                          <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                          <div
                            class="report-table__item"
                            style="
                              display: block;
                              height: 100%;
                              width: 100%;
                              padding: 15px;
                            "
                          >
                            <div>{{ row.data[item].value }}</div>
                          </div>
                          <textarea
                            style="display: none"
                            :value="row.data[item].value"
                            :data-row="index"
                            :data-fid="item"
                            @input="(event) => textareaInput(event, index, item)"
                          ></textarea>
                          <!-- <div class="blue">
                      {{ row.data[item].value }}
                    </div> -->
                          <!-- <button @click="() => showParentRecords(index, item)">
                        Edit parent record
                      </button>
                      <textarea
                        :value="row.data[item].value"
                        @input="(event) => textareaInput(event, index, item)"
                      ></textarea> -->
                        </div>
                      </div>
                    </td>
                  </template>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
      <!-- <div v-if="report" class="btn-save">
        <div class="btn-save__body">
          <custom-loader v-if="saving" />
          <span v-else>Save</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import moment from "moment";
import moment from "moment-timezone";
// import QbReportsItem from "./components/QbReportsItem.vue";

//target="_blank"
import CustomLoader from "../loader/CustomLoader.vue";
// import Multiselect from "@vueform/multiselect";

import QuickbaseRepository from "@/api/Quickbase";

export default {
  name: "QbReport",
  components: {
    CustomLoader,
    // Multiselect,
  },
  props: {
    table: String,
    title: String,
    report: String,
    filter: {},
    additionalClist: [],
    showBodyProp: Boolean,
    stickyColumns: Number,
  },
  emits: ["getInfo"],
  data: function () {
    return {
      showBody: true,
      //pop-ups
      showModalAddTask: false,
      ridForAddTask: 0,
      showModalTasks: false,
      keyForTasks: "",

      showModalEditReportRecord: false,
      showModalEditDocumentReportRecord: false,
      ridForEditReportRecord: 0,
      ridForEditDocumentReportRecord: 0,

      showModalContractType: false,
      modalContractTypeReportID: 0,

      colors: {},

      chartererNameFilterValue: [],
      reports: [],
      // reportData: [],
      // fields: [],
      // allFields: [],
      // clist: [],
      // relationships: [],
      forSave: {},
      saving: false,
      // gettingReport: true,
      showModalWithParentData: false,

      parentReportData: [],
      parentFields: [],
      parentAllFields: [],

      // fidsForFFModal: [338,365,373,92,339,352,353,375,472,340,341,342,346,486,347,487,343],
      fidsForFFModal: [],

      masterTableId: "",
      searchText: "",

      showGroup: {},
    };
  },
  watch: {
    report() {
      console.log(this.report);
      this.getQBReport();
    },
    filter() {
      this.getQBReport();
    },
  },
  mounted() {
    // this.getQBTables();
    this.getQBReport();
    this.showBody = this.showBodyProp;
  },
  updated() {
    let rts = this.$el.querySelectorAll("table tr");

    for (const key in rts) {
      if (Object.hasOwnProperty.call(rts, key)) {
        const trElement = rts[key];
        let tds = trElement.querySelectorAll(".report-table__sticky");
        let left = 0;
        for (const tdKey in tds) {
          if (Object.hasOwnProperty.call(tds, tdKey)) {
            const tdElement = tds[tdKey];
            tdElement.style.left = left + "px";
            tdElement.dataset.test = left + "px";
            // tdElement.style.backgroundColor = "red";
            left += tdElement.offsetWidth;
          }
        }
        // trElement.style.left = "20px";
      }
    }
  },
  computed: {
    ...mapGetters("cargoes", ["getReportById"]),

    myCallback(el) {
      return el[8]["value"];
    },

    reportData() {
      console.log("reportData2");
      if (this.getReportById(this.report).data) {
        let dataGrouped =
          this.getReportById(this.report).data.reduce((result, item) => {
            console.log(result);
            console.log(item);
            console.log(item[8]["value"]);
            if (!result["" + item[8]["value"]])
              result["" + item[8]["value"]] = [];
            result["" + item[8]["value"]].push(item);
            console.log(result);
            return result;
          }, []) || [];

        let res = [];
        
        for (const key in dataGrouped) {
          if (Object.hasOwnProperty.call(dataGrouped, key)) {
            const element = dataGrouped[key];
            let tmp = [];
            element.forEach((el) =>
              tmp.push({
                type: "record",
                data: el,
              })
            );
            if(key == "Dead" || key == "Covered") {
              this.add(key, false)
            } else {
              this.add(key)
            }
            
            res.push({
              type: "group",
              key: key,
              // text: key + " (" + element.length + " cargoes)",
              text: key,
              open: key == "Dead" || key == "Covered" ? false : true,
              children: tmp,
            });
          }
        }
        // return this.getReportById(this.report).data;
        return res;
      }
      return [];
    },
    fields() {
      return this.getReportById(this.report).fields;
    },
    allFields() {
      return this.getReportById(this.report).allFields;
    },
    clist() {
      return this.getReportById(this.report).clist;
    },
    relationships() {
      return this.getReportById(this.report).relationships;
    },

    gettingReport() {
      return this.getReportById(this.report).loading ?? true;
    },

    modalName() {
      if (this.masterTableId == "brxivurjh") {
        return "Sales Contract";
      } else if (this.masterTableId == "brxix2mgw") {
        return "Freight Fixture";
      } else {
        return "";
      }
    },
    objFields() {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      this.allFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    objParentFields() {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      this.parentAllFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    lookups() {
      let res = [];
      for (const key in this.relationships) {
        if ("lookupFields" in this.relationships[key]) {
          for (const k in this.relationships[key].lookupFields) {
            res.push(this.relationships[key].lookupFields[k].id);
          }
        }
      }
      return res;
    },

    filteredItems() {

      const filteredGroups = [];

        for (let group of this.reportData) {
          const filteredItems = group.children.filter(groupChild => {

            for (let key of Object.keys(groupChild.data)) {
              if (typeof groupChild.data[key]?.value === "string" && groupChild.data[key]?.value.toLowerCase().includes(this.searchText.toLowerCase())) {
                // console.log("STRING", groupChild.data[key]?.value)
                return true;
              } else if (typeof groupChild.data[key]?.value === "number" && groupChild.data[key]?.value.toString().toLowerCase().includes(this.searchText.toLowerCase())) {
                // console.log("NUMBER", groupChild.data[key]?.value.toString())
                return true;
              } else if (typeof groupChild.data[key]?.value === "object" && groupChild.data[key]?.value !== null && groupChild.data[key]?.value !== undefined ) {
                  for (const internalElem of groupChild.data[key]?.value) {
                    if (internalElem.toLowerCase().includes(this.searchText.toLowerCase())) {
                      return true;
                    }
                  }
              }
            }

            return false;
          });

          if (filteredItems.length > 0) {
            filteredGroups.push({
              type: 'group',
              key: group.key, 
              text: group.text,
              children: filteredItems,
            });
          }

          console.log("filteredGroups", filteredGroups)
        }

      return filteredGroups;
    },

  },

  methods: {
    ...mapActions("cargoes", ["getReport"]),

    add(key, value = true) {
      this.showGroup[key] = value;
    },

    isShow(rid) {
      let hide = [8];
      if(hide.includes(rid)) {
        return false;
      }
      return true;
    },

    documentsIds(text) {
      let res = {};
      if (!text) return {};
      const rows = text.split(";");
      for (const key in rows) {
        if (Object.hasOwnProperty.call(rows, key)) {
          const element = rows[key];
          let d = element.split("|");
          res[d[0]] = d[1];
        }
      }
      return res;
    },
    formatNextTaskName(value) {
      if (value) {
        if (value.length == 0) {
          return "";
        } else if (value.length == 1) {
          return value[0];
        } else {
          return value;
        }
      } else {
        return "";
      }
    },
    currencyFormat(value, prop) {
      // currencyFormat(value) {
      if (value) {
        /* let formatedValue = value.toLocaleString("en-US", {
            style: 'currency',
          }); */
        let formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: prop.properties.decimalPlaces,
        });
        return formatter.format(value);
      } else {
        return "";
      }
    },

    formatDateFunc(date) {
      if (date) {
        if (moment(date).year() == moment().year()) {
          return moment(date).format("MMM DD");
        }
        return moment(date).format("MMM DD, yyyy");
      } else {
        return "";
      }
    },

    formatDateTimeFunc(date, props) {
      if (date) {
        if (props["properties"]["displayTime"]) {
          return moment(date).tz("US/Pacific").format("MM-DD-yyyy h:mm a");
        } else {
          if (props["properties"]["displayMonth"] == "name") {
            if (props["properties"]["displayRelative"]) {
              if (moment(date).year() == moment().year()) {
                return moment(date).tz("US/Pacific").format("MMM DD");
              } else {
                return moment(date).tz("US/Pacific").format("MMM DD, yyyy");
              }
            } else {
              return moment(date).tz("US/Pacific").format("MMM-DD-yyyy");
            }
          } else {
            return moment(date).tz("US/Pacific").format("MM-DD-yyyy");
          }
        }
      } else {
        return "";
      }
    },

    formatMultitext(value) {
      if (value) {
        return value.join(", ");
      } else {
        return "";
      }
    },

    styleForCol(fid, rowIndex) {
      let classes = "";
      if (Object.hasOwnProperty.call(this.colors, fid)) {
        classes += this.colors[fid];
      }
      if (rowIndex < this.stickyColumns) {
        classes += "report-table__sticky";
      }

      if(fid == 49 || fid == 125) {
        classes += " left-line"
      }

      let forRightLine = [87, 88, 89];

      if(forRightLine.includes(fid)) {
        classes += " right-line"
      }


      return classes;
    },

    styleForColHeader(fid) {
      let classes = "";

      if(fid == 49 || fid == 125) {
        classes += " left-line"
      }

      let forRightLine = [87, 88, 89];

      if(forRightLine.includes(fid)) {
        classes += " right-line"
      }


      return classes;
    },

    FFCheckLookup(key) {
      if (this.objFields[key].mode == "lookup") return true;
      return false;
    },

    colectDataFF(value, fid) {
      this.dataFFModalForQB[fid] = value;
    },

    async updateRecord(rid) {
      this.getRecordByRidForReport(rid, this.table);
    },

    getRecordByRidForReport(rid, table) {
      QuickbaseRepository.getRecordDataByRecordID(table, rid)
        .then((result) => {
          // this.reportData = result["body"]["data"];
          this.updateRecordByRid(result["data"]["body"]["data"]);
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    async updateRecordByRid(record) {
      let rid = record[3]["value"];

      let rowKeyForUpdate = -1;

      for (const key in this.reportData) {
        if (Object.hasOwnProperty.call(this.reportData, key)) {
          const record = this.reportData[key];
          if (record[3]["value"] == rid) {
            rowKeyForUpdate = key;
          }
        }
      }
      console.log("rowKeyForUpdate: " + rowKeyForUpdate);

      if (rowKeyForUpdate != -1) {
        for (const fid in record) {
          if (Object.hasOwnProperty.call(record, fid)) {
            const fieldWithNewData = record[fid];
            if (
              Object.hasOwnProperty.call(this.reportData[rowKeyForUpdate], fid)
            ) {
              this.reportData[rowKeyForUpdate][fid]["value"] =
                fieldWithNewData["value"];
            }
          }
        }
      }
    },

    async showModal() {
      this.showModalWithParentData = true;
    },

    showParentRecords(index, item) {
      this.showModal();
      console.log(index, item);
      console.log(this.objFields);
      console.log(this.objFields[item].properties.lookupReferenceFieldId);

      let fieldIdForParentKey =
        this.objFields[item].properties.lookupReferenceFieldId;
      let valueForParentKey = this.reportData[index][fieldIdForParentKey].value;
      let masterTableId =
        this.objFields[fieldIdForParentKey].properties.masterTableId;
      let masterTableKeyFid =
        this.objFields[fieldIdForParentKey].properties.masterTableKeyFid;

      console.log(valueForParentKey);
      console.log(masterTableId);
      this.masterTableId = masterTableId;
      console.log(masterTableKeyFid);

      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN +
          "/v1/qb/query/table/" +
          masterTableId +
          "/key/" +
          masterTableKeyFid +
          "/value/" +
          valueForParentKey,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          this.parentReportData = result["body"]["data"];
          this.parentFields = result["body"]["fields"];
          this.parentAllFields = result["body"]["allFields"];
          // this.clist = result["body"]["report"]["query"]["fields"];
          // this.relationships = result["body"]["relationships"];
          // this.clients = result;
        })
        .catch((error) => console.log("error", error));
    },

    textareaInput(arg, index, item) {
      if (this.lookups.includes(item)) {
        for (const rk in this.reportData) {
          for (const fk in this.reportData[rk]) {
            if (fk == item) {
              for (const key in this.allFields) {
                if (Object.hasOwnProperty.call(this.allFields, key)) {
                  if (
                    this.allFields[key].id == fk &&
                    this.allFields[key].mode == "lookup"
                  ) {
                    // this.allFields[key].properties.lookupReferenceFieldId
                    if (
                      this.reportData[index][
                        this.allFields[key].properties.lookupReferenceFieldId
                      ].value ==
                        this.reportData[rk][
                          this.allFields[key].properties.lookupReferenceFieldId
                        ].value &&
                      !!this.reportData[index][
                        this.allFields[key].properties.lookupReferenceFieldId
                      ].value
                    ) {
                      this.reportData[rk][fk].value = arg.target.value;

                      for (const relKey in this.relationships) {
                        for (const relLFKey in this.relationships[relKey]
                          .lookupFields) {
                          if (
                            item ==
                            this.relationships[relKey].lookupFields[relLFKey].id
                          ) {
                            // let tmp = {};

                            if (
                              this.relationships[relKey].parentTableId in
                              this.forSave
                            ) {
                              this.forSave[
                                this.relationships[relKey].parentTableId
                              ]["key"] =
                                this.reportData[rk][
                                  this.allFields[
                                    key
                                  ].properties.lookupReferenceFieldId
                                ].value;
                              this.forSave[
                                this.relationships[relKey].parentTableId
                              ][
                                this.allFields[
                                  key
                                ].properties.lookupTargetFieldId
                              ] = arg.target.value;
                            } else {
                              this.forSave[
                                this.relationships[relKey].parentTableId
                              ] = {};
                              this.forSave[
                                this.relationships[relKey].parentTableId
                              ]["key"] =
                                this.reportData[rk][
                                  this.allFields[
                                    key
                                  ].properties.lookupReferenceFieldId
                                ].value;
                              this.forSave[
                                this.relationships[relKey].parentTableId
                              ][
                                this.allFields[
                                  key
                                ].properties.lookupTargetFieldId
                              ] = arg.target.value;
                            }
                            // tmp["key"] =
                            //   this.reportData[rk][
                            //     this.allFields[
                            //       key
                            //     ].properties.lookupReferenceFieldId
                            //   ].value;
                            // tmp[
                            //   this.allFields[key].properties.lookupTargetFieldId
                            // ] = arg.target.value;
                            // this.forSave[
                            //     this.relationships[relKey].parentTableId
                            //   ] = tmp;
                            // if (
                            //   this.relationships[relKey].parentTableId in
                            //   this.forSave
                            // ) {
                            //   this.forSave[
                            //     this.relationships[relKey].parentTableId
                            //   ].push(tmp);
                            // } else {
                            //   this.forSave[
                            //     this.relationships[relKey].parentTableId
                            //   ] = [];
                            //   this.forSave[
                            //     this.relationships[relKey].parentTableId
                            //   ].push(tmp);
                            // }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        this.reportData[index][item].value = arg.target.value;
      }
      // this.reportData.forEach((rv, rk) => {
      //   for (const fk in rv) {
      //     if (fk == item) {
      //       this.reportData[rk][fk].value = arg.target.value;
      //     }
      //   }
      //   // Array.from(rv).forEach((fv, fk) => {
      //   // console.log(fv);
      //   // console.log(fk);

      //   //   if(fk == item) {
      //   //     this.reportData[rk][fk].value = arg;
      //   //   }
      //   // });
      // });
    },

    getInfoReport(report) {
      console.log(report);
      this.$emit("getInfo", report);
    },

    getKeyForRow(row) {
      return this.reportData[row][3]["value"];
    },

    async saveDataForQB(params) {
      QuickbaseRepository.pushDataToQB(params)
        .then((result) => {
          console.log(result);
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    async getQBReport() {
      this.getReport({
        table: this.table,
        reportId: this.report,
        filter: this.filter,
        clist: this.additionalClist,
      });
      // this.gettingReport = true;
      this.gettingReport = false;
    },
    prepNameForColumnTitle(label) {
      let countDash = label.split("-").length - 1;
      if (countDash > 1) {
        let tmp = label.split("-");
        tmp.shift();
        return tmp.join("-").replace(/formula/g, "");
      } else {
        return label.replace(/formula/gi, "");
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.modal-contract-type * {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  text-decoration: none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.report-panel {
  max-width: 100%;
  /* overflow-y: auto; */
  /* overflow-x: auto; */
}

.search-input {
  margin-left: 32px;
  width: 420px;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  outline: none;
  border: var(--ms-border-width,1px) solid var(--ms-border-color,#d1d5db);
  border-radius: var(--ms-radius,4px);
  background: var(--ms-bg,#fff);
  font-size: var(--ms-font-size,1rem);
  min-height: calc(var(--ms-border-width, 1px)*2 + var(--ms-font-size, 1rem)*var(--ms-line-height, 1.375) + var(--ms-py, .5rem)*2);
}

.search-input::placeholder {
  color: #9ca3af;
}

.search-input:focus {
  box-shadow: 0 0 0 var(--ms-ring-width,3px) var(--ms-ring-color,rgba(16,185,129,.18823529411764706));
}

.report-body {
  /* height: 100%; */
  /* height: 700px; */
  /* overflow-y: auto;
  overflow-x: auto; */
  position: relative;
  margin-top: 10px;
  /* display: flex; */
  padding-bottom: 45px;
  /* height: 420px; */
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

td {
  /* border-right: 1px solid #d3dfed; */
  border-bottom: 1px solid #d3dfed;
  /* padding: 15px; */
}

tr td {
  border-right: 1px solid #d3dfed4c;
}

tr td:last-child {
  border-right: 1px solid #d3dfed;
}

thead {
  /* background: #f6faff; */
  background: #fff;
}

tbody {
  /* border-top: 1px solid #d3dfed; */
  /* border-left: 1px solid #d3dfed; */
}

tbody tr:first-child td {
  border-top: 1px solid #d3dfed;
  /* border-left: 1px solid #d3dfed; */
}

tbody tr:first-child td:first-child {
  border-top: none;
  /* border-left: 1px solid #d3dfed; */
}

.report-table {
  /* margin-top: 50px; */
  /* width: 100%; */
  table-layout: fixed;
  border-collapse: collapse;
  /* height: 90vh; */
  position: relative;
}

.report__collapse {
  border: 3px solid #d3dfed;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  margin-right: 15px;
  color: #2d3757;
  background: #f6faff;
}

/* .report-table thead {
  background-color: #333;
  color: #fdfdfd;
  position: sticky;
} */

.report-table thead tr {
  /* display: block; */
  position: relative;
}

th {
  position: sticky;
  z-index: 1;
  /* border-top: 0px;
  border-right: 1px solid rgb(215, 214, 217);
  border-bottom: 1px solid rgb(215, 214, 217); */
  border: 0;
  padding: 5px 10px;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  /* background-color: rgb(120, 117, 124); */
  /* background: #fff; */
  cursor: default;
  vertical-align: middle;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #2d3757;
  /* text-align: left; */

  min-height: 70px;
}

.report-table .report-table__head th > div {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-table td div {
  white-space: nowrap;
}

.report-table__head {
  /*position: sticky;*/
  /*z-index: 1;*/
  top: 0;
  z-index: 999;
  background-color: #fff;
}

.btn-save {
  display: flex;
  justify-content: end;
}

.btn-save__body {
  background: rgb(106, 141, 255);
  color: #fff;
  font-size: 1.25em;
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;
}

/* .modal {
  position: absolute;
} */

.modal__mask {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  transition: opacity 0.3s ease;
  padding: 20px;
}

.modal__wrapper {
  margin: 40px;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: 100%;
  /* padding: 20px; */
}

.modal__container {
  /* overflow-y: scroll; */
  /* width: 300px; */
  margin: 0px auto;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-width: 800px;
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d3dfed;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #2d3757;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  margin: 20px 0;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.modal__default-button {
  float: right;
}

.modal__footer {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  margin-right: 15px;
  cursor: pointer;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
}

.preloader {
  display: flex;
  justify-content: center;
}

/* .report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  background-color: #e3e9f1;
} */

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
}

.item-modal__title2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.modal-contract-type {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  text-decoration: none;
}

.item-modal__img {
  margin-left: 10px;
  height: 16px;
  width: 16px;
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0;
  flex-basis: 16px;
  background: url(@/assets/share.svg) no-repeat center center;
  background-size: 16px 16px;
}

.report-table__item {
  font-family: "Lato" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #2d3757;
  display: block;
  height: 100%;
  width: 100%;
  padding: 15px;
}

.report-table__item-wrap {
  display: flex;
}

.report-table__sticky {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  /* left: 0; */
  /* background: #f6faff; */
  background: #fff;
  /* border: #2d3757 2px solid; */
  /* border-right: 1px solid #CCC; */
  /* border-left: 1px solid #CCC; */
  left: 0;
  z-index: 998;
}

td.report-table__sticky {
  background: #fff;
}

th.report-table__col-pencil {
  background: #fff;
}
.report-table__sticky > div {
  /* border: #2d3757 2px solid; */
}

.report-table__sticky > div {
  /* border: #2d3757 2px solid; */
}

.report-table__sticky > div::after,
.report-table__sticky > div::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

.report-table__sticky > div::before {
  top: -1px;
  border-top: 1px solid #d3dfed;
  /* margin-left: -1px; */
}

th.report-table__sticky > div::before {
  border-top: 0px solid #d3dfed;
  /* margin-left: -1px; */
}

.report-table__sticky > div::after {
  bottom: -1px;
  border-bottom: 1px solid #d3dfed;
}

.report-table__sticky::after,
.report-table__sticky::before {
  content: "";
  position: absolute;
  top: -1px;
  height: 100%;
}

.report-table__sticky::before {
  left: -1px;
  border-left: 1px solid #d3dfed4c;
  /* margin-left: -1px; */
}

.report-table__sticky::after {
  right: -1px;
  border-right: 1px solid #d3dfed4c;
}

th.report-table__sticky::before {
  left: -1px;
  border-left: 1px solid #f6faff4c;
  /* margin-left: -1px; */
}

th.report-table__sticky::after {
  right: 0;
  border-right: 1px solid #f6faff4c;
}

.switch-bar {
  display: flex;
}

.switch-bar__item {
  flex: 1 1 auto;
  text-align: center;
  border-bottom: 1px solid #d3dfed;
  padding: 8px 0;
  cursor: pointer;
}

.switch-bar__item_active {
  border-bottom: 2px solid #2d3757;
}

.switcher-content {
  /* display: flex;
  flex-wrap: wrap; */
}

.switcher-content__choose {
  display: flex;
  flex-wrap: wrap;
}

.switcher-content__item {
  box-sizing: border-box;
  padding: 20px 20px;
  width: 50%;
  min-width: 100px;
}

.switcher-content__item_100 {
  width: 100%;
}

.switcher-content__item_25 {
  width: 25%;
}

.switcher-content__title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;
}

.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  width: 100%;
}

.report-table__item-formula_other {
  /* background: rgba(238, 238, 238, 0.5); */
  padding: 15px;
  margin: 0 5px;
  /* cursor: not-allowed; */
}

.report-table__item-text-multiple-choice-wrap {
  /* min-width: 200px; */
  position: relative;
  padding: 5px 15px;
  /* z-index: 99; */
}

.report-table__item-text-multiple-choice-cargo-wrap {
  /* min-width: 200px; */
  position: relative;
  padding: 5px 15px;
  /* z-index: 99; */
}

.report-table__item-boolean-wrap {
  position: relative;
  padding: 5px 15px;
  /* z-index: 99; */
}

.multiselect {
  /* z-index: 999; */
}

.multiselect-dropdown {
  /* z-index: 1000; */
}

.red {
  background: red;
}

.green {
  background: green;
}

.blue {
  background: blue;
}

.LightOrange {
  background-color: #deecfc;
}

.LightBlue {
  background-color: #b9ceeb;
}

.LightGreen {
  background-color: #c3b4d2;
}

.LightGray {
  background-color: #eceeea;
}
.report-table tbody tr:not(.header-block):hover td {
  background: #f2f7fc;
}

.report-table tbody tr:hover td.LightOrange {
  background-color: #f2f7fe;
}

.report-table tbody tr:hover td.LightBlue {
  background-color: #e9eff9;
}
/* E9EFF9 */
.report-table tbody tr:hover td.LightGreen {
  background-color: #eeeaf3;
}

.report-table tbody tr:hover td.LightGray {
  background-color: #f7f8f7;
}

.report-table tbody tr:hover td.report-table__col-pencil {
  background: none;
}

.report-table tbody tr th.report-table__col-pencil {
  background: #fff;
}

.report-table__col-pencil {
  border: 0;
}

.report-table__sticky > div::after,
.report-table__sticky > div::before {
  border: 0;
}

.report-table tbody tr:hover td.report-table__col-pencil .record__edit-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0;
  flex-basis: 16px;
  background: url(@/assets/icon-pencil.svg) no-repeat center center;
  background-size: 16px 16px;
  cursor: pointer;
}

.report-table tbody tr:hover td.report-table__col-pencil {
  background: #fff;
}

.contract-document {
  cursor: pointer;
  background: #eef0f3;
  display: inline-block;
  border-radius: 15px;
  padding: 3px 10px;
  margin-right: 5px;
}

.contract-document:last-child {
  margin-right: 0;
}

.report-table__item_64 * {
  font-family: "Lato" !important; 
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #2d3757;
}

.left-line {
  border-left: 4px solid #d3dfed;;
}

.right-line {
  border-right: 2px solid #d3dfed;;
}

.header-block,
.header-block .report-table__sticky {
  background-color: #e6eaef;
}
.header-block__body {
  height: 48px;
}
</style>

<style>
.report-table__item_64 * {
  font-family: "Lato" !important; 
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #2d3757;
}

.Vibrant {
  cursor: pointer;
  display: inline-block;
  padding: 5px 15px;
  /* display: block; */
  border-radius: 5px;
  margin: 5px;
  color: #fff;
  text-decoration: none;
}

.OpenAsPopup {
  padding: 5px 15px;
  display: block;
  border-radius: 5px;
  margin: 5px;
  color: #fff;
  text-decoration: none;
}

.record__edit-button {
  width: 32px;
  height: 32px;
  /* background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 16px;
  background: url(@/assets/pencil.png) no-repeat center center;
  background-size: 16px 16px;
  cursor: pointer; */
}

.report__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  color: #2d3757;
  padding-top: 30px;
  padding-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.wrap-scroll {
  height: calc(100vh - 270px);
  overflow-x: auto;
  overflow-y: auto;
  margin: 0;
  position: relative;
}

.wrap-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f9f9fd;
}

.wrap-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.wrap-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  background-color: #e3e9f1;
}

.create-record-wrap {
  flex-grow: 1;
  display: flex;
  justify-content: end;
}

.create-record {
  padding: 12px 24px;
  background: #2d3757;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid transparent;
  box-sizing: border-box;
}

.create-record:hover {
  background-color: #50629b;
  border: 1px solid transparent;
  outline: none;
}

.report-table__item-formula-rich-text-wrap {
  /* padding: 15px; */
  padding-left: 15px;
  padding-right: 15px;
}
</style>

<style>
.rich-text__484 div a {
  padding: 8px 8px;
  text-decoration: none;
  /* color: #6c6f7d; */

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  cursor: default;
}

.rich-text__956 div {
  padding: 8px 8px;
  text-decoration: none;
  /* color: #6c6f7d; */

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  cursor: default;
}

.btn-log {
  position: relative;
}

.btn-log__body {
  display: none;
  width: max-content;
  position: absolute;
  background: #2d3757;
  color: #fff;
  z-index: 5;
  padding: 8px;
  left: 0;
  bottom: 30px;
  border-radius: 2px;
  box-shadow: -1px -1px 10px 0px #2d375780;
  border: 1px solid #d3dfed;
}

.btn-log:hover .btn-log__body {
  display: block;
}

.field-title__btn-log {
  cursor: pointer;
  margin: 5px;
  margin-left: 10px;
  border-radius: 50px;
  background:  #2d3757;
  color: #fff;
  padding: 2px 8px;
}
</style>
