<template>
  <div class="home">
    <div class="reports">
      <div class="reports__wrap">
        <div class="reports__body">
          <div class="report__item">
            <div class="report__wrap">
              <div class="report-panel">
                <div class="report__title">
                  <div class="report__collapse" @click="showBody = !showBody">
                    <span v-if="showBody">-</span>
                    <span v-else>+</span>
                  </div>
                  <h2 @click="showBody = !showBody">Charterers</h2>
                  <div class="create-record-wrap">
                    <button
                      class="create-record"
                      @click="
                        () => {
                          this.$router.push({
                            name: 'CreateCharterersRecord',
                            params: {},
                          });
                        }
                      "
                    >
                      New Charterer
                    </button>
                  </div>
                </div>

                <!-- <div class="preloader" v-if="gettingReport">
                  <CustomLoader />
                </div> -->

                <!-- <div v-else-if="reportData.length != 0 && showBody"> -->
                <div v-if="showBody">

                  <input v-model="searchText" class="search-input" placeholder="Search..." @change="filteredItems()">

                  <div class="report-body wrap-scroll">
                    <table class="report-table">
                      <thead>
                        <tr class="report-table__head">
                          <th class="report-table__sticky report-table__col-pencil"></th>
                          <th class="column-name">
                            <div>Charterer Name</div>
                          </th>
                          <th class="column-global-charterer-name">
                            <div>Global Charterer Name</div>
                          </th>
                          <th class="column-display">
                            <div>Display on the website</div>
                          </th>
                        </tr>
                      </thead>
                      
                      <tbody>
                        <tr v-for="(row, index) in filteredItems" :key="index">
                          <td class="report-table__sticky report-table__col-pencil">
                            <div
                              class="record__edit-button"
                              @click="() => {
                                this.$router.push({ name: 'EditCharterersRecord', params: { id: row.id, open: true } })
                              }"
                            ></div>
                          </td>
                          <td class="column-name">
                            <div class="report-table__item">
                              {{ row.name }}
                            </div>
                          </td>
                          <td class="column-global-charterer-name">
                            <div class="report-table__item">
                              {{ row.global_charterer_name }}
                            </div>
                          </td>
                          <td class="column-display">
                            <div class="report-table__item">
                              <input type="checkbox" class="custom-checkbox" :checked="row.display_on_the_website" disabled>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import CharterersRepository from "@/api/Charterers";

export default {
  components: {
  },

  data() {
    return {
      showBody: true,
      charterers: [],
      searchText: "",
    };
  },

  mounted() {
    this.getCharterers();
  },

  computed: {
    filteredItems() {
      return this.charterers.filter(item => {
        let strValue = ""

        for (let key of Object.keys(item)) {
          if (typeof item[key] === "string") {
            strValue += `${item[key]?.toLowerCase()} `
          } else if (typeof item[key] === "object" && item[key] !== null && item[key].length) {
            let internalObject = item[key]

            for (let internalKey of Object.keys(internalObject)) {
              strValue += `${internalObject[internalKey].toString().toLowerCase()}`
            }
          }
        }
        return strValue.includes(this.searchText.toLowerCase())
      });
    }
  },

  methods: {
    getCharterers() {
      CharterersRepository.getCharterers()
        .then((result) => {
          this.charterers = result["data"]["data"];
          console.log("Charterers", this.charterers);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

  },

};
</script>

<style scoped>
  .reports {
    padding: 0 30px;
  }

  .report-panel {
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .report__title {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 48px;
    color: #2d3757;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .report__collapse {
    border: 3px solid #d3dfed;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75em;
    margin-right: 15px;
    color: #2d3757;
    background: #f6faff;
  }

  .search-input {
    margin-left: 32px;
    width: 420px;
    height: 40px;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    border: var(--ms-border-width,1px) solid var(--ms-border-color,#d1d5db);
    border-radius: var(--ms-radius,4px);
    background: var(--ms-bg,#fff);
    font-size: var(--ms-font-size,1rem);
    min-height: calc(var(--ms-border-width, 1px)*2 + var(--ms-font-size, 1rem)*var(--ms-line-height, 1.375) + var(--ms-py, .5rem)*2);
  }

  .search-input::placeholder {
    color: #9ca3af;
  }

  .search-input:focus {
    box-shadow: 0 0 0 var(--ms-ring-width,3px) var(--ms-ring-color,rgba(16,185,129,.18823529411764706));
  }

  .report-body {
    overflow-y: auto;
    overflow-x: auto;
    position: relative;
    margin-top: 10px;
    padding: 0 5px 45px 0;
  }

  .report-table {
    /* width: 100%; */
    table-layout: auto;
    border-collapse: collapse;
    position: relative;
  }

  th {
    position: sticky;
    z-index: 1;
    border: 0;
    padding: 5px 10px;
    color: rgb(255, 255, 255);
    -webkit-font-smoothing: antialiased;
    cursor: default;
    vertical-align: middle;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #2d3757;
    min-height: 70px;
  }

  .report-table .report-table__head th > div {
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .report-table__head {
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #fff;
  }

  tbody td {
    border: 1px solid #d3dfed;
  }

  .report-table__item {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2d3757;
    display: block;
    height: 100%;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }

  .report-table tbody tr:hover td{
    background: #f2f7fc;
  }

  .report-table tbody tr:hover td.report-table__col-pencil .record__edit-button {
    background: #eee;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16px;
    background: url(@/assets/icon-pencil.svg) no-repeat center center;
    background-size: 16px 16px;
    cursor: pointer;
  }

  .report-table tbody tr:hover td.report-table__col-pencil {
    background: #fff;
  }

  .report-table__col-pencil {
    border: 0;
  }

  .column-display {
    width: 150px;
  }

  .column-display .report-table__item {
    display: flex;
    justify-content: center;
  }

  .column-name,
  .column-global-charterer-name {
    width: max-content;
    min-width: 180px;
  }

  .custom-checkbox {
    appearance: none;
    width: 13px;
    height: 13px;
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }

  .custom-checkbox:checked {
    background-color: #3b82f6;
    border-color: #3b82f6;
    box-shadow: 0px 0px 5px 1px rgba(59, 130, 246, 0.7);
  }

  .custom-checkbox:checked::after {
    content: '';
    position: absolute;
    top: 47%;
    left: 50%;
    width: 2px;
    height: 7px;
    border-right: 2.25px solid white;
    border-bottom: 2.25px solid white;
    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 1px;
  }

  .custom-checkbox:disabled {
    cursor: not-allowed;
    opacity: 0.4;
    box-shadow: none;
  }

</style>