import ApiHelper from "@/api/ApiHelper"

export default {
    async getTableFields(tableID) {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        // let response = await Api.get(url, 
        //     data, headers);

        // let sessionID = store.state.sessionRecord.sessionID;
        let url = "/v1/report-generator/table/" + tableID + "/fields";

        return await ApiHelper.getResult(url, data, headers);
    },

    async createReport(name, table, clist, filter, sorting) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
            name: name,
            table: table,
            clist: clist,
            filter: filter,
            sorting: sorting,
        })
        
        // return await Api.post("/v1/report-generator/report", 
        // data, headers);

        let url = "/v1/report-generator/report";

        return await ApiHelper.getResult(url, data, headers);
    },

    async updateReport(reportID, name, clist, filter, sorting) {
        var headers = {
            method: "PUT",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
            name: name,
            clist: clist,
            filter: filter,
            sorting: sorting,
        })
        
        // return Api.put("/v1/report-generator/reports/" + reportID, 
        // data, headers);

        let url = "/v1/report-generator/reports/" + reportID;

        return await ApiHelper.getResult(url, data, headers);
    },

    async getReport(reportID) {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        let url = "/v1/report-generator/reports/" + reportID;
        return await ApiHelper.getResult(url, data, headers);
    },

    async getReports() {
        // let sessionID = store.state.sessionRecord.sessionID;
        
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        let url = "/v1/report-generator/reports";
        return await ApiHelper.getResult(url, data, headers);
    },

    async deleteReport(reportID) {
        var headers = {
            method: "DELETE",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        // return Api.delete("/v1/report-generator/reports/" + reportID, 
        // data, headers);
        
        let url = "/v1/report-generator/reports/" + reportID;

        return await ApiHelper.getResult(url, data, headers);
    },
}