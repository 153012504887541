import ApiHelper from "@/api/ApiHelper"

export default {
    async getAll() {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        // return Api.get("/v1/sizes", 
        // data, headers);
        let url = "/v1/sizes";
        return await ApiHelper.getResult(url, data, headers);
    }
}