<template>
  <div class="report-panel">
    <div
      @click="showBody = !showBody"
      class="report__title"
      v-if="gettingReport || reportData.length != 0"
    >
      <div class="report__collapse">
        <span v-if="showBody">-</span>
        <span v-else>+</span>
      </div>
      <h2>{{ title }}</h2>
    </div>
    <div class="preloader" v-if="gettingReport">
      <CustomLoader />
    </div>
    <div v-else-if="reportData.length != 0 && showBody">
      <div class="report-body" @scroll="updateStableWidth">
        <!-- <QbReportsItem v-for="report in reports" :report="report" :key="report.id" @getInfo="getInfoReport"/> -->
        <!-- <div v-for="(row, index) in reportData" :key="index">
        <div v-for="(item, index2) in row" :key="index + index2">
        <div>({{index}} {{index2}}) -- {{item.value}}</div>
        </div>
      </div> -->
        <div class="report-stablewidth" :style="{ minWidth: stableWidth + 'px' }"></div>
        <table class="report-table">
          <thead>
            <tr class="report-table__head">
              <th v-if="editLifting" class="report-table__sticky report-table__col-pencil"></th>

              <template v-for="(item, columnIndex) in clist" :key="item">
                <th
                  :class="[this.styleForCol(item, columnIndex), 'fid' + objFields[item].id]"
                  @click="(objFields[item].id === 1136 
                    || objFields[item].id === 1137
                    || objFields[item].id === 1138
                    || objFields[item].id === 1139
                    || objFields[item].id === 1140
                    || objFields[item].id === 1141
                    || objFields[item].id === 1144
                    || objFields[item].id === 1145
                    || objFields[item].id === 1161) ? showHideFieldsWithColor($event) : undefined"
                  v-if="objFields[item].id !== 752 
                    // && objFields[item].id !== 627
                    // && objFields[item].id !== 628
                    // && objFields[item].id !== 1203
                    // && objFields[item].id !== 1030
                    // && objFields[item].id !== 1031
                  "
                >
                  <div v-if="objFields[item].id === 956">
                    <div class="contract-type-buyer-name-wrap">
                      <div class="contract-type-wrap">
                        {{ this.prepNameForColumnTitle(objFields[item].label) }}
                      </div>

                      <template v-for="(item) in clist" :key="item">
                        <div v-if="objFields[item].id === 752" class="buyer-name-wrap">
                          {{ this.prepNameForColumnTitle(objFields[item].label) }}
                        </div>   
                      </template>

                      <!-- <template v-for="(item) in clist" :key="item">
                        <div v-if="objFields[item].id === 627" class="cnf-start">
                          {{ this.prepNameForColumnTitle(objFields[item].label) }}
                        </div>   
                      </template>

                      <template v-for="(item) in clist" :key="item">
                        <div v-if="objFields[item].id === 628" class="cnf-end">
                          {{ this.prepNameForColumnTitle(objFields[item].label) }}
                        </div>   
                      </template>

                      <template v-for="(item) in clist" :key="item">
                        <div v-if="objFields[item].id === 1203" class="buyer-name-wrap">
                          {{ this.prepNameForColumnTitle(objFields[item].label) }}
                        </div>   
                      </template>

                      <template v-for="(item) in clist" :key="item">
                        <div v-if="objFields[item].id === 1030" class="fob-start">
                          {{ this.prepNameForColumnTitle(objFields[item].label) }}
                        </div>   
                      </template>

                      <template v-for="(item) in clist" :key="item">
                        <div v-if="objFields[item].id === 1031" class="fob-end">
                          {{ this.prepNameForColumnTitle(objFields[item].label) }}
                        </div>   
                      </template> -->
                    </div>
                    
                  </div> 

                  <div v-else>
                    {{ this.prepNameForColumnTitle(objFields[item].label) }}
                  </div> 
                </th>
              <!-- <th v-if="columnIndex == this.stickyColumns-1">11111</th> -->

              </template>
              
              <!-- <th v-for="item in fields" :key="item.id">
              <div>{{ item.label }}</div>
            </th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in reportData" :key="index">
              <td v-if="editLifting" class="report-table__sticky report-table__col-pencil">
                <div
                  class="record__edit-button"
                  @click="() => {
                    //this.$router.push('/lifting/record/1')

                    //openPopUpForEditRecord(row[3].value)
                    this.$router.push({ name: 'LiftingRecord', params: { rid: row[3].value, open: true } })
                    }"
                ></div>
              </td>
              <template v-for="(item, columnIndex) in clist" :key="item">
                <td
                  :class="[this.styleForCol(item, columnIndex), 'fid' + objFields[item].id]"
                  @click="(objFields[item].id === 1136 
                    || objFields[item].id === 1137
                    || objFields[item].id === 1138
                    || objFields[item].id === 1139
                    || objFields[item].id === 1140
                    || objFields[item].id === 1141
                    || objFields[item].id === 1144
                    || objFields[item].id === 1145
                    || objFields[item].id === 1161) ? showHideFieldsWithColor($event) : undefined"
                  v-if="objFields[item].id !== 752 
                    // && objFields[item].id !== 627
                    // && objFields[item].id !== 628
                    // && objFields[item].id !== 1203
                    // && objFields[item].id !== 1030
                    // && objFields[item].id !== 1031
                    "
                >
                  <div style="width: auto; height: 50px; display: flex; align-items: center;">
                  <!-- <div 
                  @input="(event) => textareaInput(event, index, item)" contentEditable="true">{{ row[item].value }}</div>
                <textarea v-model="row[item].value"></textarea> -->
                  <!-- <textarea
                  v-if="
                    this.objFields[item].fieldType == 'text' ||
                    this.objFields[item].fieldType == 'number'
                  "
                  :value="row[item].value"
                  @input="(event) => textareaInput(event, index, item)"
                ></textarea>
                <a
                  v-else-if="this.objFields[item].fieldType == 'url'"
                  :href="row[item].value"
                  >{{ this.objFields[item].label }}</a
                > -->
                  <div
                    v-if="this.fidsForFFModal.includes(item) && !!row[item].value"
                    class="item-modal report-table__item-modal"
                    @click="() => FFModal(index, item)"
                  >
                    <!-- @click="() => showParentRecords(index, item)" -->

                    <div class="item-modal__title">
                      {{ row[item].value }}
                    </div>
                    <!-- <div class="item-modal__img"></div> -->
                  </div>

                  <div
                    class="report-table__item-formula-wrap modal-contract-type"
                    v-else-if="this.objFields[item].fieldType == 'duration'"
                  >
                    <div class="report-table__item-formula_other">
                      {{
                        row[item].value
                          ? row[item].value / 1000 / 60 / 60 / 24
                          : 0
                      }}
                    </div>
                  </div>

                  <div
                    class="
                      report-table__item-formula-wrap
                      modal-contract-type
                      rich-text__484
                    "
                    v-else-if="item == 484"
                  >
                    <div
                      :data-row="index"
                      :data-fid="item"
                      v-if="this.objFields[item].fieldType == 'rich-text'"
                      v-html="row[item].value"
                    ></div>
                    <div class="report-table__item-formula_other" v-else>
                      {{ row[item].value }}
                    </div>
                  </div>
                  <div
                    class="
                      report-table__item-formula-wrap
                      modal-contract-type
                      rich-text__956
                    "
                    v-else-if="item == 956"
                  > 
                    <div class="contract-type-buyer-name-wrap">

                      <div class="contract-type-wrap">
                        <div
                          :data-row="index"
                          :data-fid="item"
                          class="contract-document-wrap"
                          v-if="this.objFields[item].fieldType == 'rich-text'"
                        >
                          <span class="contract-document" style="cursor: pointer" :key="index" v-for="(value, index) in this.documentsIds(row[item].value)" @click="(e) => {
                              this.ridForEditDocumentReportRecord = index;
                              this.showModalEditDocumentReportRecord = true;
                            }">
                            {{ value }}
                          </span>
                        </div>
                      </div>

                      <template v-for="(item) in clist" :key="item">
                        <div v-if="objFields[item].id === 752" class="buyer-name-wrap">
                          {{ formatMultitext(row[item].value) }}
                        </div>   
                      </template> 

                      <!-- <template v-for="(item) in clist" :key="item">
                        <div v-if="objFields[item].id === 627" class="cnf-start">
                          {{ formatDateFunc(row[item].value) }}
                        </div>   
                      </template> 

                      <template v-for="(item) in clist" :key="item">
                        <div v-if="objFields[item].id === 628" class="cnf-end">
                          {{ formatDateFunc(row[item].value) }}
                        </div>   
                      </template> 

                      <template v-for="(item) in clist" :key="item">
                        <div v-if="objFields[item].id === 1203" class="buyer-name-wrap">
                          {{ row[item].value[0] }}
                        </div>   
                      </template> 

                      <template v-for="(item) in clist" :key="item">
                        <div v-if="objFields[item].id === 1030" class="fob-start">
                          {{ formatDateFunc(row[item].value) }}
                        </div>   
                      </template> 

                      <template v-for="(item) in clist" :key="item">
                        <div v-if="objFields[item].id === 1031" class="fob-end">
                          {{ formatDateFunc(row[item].value) }}
                        </div>   
                      </template>  -->

                    </div>
                  </div>
                  <div
                    class="report-table__item-formula-wrap add tasks"
                    v-else-if="item == 173"
                  >
                    <div
                      :data-row="index"
                      :data-fid="item"
                      v-if="this.objFields[item].fieldType == 'rich-text'"
                      v-html="row[item].value"
                    ></div>
                    <div class="report-table__item-formula_other" v-else>
                      {{ row[item].value }}
                    </div>
                  </div>

                  <div
                    class="report-table__item-formula-wrap add tasks"
                    v-else-if="item == 268"
                  >
                    <div class="report-table__item-formula_other">
                      {{ formatNextTaskName(row[item].value) }}
                    </div>
                  </div>

                  <div
                    class="report-table__item-formula-wrap add add-task"
                    v-else-if="item == 558"
                  >
                    <div v-if="!row[item].value"></div>
                    <div v-else>
                      <div
                        :data-row="index"
                        :data-fid="item"
                        v-if="this.objFields[item].fieldType == 'rich-text'"
                        v-html="row[item].value"
                      ></div>
                      <div class="report-table__item-formula_other" v-else>
                        <a :href="row[item].value" target="_blank"
                          >Google Drive</a
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="report-table__item-formula-wrap add add-task"
                    v-else-if="item == 191"
                  >
                    <div
                      :data-row="index"
                      :data-fid="item"
                      v-if="this.objFields[item].fieldType == 'rich-text'"
                      v-html="row[item].value"
                    ></div>
                  </div>

                  <div
                    class="
                      report-table__item-formula-wrap
                      add
                      qb-update-eta-button
                    "
                    :data-row="index"
                    :data-fid="item"
                    v-else-if="item == 249"
                  >
                    <div
                      :data-row="index"
                      :data-fid="item"
                      v-if="this.objFields[item].fieldType == 'rich-text'"
                      v-html="row[item].value"
                    ></div>
                    <div class="report-table__item-formula_other" v-else>
                      {{ row[item].value }}
                    </div>
                  </div>

                  <div
                    class="
                      report-table__item-formula-wrap
                      add
                      qb-update-eta-button-discharge-port
                    "
                    :data-row="index"
                    :data-fid="item"
                    v-else-if="item == 255"
                  >
                    <div
                      :data-row="index"
                      :data-fid="item"
                      v-if="this.objFields[item].fieldType == 'rich-text'"
                      v-html="row[item].value"
                    ></div>
                    <div class="report-table__item-formula_other" v-else>
                      {{ row[item].value }}
                    </div>
                  </div>

                  <div
                    class="
                      report-table__item-formula-wrap
                      add
                      qb-update-etc-button
                    "
                    :data-row="index"
                    :data-fid="item"
                    v-else-if="item == 252"
                  >
                    <div
                      :data-row="index"
                      :data-fid="item"
                      v-if="this.objFields[item].fieldType == 'rich-text'"
                      v-html="row[item].value"
                    ></div>
                    <div class="report-table__item-formula_other" v-else>
                      {{ row[item].value }}
                    </div>
                  </div>

                  <div
                    class="
                      report-table__item-formula-wrap
                      add
                      qb-update-complete-current-task-button
                    "
                    :data-row="index"
                    :data-fid="item"
                    v-else-if="item == 636"
                  >
                    <div
                      :data-row="index"
                      :data-fid="item"
                      v-if="this.objFields[item].fieldType == 'rich-text'"
                      v-html="row[item].value"
                    ></div>
                    <div class="report-table__item-formula_other" v-else>
                      {{ row[item].value }}
                    </div>
                  </div>

                  <!-- <div
                    v-if="item == 173"
                    class="item-modal report-table__item-modal"
                    @click="() => showParentRecords(index, item)"
                  >
                    <div class="item-modal__title">
                      {{ row[item].value }}
                    </div>
                    <div class="item-modal__img"></div>
                  </div> -->
                  <div v-else-if="this.objFields[item].fieldType == 'multitext'"
                    class="report-table__item-formula-wrap"
                  ><div
                      class="report-table__item"
                      style="
                        display: block;
                        height: 100%;
                        width: 100%;
                        padding: 15px;
                        transition: all .7s linear !important;
                      "
                    >
                      <div>{{ formatMultitext(row[item].value) }}</div>
                    </div></div>
                  <div v-else-if="this.objFields[item].mode == ''">
                    <a
                      v-if="this.objFields[item].fieldType == 'url'"
                      :href="row[item].value"
                      >{{ this.objFields[item].label }}</a
                    >
                    <a
                      v-else-if="this.objFields[item].fieldType == 'dblink'"
                      :href="row[item].value"
                      >{{ this.objFields[item].label }}</a
                    >
                    <div
                      class="report-table__item report-table__item-data-wrap"
                      v-else-if="this.objFields[item].fieldType == 'date'"
                    >
                      {{ formatDateFunc(row[item].value) }}
                      <!-- <DatePicker
                        v-model="row[item].value"
                        :enableTimePicker="false"
                        :format="(t) => formatDateFunc(t)"
                        disabled
                        @update:modelValue="
                          () => handleDate(row[item].value, index, item)
                        "
                      /> -->
                      <!-- <input type="date" v-model="row[item].value" /> -->
                    </div>
                    <div
                      class="report-table__item report-table__item-timestamp-wrap"
                      v-else-if="this.objFields[item].fieldType == 'timestamp'"
                    >
                      {{
                        formatDateTimeFunc(row[item].value, this.objFields[item])
                      }}
                      <!-- <DatePicker v-model="row[item].value" /> -->
                      <!-- <input type="datetime-local" v-model="row[item].value" /> -->
                      <!-- DATETIME -->
                    </div>
                    <div
                      class="report-table__item-boolean-wrap"
                      v-else-if="this.objFields[item].fieldType == 'checkbox'"
                    >
                      <input type="checkbox" disabled v-model="row[item].value" />
                      <!-- {{ row[item].value }} -->
                    </div>
                    <div
                      class="report-table__item-text-multiple-choice-wrap"
                      v-else-if="
                        this.objFields[item].fieldType == 'text-multiple-choice'
                      "
                    >
                      {{ row[item].value }}
                      <!-- <Multiselect
                        v-model="row[item].value"
                        :options="this.objFields[item].properties.choices"
                        clearOnSelect="false"
                        placeholder="Choose"
                        disabled
                        :close-on-select="false"
                      ></Multiselect> -->
                    </div>
                    <div
                      class="report-table__item-wrap"
                      v-else-if="this.objFields[item].fieldType == 'currency'"
                    >
                      <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                      <div
                        class="report-table__item"
                        style="
                          display: block;
                          height: 100%;
                          width: 100%;
                          padding: 15px;
                        "
                      >
                        <div>
                          {{
                            this.currencyFormat(
                              row[item].value,
                              this.objFields[item]
                            )
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="report-table__item-wrap" v-else>
                      <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                      <div
                        class="report-table__item"
                        style="
                          display: block;
                          height: 100%;
                          width: 100%;
                          padding: 15px;
                        "
                      >
                        <div>{{ row[item].value }}</div>
                      </div>
                      <textarea
                        style="display: none"
                        :value="row[item].value"
                        :data-row="index"
                        :data-fid="item"
                        @input="(event) => textareaInput(event, index, item)"
                      ></textarea>
                      <!-- <div class="red">
                        {{ row[item].value }}
                      </div> -->
                      <!-- <button @click="() => showParentRecords(index, item)">
                        Edit parent record
                      </button>
                      <textarea
                        :value="row[item].value"
                        @input="(event) => textareaInput(event, index, item)"
                      ></textarea> -->
                    </div>
                    <!-- <textarea
                      v-else
                      :value="row[item].value"
                      @input="(event) => textareaInput(event, index, item)"
                    ></textarea> -->
                  </div>
                  <div v-else-if="this.objFields[item].mode == 'lookup'">
                    <a
                      v-if="this.objFields[item].fieldType == 'url'"
                      :href="row[item].value"
                      >{{ this.objFields[item].label }}</a
                    >
                    <a
                      v-else-if="this.objFields[item].fieldType == 'dblink'"
                      :href="row[item].value"
                      >{{ this.objFields[item].label }}</a
                    >
                    <div
                      class="report-table__item-wrap"
                      v-else-if="this.objFields[item].fieldType == 'currency'"
                    >
                      <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                      <div
                        class="report-table__item"
                        style="
                          display: block;
                          height: 100%;
                          width: 100%;
                          padding: 15px;
                        "
                      >
                        <div>
                          {{
                            this.currencyFormat(
                              row[item].value,
                              this.objFields[item]
                            )
                          }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="report-table__item report-table__item-data-wrap"
                      v-if="this.objFields[item].fieldType == 'date'"
                    >
                      {{ formatDateFunc(row[item].value) }}
                    </div>
                    <div
                      class="report-table__item report-table__item-data-wrap"
                      v-if="this.objFields[item].fieldType == 'timestamp'"
                    >
                      {{
                        formatDateTimeFunc(row[item].value, this.objFields[item])
                      }}
                    </div>
                    <div class="report-table__item-wrap" v-else>
                      <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                      <div
                        class="report-table__item"
                        style="
                          display: block;
                          height: 100%;
                          width: 100%;
                          padding: 15px;
                        "
                      >
                        <div>{{ row[item].value }}</div>
                      </div>
                      <textarea
                        :data-row="index"
                        :data-fid="item"
                        style="display: none"
                        :value="row[item].value"
                        @input="(event) => textareaInput(event, index, item)"
                      ></textarea>
                      <!-- <div class="green">
                        {{ row[item].value }}
                      </div> -->
                      <!-- <button @click="() => showParentRecords(index, item)">
                        Edit parent record
                      </button>
                      <textarea
                        :value="row[item].value"
                        @input="(event) => textareaInput(event, index, item)"
                      ></textarea> -->
                    </div>
                  </div>
                  <div v-else-if="this.objFields[item].mode == 'formula'"
                    class="report-table__item-formula-wrap"
                  >
                    <div
                      class="report-table__item-formula-rich-text-wrap"
                      v-if="this.objFields[item].fieldType == 'rich-text'"
                      v-html="row[item].value"
                    ></div>
                    <div class="report-table__item-formula_other"
                      v-else-if="this.objFields[item].fieldType == 'date'"
                    >
                      {{ formatDateFunc(row[item].value) }}
                    </div>
                    <div class="report-table__item-formula_other" 
                      v-else
                    >
                      {{ row[item].value }}
                    </div>
                  </div>
                  <div v-else-if="this.objFields[item].mode == 'summary'"
                    class="report-table__item-formula-wrap"
                  >
                    <div
                      class="report-table__item report-table__item-data-wrap"
                      v-if="this.objFields[item].fieldType == 'date'"
                    >
                      {{ formatDateFunc(row[item].value) }}
                    </div>
                    <div
                      class="report-table__item report-table__item-data-wrap"
                      v-else-if="this.objFields[item].fieldType == 'timestamp'"
                    >
                      {{
                        formatDateTimeFunc(row[item].value, this.objFields[item])
                      }}
                    </div>
                    <div
                      class="report-table__item-wrap"
                      v-else-if="this.objFields[item].fieldType == 'currency'"
                    >
                      <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                      <div
                        class="report-table__item"
                        style="
                          display: block;
                          height: 100%;
                          width: 100%;
                          padding: 15px;
                        "
                      >
                        <div>
                          {{
                            this.currencyFormat(
                              row[item].value,
                              this.objFields[item]
                            )
                          }}
                        </div>
                      </div>
                    </div>
                    <div v-else class="report-table__item-formula_other">
                      {{ row[item].value }}
                    </div>
                  </div>
                  <a v-else-if="this.objFields[item].fieldType == 'url'"
                    :href="row[item].value"
                    >{{ this.objFields[item].label }}</a
                  >
                  <div class="report-table__item-wrap" v-else>
                    <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                    <div
                      class="report-table__item"
                      style="
                        display: block;
                        height: 100%;
                        width: 100%;
                        padding: 15px;
                      "
                    >
                      <div>{{ row[item].value }}</div>
                    </div>
                    <textarea
                      style="display: none"
                      :value="row[item].value"
                      :data-row="index"
                      :data-fid="item"
                      @input="(event) => textareaInput(event, index, item)"
                    ></textarea>
                    <!-- <div class="blue">
                      {{ row[item].value }}
                    </div> -->
                    <!-- <button @click="() => showParentRecords(index, item)">
                        Edit parent record
                      </button>
                      <textarea
                        :value="row[item].value"
                        @input="(event) => textareaInput(event, index, item)"
                      ></textarea> -->
                  </div>
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div v-if="report" class="btn-save">
        <div class="btn-save__body">
          <custom-loader v-if="saving" />
          <span v-else>Save</span>
        </div>
      </div> -->
    </div>

    <div v-if="showModalWithParentData" class="modal">
      <div class="modal__mask">
        <div class="modal__wrapper">
          <div class="modal__container">
            <div class="modal__header">
              <div class="modal__title">{{ modalName }}</div>
              <div
                class="modal__close"
                v-on:click="showModalWithParentData = false"
              ></div>
              <!-- <button
                
                class="modal__default-button"
              >
                Close
              </button> -->
            </div>

            <div class="modal__body">
              <div class="switch-bar modal__switch-bar">
                <div
                  class="switch-bar__item"
                  v-bind:class="{
                    'switch-bar__item_active': index == selectedSwitcher,
                  }"
                  v-on:click="selectedSwitcher = index"
                  v-for="(value, index) in switcherBody[masterTableId]"
                  :key="index"
                >
                  {{ index }}
                </div>
                <!-- <div class="switch-bar__item switch-bar__item_active">General</div> -->
                <!-- <div class="switch-bar__item">Freight</div>
                <div class="switch-bar__item">Reminders</div> -->
              </div>
              <div class="switcher-content">
                <div class="switcher-content__choose">
                  <div
                    class="switcher-content__item"
                    v-bind:class="{
                      'switcher-content__item_100': value.width == 100,
                      'switcher-content__item_25': value.width == 25,
                    }"
                    v-for="(value, index) in switcherBody[masterTableId][
                      selectedSwitcher
                    ]"
                    :key="index"
                  >
                    <!-- <div class="switcher-content__title">{{ value }}</div> -->
                    <div class="switcher-content__title">
                      {{ this.objParentFields[value.key].label }}
                    </div>
                    <div class="switcher-content__data">
                      <!-- {{this.parentReportData[value.key]}} -->
                      <!-- {{this.parentReportData[value.key].value}} -->
                      <input
                        type="text"
                        v-model="this.parentReportData[value.key].value"
                        class="switcher-content__data-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- <table class="report-table">
                <tbody>
                  <tr v-for="(value, index) in parentReportData" :key="index">
                    <td>
                      <b>{{ this.objParentFields[index].label }}</b>
                    </td>
                    <td>
                      <textarea :value="value.value"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table> -->
            </div>

            <div class="modal__footer">
              <div
                class="modal__close-button"
                v-on:click="showModalWithParentData = false"
              >
                Cancel
              </div>
              <div class="modal__save-button">Save</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showFFModal" class="modal">
      <div class="modal__mask">
        <div class="modal__wrapper">
          <div class="modal__container">
            <div class="modal__header">
              <div class="modal__title">Freight Fixture</div>
              <div class="modal__close" v-on:click="showFFModal = false"></div>
              <!-- <button
                
                class="modal__default-button"
              >
                Close
              </button> -->
            </div>

            <div class="modal__body">
              <div class="switch-bar modal__switch-bar">
                <div
                  class="switch-bar__item"
                  v-bind:class="{
                    'switch-bar__item_active': index == selectedSwitcher,
                  }"
                  v-on:click="selectedSwitcher = index"
                  v-for="(value, index) in switcherFFBody"
                  :key="index"
                >
                  {{ index }}
                </div>
                <!-- <div class="switch-bar__item switch-bar__item_active">General</div> -->
                <!-- <div class="switch-bar__item">Freight</div>
                <div class="switch-bar__item">Reminders</div> -->
              </div>
              <div class="switcher-content">
                <div class="switcher-content__choose">
                  <div
                    class="switcher-content__item"
                    v-bind:class="{
                      'switcher-content__item_100': value.width == 100,
                      'switcher-content__item_25': value.width == 25,
                    }"
                    v-for="(value, index) in switcherFFBody[selectedSwitcher]"
                    :key="index"
                  >
                    <!-- <div class="switcher-content__title">{{ value }}</div> -->
                    <div class="switcher-content__title">
                      {{
                        this.objFields[
                          this.switcherFFBody[selectedSwitcher][index].key
                        ].label
                      }}
                    </div>
                    <div
                      class="switcher-content__data"
                      v-if="
                        this.FFCheckLookup(
                          this.switcherFFBody[selectedSwitcher][index].key
                        )
                      "
                    >
                      <!-- {{this.parentReportData[value.key]}} -->
                      <!-- {{this.parentReportData[value.key].value}} -->
                      <!-- v-model="this.parentReportData[value.key].value" -->
                      <input
                        type="text"
                        class="switcher-content__data-input"
                        disabled
                        v-model="
                          this.switcherFFBody[selectedSwitcher][index].value
                        "
                      />
                    </div>
                    <div class="switcher-content__data" v-else>
                      <!-- {{this.parentReportData[value.key]}} -->
                      <!-- {{this.parentReportData[value.key].value}} -->
                      <!-- v-model="this.parentReportData[value.key].value" -->
                      <input
                        type="text"
                        class="switcher-content__data-input"
                        @update:modelValue="
                          (e) =>
                            colectDataFF(
                              e,
                              this.switcherFFBody[selectedSwitcher][index].key
                            )
                        "
                        v-model="
                          this.switcherFFBody[selectedSwitcher][index].value
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- <table class="report-table">
                <tbody>
                  <tr v-for="(value, index) in parentReportData" :key="index">
                    <td>
                      <b>{{ this.objParentFields[index].label }}</b>
                    </td>
                    <td>
                      <textarea :value="value.value"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table> -->
            </div>

            <div class="modal__footer">
              <div class="modal__close-button" v-on:click="showFFModal = false">
                Cancel
              </div>
              <div @click="() => saveFFModal()" class="modal__save-button">
                Save
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AddTaskPopUp
      v-if="showModalAddTask"
      :open="showModalAddTask"
      @showStatus="showStatusAddTaskPopUp"
      :rid="ridForAddTask"
    />

    <ShowTasksPopUp
      v-if="showModalTasks"
      :open="showModalTasks"
      :recordKey="keyForTasks"
      @showStatus="showStatusTasksPopUp"
    />

    <ShowContractTypePopUp
      v-if="showModalContractType"
      :open="showModalContractType"
      :recordKey="keyForTasks"
      @showStatus="showStatusContractTypePopUp"
      :reportId="modalContractTypeReportID"
    />

    <EditReportRecord
      v-if="showModalEditReportRecord"
      :open="showModalEditReportRecord"
      @showStatus="showStatusEditReportRecordPopUp"
      :rid="ridForEditReportRecord"
      @updateRecord="updateRecord"
    />

    <EditDocumentReportRecord
      v-if="showModalEditDocumentReportRecord"
      :open="showModalEditDocumentReportRecord"
      @showStatus="showStatusEditDocumentReportRecordPopUp"
      :rid="ridForEditDocumentReportRecord"
      @updateRecord="updateRecord2"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import moment from "moment";
import moment from "moment-timezone";
// import QbReportsItem from "./components/QbReportsItem.vue";

//target="_blank"
import CustomLoader from "../loader/CustomLoader.vue";
// import Multiselect from "@vueform/multiselect";
import AddTaskPopUp from "@/components/pop-up/AddTaskPopUp.vue";
import ShowTasksPopUp from "@/components/pop-up/ShowTasksPopUp.vue";
import ShowContractTypePopUp from "@/components/pop-up/ShowContractTypePopUp.vue";
import EditReportRecord from "@/components/pop-up/EditReportRecord.vue";
import EditDocumentReportRecord from "@/components/pop-up/EditDocumentRecord.vue";

import QuickbaseRepository from "@/api/Quickbase";
import LiftingRepository from "@/api/Lifting";

export default {
  name: "QbReport",
  components: {
    CustomLoader,
    // Multiselect,
    AddTaskPopUp,
    ShowTasksPopUp,
    ShowContractTypePopUp,
    EditReportRecord,
    EditDocumentReportRecord,
  },
  props: {
    editLifting: Number,
    table: String,
    title: String,
    report: String,
    filter: {},
    showBodyProp: Boolean,
    stickyColumns: Number,
  },
  emits: ["getInfo"],
  data: function () {
    return {
      showBody: true,
      //pop-ups
      showModalAddTask: false,
      ridForAddTask: 0,
      showModalTasks: false,
      keyForTasks: "",

      showModalEditReportRecord: false,
      showModalEditDocumentReportRecord: false,
      ridForEditReportRecord: 0,
      ridForEditDocumentReportRecord: 0,

      showModalContractType: false,
      modalContractTypeReportID: 0,

      stableWidth: 0,

      colors: {
        1113: "LightYellow",
        923: "LightYellow",
        1129: "LightYellow",
        1132: "LightYellow",
        1104: "slightlyBlue",
        1105: "slightlyBlue",
        1106: "slightlyBlue",
        1107: "slightlyBlue",
        718: "slightlyBlue",
        1108: "slightlyBlue",
        153: "slightlyGreen",
        1064: "slightlyGreen",
        1065: "slightlyGreen",
        252: "slightlyGreen",
        221: "slightlyGreen",
        788: "slightlyGreen",
        1080: "slightlyGreen",
        107: "slightlyGreen",
        816: "slightlyGreen",
        255: "slightlyGreen",
        156: "slightlyGreen",
        124: "slightlyGreen",
        757: "slightlyGreen",
        154: "slightlyGreen",
        759: "slightlyGreen",
        158: "slightlyGreenAlwaysShow",
        931: "slightlyGreen",
        1170: "slightlyGreen",
        249: "slightlyGreen",
        814: "slightlyGreen",
        1081: "slightlyGreen",
        1167: "slightlyGreen",
        1127: "slightlyGreen",
        102: "slightlyBlue",
        417: "slightlyBlue",
        233: "slightlyBlue",
        419: "slightlyBlueAlwaysShow",
        800: "slightlyBlue",
        355: "LightBlue",
        928: "LightBlue",
        929: "LightBlue",
        356: "LightBlue",
        101: "LightBlue",
        363: "LightBlue",
        364: "LightBlue",
        344: "LightBlue",
        587: "LightBlue",
        345: "LightBlue",
        1217: "LightBlue",
        278: "LightGreen",
        280: "LightGreen",
        282: "LightGreen",
        285: "LightGreen",
        288: "LightGreen",
        290: "LightGreen",
        292: "LightGreen",
        294: "LightGreen",
        563: "LightGreen",
        567: "LightGreen",
        201: "LightGray",
        202: "LightGray",
        203: "LightGray",
        207: "LightGray",
        204: "LightGray",
        1136: "LightBlueAlwaysShow",
        1137: "LightYellowAlwaysShow",
        1138: "slightlyGreenAlwaysShow",
        1139: "slightlyBlueAlwaysShow",
        1140: "LightGrayAlwaysShow",
        1141: "LightVioletAlwaysShow",
        268: "slightlyYellow",
        636: "slightlyYellow",
        173: "slightlyYellow",
        633: "slightlyYellow",
        783: "slightlyYellow",
        639: "slightlyYellow",
        1130: "slightlyYellow",
        1008: "slightlyYellow",
        1142: "slightlyYellow",
        1143: "slightlyYellow",
        1144: "slightlyYellowAlwaysShow",
        956: "LightOrange ",
        752: "LightOrange ",
        // 
        627: "LightOrange ",
        628: "LightOrange ",
        1203: "LightOrange ",
        1030: "LightOrange ",
        1031: "LightOrange ",
        1216: "LightOrange ",
        // 
        1145: "LightOrangeAlwaysShow ",
        1161: "CoralAlwaysShow",
        1164: "Coral",
      },

      showFFModal: false,
      dataFFModalForQB: {},

      chartererNameFilterValue: [],
      reports: [],
      // reportData: [],
      // fields: [],
      // allFields: [],
      // clist: [],
      // relationships: [],
      forSave: {},
      saving: false,
      // gettingReport: true,
      showModalWithParentData: false,

      parentReportData: [],
      parentFields: [],
      parentAllFields: [],

      // fidsForFFModal: [338,365,373,92,339,352,353,375,472,340,341,342,346,486,347,487,343],
      fidsForFFModal: [],

      masterTableId: "",
      switcher: {
        brxivurjh: ["General", "Price", "Differentials", "Reminders"],
        brxix2mgw: ["General", "Freight", "Reminders"],
      },
      selectedSwitcher: "General",
      switcherBody: {
        // mamming for pop-ups
        brxivurjh: {
          General: [
            {
              key: 44,
              width: 50,
            },
            {
              key: 6,
              width: 50,
            },
            {
              key: 24,
              width: 50,
            },
            {
              key: 29,
              width: 50,
            },
            {
              key: 16,
              width: 50,
            },
            {
              key: 60,
              width: 50,
            },
            {
              key: 61,
              width: 50,
            },
            {
              key: 9,
              width: 50,
            },
            {
              key: 15,
              width: 50,
            },
          ],
          Price: [
            {
              key: 38,
              width: 25,
            },
            {
              key: 40,
              width: 25,
            },
            {
              key: 42,
              width: 25,
            },
          ],
          Differentials: [
            {
              key: 27,
              width: 25,
            },
          ],
          Reminders: [
            {
              key: 25,
              width: 25,
            },
          ],
        },
        brxix2mgw: {
          General: [
            {
              key: 18,
              width: 50,
            },
            {
              key: 107,
              width: 50,
            },
            {
              key: 51,
              width: 50,
            },
            {
              key: 45,
              width: 50,
            },
            {
              key: 35,
              width: 25,
            },
            {
              key: 69,
              width: 25,
            },
            {
              key: 70,
              width: 50,
            },
            {
              key: 50,
              width: 25,
            },
            {
              key: 6,
              width: 25,
            },

            {
              key: 29,
              width: 50,
            },
            {
              key: 31,
              width: 25,
            },
            {
              key: 58,
              width: 25,
            },
            {
              key: 82,
              width: 50,
            },
            {
              key: 66,
              width: 25,
            },

            {
              key: 63,
              width: 25,
            },
            {
              key: 83,
              width: 50,
            },
            {
              key: 67,
              width: 25,
            },
            {
              key: 64,
              width: 25,
            },
            {
              key: 65,
              width: 25,
            },

            {
              key: 7,
              width: 25,
            },
            {
              key: 11,
              width: 25,
            },
            {
              key: 46,
              width: 25,
            },
          ],
          Freight: [
            {
              key: 23,
              width: 50,
            },
            {
              key: 72,
              width: 50,
            },
            {
              key: 74,
              width: 25,
            },
            {
              key: 103,
              width: 25,
            },
            {
              key: 105,
              width: 25,
            },
            {
              key: 25,
              width: 25,
            },
          ],
          Reminders: [
            {
              key: 20,
              width: 50,
            },
            {
              key: 17,
              width: 50,
            },
            {
              key: 84,
              width: 100,
            },

            {
              key: 86,
              width: 100,
            },
            {
              key: 99,
              width: 50,
            },
            {
              key: 101,
              width: 50,
            },
          ],
        },
      },

      keyFFModal: 0,
      switcherFF: ["General", "Freight", "Reminders"],
      switcherFFBody: {
        General: [
          {
            key: 338,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 365,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 373,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 92,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 339,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 352,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 353,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 375,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 472,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 340,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 341,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 342,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 346,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 486,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 347,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 487,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 343,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
        ],
        Freight: [
          {
            key: 344,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 355,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 356,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 363,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 364,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 345,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
        ],
        Reminders: [
          {
            key: 349,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 359,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 348,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 360,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 361,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 362,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 350,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 351,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 354,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
        ],
      },
      /* switcherFFBody: {
        General: [
          {
            key: 338,
            width: 50,
          },
        ],
        Freight: [
          {
            key: 365,
            width: 50,
          },
        ],
        Reminders: [
          {
            key: 20,
            width: 50,
          },
          
        ],
      }, */
    };
  },
  watch: {
    report() {
      console.log(this.report);
      this.getQBReport();
    },
    filter() {
      this.getQBReport();
    },

    showBody() {
      this.$nextTick(() => {
        let elements = this.$el.querySelectorAll(".LightBlueAlwaysShow ~ .LightBlue, .LightYellowAlwaysShow ~ .LightYellow, .slightlyBlueAlwaysShow ~ .slightlyBlue, .slightlyGreenAlwaysShow ~ .slightlyGreen, .LightVioletAlwaysShow ~ .LightGreen, .LightGrayAlwaysShow ~ .LightGray, .slightlyYellowAlwaysShow ~ .slightlyYellow, .LightOrangeAlwaysShow ~ .LightOrange, .CoralAlwaysShow ~ .Coral");
        for (const element of elements) {
          element.classList.add('hidden')
        }
      });
    },

    gettingReport(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        let elements = document.querySelectorAll(".LightBlueAlwaysShow ~ .LightBlue, .LightYellowAlwaysShow ~ .LightYellow, .slightlyBlueAlwaysShow ~ .slightlyBlue, .slightlyGreenAlwaysShow ~ .slightlyGreen, .LightVioletAlwaysShow ~ .LightGreen, .LightGrayAlwaysShow ~ .LightGray, .slightlyYellowAlwaysShow ~ .slightlyYellow, .LightOrangeAlwaysShow ~ .LightOrange, .CoralAlwaysShow ~ .Coral");
        for (const element of elements) {
          element.classList.add('hidden')
        }
      }
    }
  },
  async mounted() {
    // this.getQBTables();
    await this.getQBReport();
    this.$nextTick(() => {
      let elements = document.querySelectorAll(".LightBlueAlwaysShow ~ .LightBlue, .LightYellowAlwaysShow ~ .LightYellow, .slightlyBlueAlwaysShow ~ .slightlyBlue, .slightlyGreenAlwaysShow ~ .slightlyGreen, .LightVioletAlwaysShow ~ .LightGreen, .LightGrayAlwaysShow ~ .LightGray, .slightlyYellowAlwaysShow ~ .slightlyYellow, .LightOrangeAlwaysShow ~ .LightOrange, .CoralAlwaysShow ~ .Coral");
      for (const element of elements) {
        element.classList.add('hidden')
      }
    });
    this.showBody = this.showBodyProp;  
  },
  beforeUpdate() {
    // let rts = this.$el.querySelectorAll("table tr");

    /* for (const key in rts) {
      if (Object.hasOwnProperty.call(rts, key)) {
        const trElement = rts[key];
        let tds = trElement.querySelectorAll(".report-table__sticky");
        let left = 0;
        for (const tdKey in tds) {
          if (Object.hasOwnProperty.call(tds, tdKey)) {
            const tdElement = tds[tdKey];
            tdElement.style.left = left + "px";
            tdElement.dataset.test = left + "px";
            // tdElement.style.backgroundColor = "red";
            left += tdElement.offsetWidth;
          }
        }
        // trElement.style.left = "20px";
      }
    } */
  },
  updated() {
    let allAddTaskBtn = this.$el.querySelectorAll(".add-task .Vibrant");
    allAddTaskBtn.forEach((el) => {
      el.onclick = (e) => {
        this.showModalAddTask = true;
        let rowId = e.target.parentElement.dataset.row;
        this.ridForAddTask = this.reportData[rowId][3].value;
        // let fid = e.target.parentElement.dataset.fid;
        return false;
      };
    });

    let allTasksBtn = this.$el.querySelectorAll(".tasks .Vibrant");
    allTasksBtn.forEach((el) => {
      el.onclick = (e) => {
        this.showModalTasks = true;
        this.keyForTasks = this.getKeyForRow(
          e.target.parentElement.dataset.row
        );
        return false;
      };
    });

    let allContractTypeBtn = this.$el.querySelectorAll(
      ".modal-contract-type a"
    );
    allContractTypeBtn.forEach((el) => {
      // el.onclick = (e) => {
      el.onclick = () => {
        /* console.log("test");
        this.keyForTasks = this.getKeyForRow(
          e.target.parentElement.dataset.row
        );
        if (e.currentTarget.innerHTML.toString().indexOf("Cnf") !== -1) {
          this.modalContractTypeReportID = 15;
          console.log("open Cnf");
        } else if (e.currentTarget.innerHTML.toString().indexOf("Fob") !== -1) {
          this.modalContractTypeReportID = 16;
          console.log("open Fob");
        }
        this.showModalContractType = true; */
        return false;
      };
    });

    let qbUpdateEtaButton = this.$el.querySelectorAll(
      ".qb-update-eta-button .Vibrant"
    );
    qbUpdateEtaButton.forEach((el) => {
      el.onclick = (e) => {
        e.preventDefault();
        // let rowId = e.target.parentElement.dataset.row;
        let rowId = e.target.closest('.qb-update-eta-button').dataset.row;
        // this.ridForAddTask = this.reportData[rowId][3].value;
        let rid = this.reportData[rowId][3].value;
        // let fid = e.target.parentElement.dataset.fid;
        this.updateETALoadPort(rid);
        return false;
      };
    });

    let qbUpdateEtaDischargePortButton = this.$el.querySelectorAll(
      ".qb-update-eta-button-discharge-port .Vibrant"
    );
    qbUpdateEtaDischargePortButton.forEach((el) => {
      el.onclick = (e) => {
        e.preventDefault();
        // let rowId = e.target.parentElement.dataset.row;
        let rowId = e.target.closest('.qb-update-eta-button-discharge-port').dataset.row;
        // this.ridForAddTask = this.reportData[rowId][3].value;
        let rid = this.reportData[rowId][3].value;
        // let fid = e.target.parentElement.dataset.fid;
        this.updateETADischargePort(rid);
        return false;
      };
    });

    let qbUpdateEtcButton = this.$el.querySelectorAll(
      ".qb-update-etc-button .Vibrant"
    );
    qbUpdateEtcButton.forEach((el) => {
      el.onclick = (e) => {
        e.preventDefault();
        // let rowId = e.target.parentElement.dataset.row;
        let rowId = e.target.closest('.qb-update-etc-button').dataset.row;
        // this.ridForAddTask = this.reportData[rowId][3].value;
        let rid = this.reportData[rowId][3].value;
        // let fid = e.target.parentElement.dataset.fid;
        this.updateETCLoadPort(rid);
        return false;
      };
    });

    let qbUpdateCompleteCurrentTaskButton = this.$el.querySelectorAll(
      ".qb-update-complete-current-task-button .Vibrant"
    );
    qbUpdateCompleteCurrentTaskButton.forEach((el) => {
      el.onclick = (e) => {
        e.preventDefault();
        // let rowId = e.target.parentElement.dataset.row;
        let rowId = e.target.closest('.qb-update-complete-current-task-button').dataset.row;
        // this.ridForAddTask = this.reportData[rowId][3].value;
        let rid = this.reportData[rowId][3].value;
        // let fid = e.target.parentElement.dataset.fid;
        this.updateCompleteCurrentTask(rid);
        return false;
      };
    });

    let elements = document.querySelectorAll(".LightBlueAlwaysShow ~ .LightBlue, .LightYellowAlwaysShow ~ .LightYellow, .slightlyBlueAlwaysShow ~ .slightlyBlue, .slightlyGreenAlwaysShow ~ .slightlyGreen, .LightVioletAlwaysShow ~ .LightGreen, .LightGrayAlwaysShow ~ .LightGray, .slightlyYellowAlwaysShow ~ .slightlyYellow, .LightOrangeAlwaysShow ~ .LightOrange, .CoralAlwaysShow ~ .Coral");
    for (const element of elements) {
      // element.style.display = "table-cell";
      // element.style.maxWidth = "2550px";
      element.style.maxWidth = "1100px";
      element.style.letterSpacing = "0px";
      element.style.color = "#2d3757";
      // element.style.transition = "max-width .3s linear, padding-left .3s linear, padding-right .3s linear, letter-spacing .3s linear, color .3s linear, left .3s linear";
    }

    let rts = this.$el.querySelectorAll("table tr");

    for (const key in rts) {
      if (Object.hasOwnProperty.call(rts, key)) {
        const trElement = rts[key];
        let tds = trElement.querySelectorAll(".report-table__sticky:not(.hidden)");
        let left = 0;
        for (const tdKey in tds) {
          if (Object.hasOwnProperty.call(tds, tdKey)) {
            const tdElement = tds[tdKey];
            tdElement.style.left = left + "px";
            tdElement.dataset.test = left + "px";
            // tdElement.style.backgroundColor = "red";
            left += tdElement.offsetWidth;
          }
        }
        // trElement.style.left = "20px";
      }
    }
  },
  
  computed: {
    ...mapGetters("liftings", ["getReportById"]),

    reportData() {
      return this.getReportById(this.report).data ?? [];
    },
    fields() {
      return this.getReportById(this.report).fields;
    },
    allFields() {
      return this.getReportById(this.report).allFields;
    },
    clist() {
      return this.getReportById(this.report).clist;
    },
    relationships() {
      return this.getReportById(this.report).relationships;
    },

    gettingReport() {
      return this.getReportById(this.report).loading ?? true;
    },

    modalName() {
      if (this.masterTableId == "brxivurjh") {
        return "Sales Contract";
      } else if (this.masterTableId == "brxix2mgw") {
        return "Freight Fixture";
      } else {
        return "";
      }
    },
    objFields() {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      this.allFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    objParentFields() {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      this.parentAllFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    lookups() {
      let res = [];
      for (const key in this.relationships) {
        if ("lookupFields" in this.relationships[key]) {
          for (const k in this.relationships[key].lookupFields) {
            res.push(this.relationships[key].lookupFields[k].id);
          }
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions('liftings', ["getReport"]),

    calcWidth(tableElem) {
      let allContractTypeWrap = tableElem.querySelectorAll('.contract-type-wrap');
      let count = 0;

      allContractTypeWrap.forEach((elem) => {
        let contractDocuments = elem.querySelectorAll('.contract-document');

        if (contractDocuments.length > count) {
          count = contractDocuments.length;
        }
      });

      allContractTypeWrap.forEach((elem) => {
        if (count === 0) {
          elem.style.width = '85px';
        } else {
          elem.style.width = `${count * 185}px`;
        }
      });
    },

    documentsIds(text) {
      let res = {};
      if(!text) return {};
      const rows = text.split(";");
      for (const key in rows) {
        if (Object.hasOwnProperty.call(rows, key)) {
          const element = rows[key];
          let d = element.split("|");
          res[d[0]] = d[1];
        }
      }
      return res;
    },
    formatNextTaskName(value) {
      if (value) {
        if (value.length == 0) {
          return "";
        } else if (value.length == 1) {
          return value[0];
        } else {
          return value;
        }
      } else {
        return "";
      }
    },
    currencyFormat(value, prop) {
      // currencyFormat(value) {
      if (value) {
        /* let formatedValue = value.toLocaleString("en-US", {
            style: 'currency',
          }); */
        let formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: prop.properties.decimalPlaces,
        });
        return formatter.format(value);
      } else {
        return "";
      }
    },

    formatDateFunc(date) {
      if (date) {
        if (moment(date).year() == moment().year()) {
          return moment(date).format("MMM DD");
        }
        return moment(date).format("MMM DD, yyyy");
      } else {
        return "";
      }
    },

    formatDateTimeFunc(date, props) {
      if (date) {
        if (props["properties"]["displayTime"]) {
          return moment(date).tz("US/Pacific").format("MM-DD-yyyy h:mm a");
        } else {
          if(props["properties"]["displayMonth"] == "name") {
            if(props["properties"]["displayRelative"]) {
              if (moment(date).year() == moment().year()) {
                return moment(date).tz("US/Pacific").format("MMM DD");
              } else {
                return moment(date).tz("US/Pacific").format("MMM DD, yyyy");
              }
            } else {
              return moment(date).tz("US/Pacific").format("MMM-DD-yyyy");
            }
          } else {
            return moment(date).tz("US/Pacific").format("MM-DD-yyyy");
          }
        }
      } else {
        return "";
      }
    },

    formatMultitext(value) {
      if(value) {
        return value.join(", ");
      } else {
        return "";
      }
    },

    styleForCol(fid, rowIndex) {
      let classes = "";
      if (Object.hasOwnProperty.call(this.colors, fid)) {
        classes += this.colors[fid];
      }
      if(rowIndex < this.stickyColumns) {
        classes += "report-table__sticky";
      }
      if(fid == 956) {
        classes += " contract-type-style";
      }
      if(fid == 373) {
        classes += " charterer-name-style";
      }
   
      return classes;
    },

    updateStableWidth($event) {
      // Set stableWidth to be the scoll position, so that if a column collapses there is white space until the user scrolls to the left
      this.stableWidth = $event.target.scrollLeft + $event.target.clientWidth;
    },

    FFCheckLookup(key) {
      if (this.objFields[key].mode == "lookup") return true;
      return false;
    },

    colectDataFF(value, fid) {
      this.dataFFModalForQB[fid] = value;
    },

    saveFFModal() {
      let params = {};
      params["to"] = "brxisk55z";
      let dataTmp = {};
      let willSend = false;
      for (const key in this.dataFFModalForQB) {
        if (Object.hasOwnProperty.call(this.dataFFModalForQB, key)) {
          dataTmp[key] = this.dataFFModalForQB[key];
          dataTmp = true;
        }
      }

      if (willSend) {
        dataTmp["key"] = this.keyFFModal;
        let data = [];
        data.push(dataTmp);
        params["data"] = data;
        this.saveDataForQB(params);
      }

      this.showFFModal = false;
    },

    handleDate(date, rowId, fid) {
      console.log("handleDate(row: " + rowId + " | fid: " + fid + ")");

      let params = {};
      let data = [];
      let dataTmp = {};

      if (this.lookups.includes(fid)) {
        for (const relKey in this.relationships) {
          for (const relLFKey in this.relationships[relKey].lookupFields) {
            if (fid == this.relationships[relKey].lookupFields[relLFKey].id) {
              params["to"] = this.relationships[relKey].parentTableId;

              // get the key of the parent table on backend side
              // console.log("key for parent: " + this.objFields[this.relationships[relKey].parentTableId].properties.masterTableKeyFid);
              for (const key in this.allFields) {
                // if (Object.hasOwnProperty.call(this.allFields, key)) {
                if (
                  this.allFields[key].id == fid &&
                  this.allFields[key].mode == "lookup"
                ) {
                  console.log(
                    this.allFields[key].properties.lookupTargetFieldId
                  );
                  console.log(
                    "key: " +
                      this.reportData[rowId][
                        this.allFields[key].properties.lookupReferenceFieldId
                      ].value
                  );

                  dataTmp["key"] =
                    this.reportData[rowId][
                      this.allFields[key].properties.lookupReferenceFieldId
                    ].value;
                  dataTmp[this.allFields[key].properties.lookupTargetFieldId] =
                    date.getFullYear() +
                    "-" +
                    date.getMonth() +
                    "-" +
                    date.getDate();
                } else if (this.allFields[key].id == fid) {
                  dataTmp["key"] =
                    this.reportData[rowId][
                      this.allFields[key].properties.lookupReferenceFieldId
                    ].value;
                  dataTmp[this.allFields[key].properties.lookupTargetFieldId] =
                    date.getFullYear() +
                    "-" +
                    date.getMonth() +
                    "-" +
                    date.getDate();
                }
                // }
              }
            }
          }
        }
        data.push(dataTmp);
        params["data"] = data;
        // this.saveDataForQB(params);
        // this.saveDataForQB(params);
      } else {
        console.log("not lookup");
        params["to"] = "brxisk55z";
        for (const key in this.allFields) {
          // if (Object.hasOwnProperty.call(this.allFields, key)) {
          if (this.allFields[key].id == fid) {
            console.log("Mode: " + this.allFields[key].mode);
            console.log(this.allFields[key]);
            console.log(this.allFields[key].properties.lookupTargetFieldId);
            console.log("key: " + this.reportData[rowId][3].value);
            dataTmp["key"] = this.reportData[rowId][3].value;
            dataTmp[fid] =
              date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
          }
          // }
        }
        data.push(dataTmp);
        params["data"] = data;
        console.log(params);
        console.log(JSON.stringify(params));
        // this.saveDataForQB(params);
      }
    },

    showStatusEditReportRecordPopUp(status) {
      this.showModalEditReportRecord = status;
    },

    showStatusEditDocumentReportRecordPopUp(status) {
      this.showModalEditDocumentReportRecord = status;
    },

    showStatusTasksPopUp(status) {
      this.showModalTasks = status;
    },

    showStatusAddTaskPopUp(status) {
      this.showModalAddTask = status;
    },

    showStatusContractTypePopUp(status) {
      this.showModalContractType = status;
    },

    async updateRecord(rid) {
      this.getRecordByRidForReport(rid, this.table);
    },

    getRecordByRidForReport(rid, table) {
      QuickbaseRepository.getRecordDataByRecordID(table, rid)
        .then((result) => {
          // this.reportData = result["body"]["data"];
          this.updateRecordByRid(result["data"]["body"]["data"]);
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    async updateRecordByRid(record) {
      let rid = record[3]["value"];

      let rowKeyForUpdate = -1;

      for (const key in this.reportData) {
        if (Object.hasOwnProperty.call(this.reportData, key)) {
          const record = this.reportData[key];
          if (record[3]["value"] == rid) {
            rowKeyForUpdate = key;
          }
        }
      }
      console.log("rowKeyForUpdate: " + rowKeyForUpdate);

      if (rowKeyForUpdate != -1) {
        for (const fid in record) {
          if (Object.hasOwnProperty.call(record, fid)) {
            const fieldWithNewData = record[fid];
            if (
              Object.hasOwnProperty.call(this.reportData[rowKeyForUpdate], fid)
            ) {
              this.reportData[rowKeyForUpdate][fid]["value"] = fieldWithNewData["value"];
            }
          }
        }
      }
    },

    async showModal() {
      this.showModalWithParentData = true;
    },

    FFModal(index) {
      //, item
      this.showFFModal = true;
      console.log(this.reportData[index][3].value);
      this.keyFFModal = this.reportData[index][3].value;
      this.getFFData(this.reportData[index][3].value);
    },

    async getFFData(key) {
      let params = {};
      params["key"] = key;
      params["clist"] = [];

      for (const tabKey in this.switcherFFBody) {
        if (Object.hasOwnProperty.call(this.switcherFFBody, tabKey)) {
          for (const key in this.switcherFFBody[tabKey]) {
            if (Object.hasOwnProperty.call(this.switcherFFBody[tabKey], key)) {
              params["clist"].push(this.switcherFFBody[tabKey][key].key);
            }
          }
        }
      }
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/freight-fixture/query",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          let data = result["body"]["data"];
          if (data.length) {
            data = data.shift();
          }
          console.log(data);

          for (const tabKey in this.switcherFFBody) {
            if (Object.hasOwnProperty.call(this.switcherFFBody, tabKey)) {
              for (const key in this.switcherFFBody[tabKey]) {
                if (
                  Object.hasOwnProperty.call(this.switcherFFBody[tabKey], key)
                ) {
                  if (
                    Object.hasOwnProperty.call(
                      data,
                      this.switcherFFBody[tabKey][key].key
                    )
                  ) {
                    console.log("=======");
                    this.switcherFFBody[tabKey][key].value =
                      data[this.switcherFFBody[tabKey][key].key].value;
                  }
                  params["clist"].push(this.switcherFFBody[tabKey][key].key);
                }
              }
            }
          }
          /* this.reportData = result["body"]["data"];
          this.fields = result["body"]["fields"];
          this.allFields = result["body"]["allFields"];
          this.clist = result["body"]["report"]["query"]["fields"];
          this.relationships = result["body"]["relationships"];
          this.gettingReport = false; */
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
          // this.gettingReport = false;
        });
    },

    showParentRecords(index, item) {
      this.showModal();
      console.log(index, item);
      console.log(this.objFields);
      console.log(this.objFields[item].properties.lookupReferenceFieldId);

      let fieldIdForParentKey =
        this.objFields[item].properties.lookupReferenceFieldId;
      let valueForParentKey = this.reportData[index][fieldIdForParentKey].value;
      let masterTableId =
        this.objFields[fieldIdForParentKey].properties.masterTableId;
      let masterTableKeyFid =
        this.objFields[fieldIdForParentKey].properties.masterTableKeyFid;

      console.log(valueForParentKey);
      console.log(masterTableId);
      this.masterTableId = masterTableId;
      console.log(masterTableKeyFid);

      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN +
          "/v1/qb/query/table/" +
          masterTableId +
          "/key/" +
          masterTableKeyFid +
          "/value/" +
          valueForParentKey,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          this.parentReportData = result["body"]["data"];
          this.parentFields = result["body"]["fields"];
          this.parentAllFields = result["body"]["allFields"];
          // this.clist = result["body"]["report"]["query"]["fields"];
          // this.relationships = result["body"]["relationships"];
          // this.clients = result;
        })
        .catch((error) => console.log("error", error));
    },

    textareaInput(arg, index, item) {

      if (this.lookups.includes(item)) {
        for (const rk in this.reportData) {
          for (const fk in this.reportData[rk]) {
            if (fk == item) {
              for (const key in this.allFields) {
                if (Object.hasOwnProperty.call(this.allFields, key)) {
                  if (
                    this.allFields[key].id == fk &&
                    this.allFields[key].mode == "lookup"
                  ) {
                    // this.allFields[key].properties.lookupReferenceFieldId
                    if (
                      this.reportData[index][
                        this.allFields[key].properties.lookupReferenceFieldId
                      ].value ==
                        this.reportData[rk][
                          this.allFields[key].properties.lookupReferenceFieldId
                        ].value &&
                      !!this.reportData[index][
                        this.allFields[key].properties.lookupReferenceFieldId
                      ].value
                    ) {
                      this.reportData[rk][fk].value = arg.target.value;

                      for (const relKey in this.relationships) {
                        for (const relLFKey in this.relationships[relKey]
                          .lookupFields) {
                          if (
                            item ==
                            this.relationships[relKey].lookupFields[relLFKey].id
                          ) {
                            // let tmp = {};

                            if (
                              this.relationships[relKey].parentTableId in
                              this.forSave
                            ) {
                              this.forSave[
                                this.relationships[relKey].parentTableId
                              ]["key"] =
                                this.reportData[rk][
                                  this.allFields[
                                    key
                                  ].properties.lookupReferenceFieldId
                                ].value;
                              this.forSave[
                                this.relationships[relKey].parentTableId
                              ][
                                this.allFields[
                                  key
                                ].properties.lookupTargetFieldId
                              ] = arg.target.value;
                            } else {
                              this.forSave[
                                this.relationships[relKey].parentTableId
                              ] = {};
                              this.forSave[
                                this.relationships[relKey].parentTableId
                              ]["key"] =
                                this.reportData[rk][
                                  this.allFields[
                                    key
                                  ].properties.lookupReferenceFieldId
                                ].value;
                              this.forSave[
                                this.relationships[relKey].parentTableId
                              ][
                                this.allFields[
                                  key
                                ].properties.lookupTargetFieldId
                              ] = arg.target.value;
                            }
                            // tmp["key"] =
                            //   this.reportData[rk][
                            //     this.allFields[
                            //       key
                            //     ].properties.lookupReferenceFieldId
                            //   ].value;
                            // tmp[
                            //   this.allFields[key].properties.lookupTargetFieldId
                            // ] = arg.target.value;
                            // this.forSave[
                            //     this.relationships[relKey].parentTableId
                            //   ] = tmp;
                            // if (
                            //   this.relationships[relKey].parentTableId in
                            //   this.forSave
                            // ) {
                            //   this.forSave[
                            //     this.relationships[relKey].parentTableId
                            //   ].push(tmp);
                            // } else {
                            //   this.forSave[
                            //     this.relationships[relKey].parentTableId
                            //   ] = [];
                            //   this.forSave[
                            //     this.relationships[relKey].parentTableId
                            //   ].push(tmp);
                            // }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        this.reportData[index][item].value = arg.target.value;
      }
      // this.reportData.forEach((rv, rk) => {
      //   for (const fk in rv) {
      //     if (fk == item) {
      //       this.reportData[rk][fk].value = arg.target.value;
      //     }
      //   }
      //   // Array.from(rv).forEach((fv, fk) => {
      //   // console.log(fv);
      //   // console.log(fk);

      //   //   if(fk == item) {
      //   //     this.reportData[rk][fk].value = arg;
      //   //   }
      //   // });
      // });
    },

    getInfoReport(report) {
      console.log(report);
      this.$emit("getInfo", report);
    },

    getKeyForRow(row) {
      return this.reportData[row][3]["value"];
    },
    replaceTextToEditField(e, index, item) {
      e.currentTarget.style.display = "none";
      e.currentTarget.nextElementSibling.style.display = "block";
      // let test = function() {
      //   console.log(e);
      //   console.log(e.currentTarget);
      //   console.log(e.target);
      //   this.style.display = "block";
      //   this.nextElementSibling.style.display = "none";
      // };
      // test.bind(e.currentTarget);
      // e.currentTarget.nextElementSibling.onblur = test;
      e.currentTarget.nextElementSibling.onblur = (e) => {
        let rowId = e.target.dataset.row;
        let fid = e.target.dataset.fid;
        console.log(
          "OnBlur(row: " +
            e.target.dataset.row +
            " | fid: " +
            e.target.dataset.fid +
            ")"
        );

        e.currentTarget.parentElement.firstElementChild.style.display = "block";
        e.currentTarget.style.display = "none";

        let params = {};
        let data = [];
        let dataTmp = {};

        if (this.lookups.includes(item)) {
          for (const relKey in this.relationships) {
            for (const relLFKey in this.relationships[relKey].lookupFields) {
              if (fid == this.relationships[relKey].lookupFields[relLFKey].id) {
                params["to"] = this.relationships[relKey].parentTableId;
                console.log(this.relationships[relKey].parentTableId);

                // get the key of the parent table on backend side
                // console.log("key for parent: " + this.objFields[this.relationships[relKey].parentTableId].properties.masterTableKeyFid);
                for (const key in this.allFields) {
                  // if (Object.hasOwnProperty.call(this.allFields, key)) {
                  if (
                    this.allFields[key].id == fid &&
                    this.allFields[key].mode == "lookup"
                  ) {
                    console.log("Mode: " + this.allFields[key].mode);
                    console.log(this.allFields[key]);
                    console.log(
                      this.allFields[key].properties.lookupTargetFieldId
                    );
                    console.log(
                      "key: " +
                        this.reportData[rowId][
                          this.allFields[key].properties.lookupReferenceFieldId
                        ].value
                    );

                    dataTmp["key"] =
                      this.reportData[rowId][
                        this.allFields[key].properties.lookupReferenceFieldId
                      ].value;
                    dataTmp[
                      this.allFields[key].properties.lookupTargetFieldId
                    ] = e.target.value;
                  } else if (this.allFields[key].id == fid) {
                    console.log("Mode: " + this.allFields[key].mode);
                    console.log(this.allFields[key]);
                    console.log(
                      this.allFields[key].properties.lookupTargetFieldId
                    );
                    console.log(
                      "key: " +
                        this.reportData[rowId][
                          this.allFields[key].properties.lookupReferenceFieldId
                        ].value
                    );
                    dataTmp["key"] =
                      this.reportData[rowId][
                        this.allFields[key].properties.lookupReferenceFieldId
                      ].value;
                    dataTmp[
                      this.allFields[key].properties.lookupTargetFieldId
                    ] = e.target.value;
                  }
                  // }
                }
              }
            }
          }
          data.push(dataTmp);
          params["data"] = data;
          this.saveDataForQB(params);
          // this.saveDataForQB(params);
        } else {
          params["to"] = "brxisk55z";
          for (const key in this.allFields) {
            // if (Object.hasOwnProperty.call(this.allFields, key)) {
            if (this.allFields[key].id == fid) {
              dataTmp["key"] = this.reportData[rowId][3].value;
              dataTmp[fid] = e.target.value;
            }
            // }
          }
          data.push(dataTmp);
          params["data"] = data;
          this.saveDataForQB(params);
        }
        // e.currentTarget.style.display = "block";
        // e.currentTarget.nextElementSibling.style.display = "none";
      };
      e.currentTarget.nextElementSibling.focus();
    },
    openPopUpForEditRecord(recordId) {
      console.log(recordId);
      this.ridForEditReportRecord = recordId;
      this.showModalEditReportRecord = true;
    },
    async saveDataForQB(params) {
      QuickbaseRepository.pushDataToQB(params)
        .then((result) => {
          console.log(result);
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    async getQBReport() {
      await this.getReport({table: this.table, reportId: this.report, filter: this.filter});
      // this.gettingReport = true;
      // this.gettingReport = false;
      // var requestOptions = {
      //   method: "POST",
      //   redirect: "follow",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({
      //     filter: this.filter,
      //     token: localStorage.token,
      //   }),
      // };

      // fetch(
      //   process.env.VUE_APP_API_DOMAIN +
      //     "/v1/qb/reports/" +
      //     this.report +
      //     "/run?table_id=" +
      //     this.table,
      //   requestOptions
      // )
      //   .then((response) => response.json())
      //   .then((result) => {
      //     console.log(result);
      //     this.reportData = result["body"]["data"];
      //     this.fields = result["body"]["fields"];
      //     this.allFields = result["body"]["allFields"];
      //     this.clist = result["body"]["report"]["query"]["fields"];
      //     this.relationships = result["body"]["relationships"];
      //     this.gettingReport = false;
      //     // this.clients = result;
      //   })
      //   .catch((error) => {
      //     console.log("error", error);
      //     this.gettingReport = false;
      //   });
    },

    showHideFieldsWithColor(event) {
      const currentElem = event.currentTarget
      const nextElem = currentElem.nextElementSibling
      const nextElemStyleName = nextElem.className.split(' ')[0]

      let tableElem = currentElem;

      while (tableElem && tableElem.tagName !== 'TABLE') {
        tableElem = tableElem.parentNode;
      }

      const allElements = tableElem.querySelectorAll(`.${nextElemStyleName}`)

      if (nextElem.classList.contains("hidden")) {
        allElements.forEach(elem => {
          elem.classList.remove('hidden')
        });
      } else {
        allElements.forEach(elem => {
          elem.classList.add('hidden')
        });
      }

      this.calcWidth(tableElem);
    },

    prepNameForColumnTitle(label) {
      let countDash = label.split("-").length - 1;
      if (countDash > 1) {
        let tmp = label.split("-");
        tmp.shift();
        return tmp.join("-").replace(/formula/g, "");
      } else {
        return label.replace(/formula/gi, "");
      }
    },

    updateETALoadPort(rid) {
      LiftingRepository.updateETALoadPort(rid)
        .then((result) => {
          console.log(result);
          this.updateRecord(rid)
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    updateETCLoadPort(rid) {
      LiftingRepository.updateETCLoadPort(rid)
        .then((result) => {
          this.updateRecord(rid)
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    updateETADischargePort(rid) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: localStorage.token,
        }),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/button/eta/DischargePort/record/" + rid,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.updateRecord(rid)
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    updateCompleteCurrentTask(rid) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: localStorage.token,
        }),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN +
          "/v1/qb/button/completeCurrentTask/record/" +
          rid,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.modal-contract-type * {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  text-decoration: none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.report-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.report-body {
  /* height: 100%; */
  /* height: 700px; */
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  margin-top: 10px;
  /* display: flex; */
  padding-bottom: 45px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

td {
  /* border-right: 1px solid #d3dfed; */
  border-bottom: 1px solid #d3dfed;
  /* padding: 15px; */
}

tr td {
  border-right: 1px solid #d3dfed4c;
}

tr td:last-child {
  border-right: 1px solid #d3dfed;
}



thead {
  /* background: #f6faff; */
  background: #fff;
}

tbody {
  /* border-top: 1px solid #d3dfed; */
  /* border-left: 1px solid #d3dfed; */
}

tbody tr:first-child td {
  border-top: 1px solid #d3dfed;
  /* border-left: 1px solid #d3dfed; */
}

tbody tr:first-child td:first-child {
  border-top: none;
  /* border-left: 1px solid #d3dfed; */
}

.report-table {
  /* margin-top: 50px; */
  /* width: 100%; */
  table-layout: fixed;
  border-collapse: collapse;
  /* height: 90vh; */
  position: relative;
}

.report-stablewidth {
  height: 1px;
}

.report__collapse {
  border: 3px solid #d3dfed;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  margin-right: 15px;
  color: #2d3757;
  background: #f6faff;
}

/* .report-table thead {
  background-color: #333;
  color: #fdfdfd;
  position: sticky;
} */

.report-table thead tr {
  /* display: block; */
  position: relative;
}

th,
td {
  /* transition: max-width 10s linear 0s, padding-left 10s linear 0s, padding-right 10s linear 0s, letter-spacing 1s linear 0s, color 10s linear 0s, left 10s linear 0s !important; */
  /* transition: all .7s linear 0s !important; */
  transition: max-width .7s linear 0s, padding-left .7s linear 0s, padding-right .7s linear 0s, letter-spacing .7s linear 0s, color .7s linear 0s, left .7s linear 0s !important;
  /* transition: all .7s linear 0s !important; */
}

th {
  position: sticky;
  z-index: 1;
  border: 0;
  padding: 5px 10px;
  color: rgb(255, 255, 255);
  cursor: default;
  vertical-align: middle;
  /* border-top: 0px;
  border-right: 1px solid rgb(215, 214, 217);
  border-bottom: 1px solid rgb(215, 214, 217); */
  -webkit-font-smoothing: antialiased;
  /* background-color: rgb(120, 117, 124); */
  /* background: #fff; */

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #2d3757;
  /* text-align: left; */

  min-height: 70px;
  box-sizing: border-box;
}

.fid373 {
  min-width: 147px !important;
}

.fid169 {
  min-width: 105px !important;
}

.fid339 {
  min-width: 142px !important;
}

.fid150 {
  min-width: 113px !important;
}

.fid149 {
  min-width: 81px !important;
}

/* .fid628 {
  min-width: 176px !important;
} */

.fid1161 {
  min-width: 57px !important;
}


.report-table .report-table__head th > div {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.report-table td div {
  white-space: nowrap;
}

.report-table__head {
  position: sticky;
  z-index: 1;
}

.btn-save {
  display: flex;
  justify-content: end;
}

.btn-save__body {
  background: rgb(106, 141, 255);
  color: #fff;
  font-size: 1.25em;
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;
}

/* .modal {
  position: absolute;
} */

.modal__mask {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  transition: opacity 0.3s ease;
  padding: 20px;
}

.modal__wrapper {
  margin: 40px;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: 100%;
  /* padding: 20px; */
}

.modal__container {
  /* overflow-y: scroll; */
  /* width: 300px; */
  margin: 0px auto;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-width: 800px;
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d3dfed;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #2d3757;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  margin: 20px 0;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__default-button {
  float: right;
}

.modal__footer {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  margin-right: 15px;
  cursor: pointer;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
}

.preloader {
  display: flex;
  justify-content: center;
}

.report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
}

.item-modal__title2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.modal-contract-type {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  text-decoration: none;
}

.item-modal__img {
  margin-left: 10px;
  height: 16px;
  width: 16px;
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0;
  flex-basis: 16px;
  background: url(@/assets/share.svg) no-repeat center center;
  background-size: 16px 16px;
}

.report-table__item {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  display: block;
  height: 100%;
  width: 100%;
  padding: 15px;
}

.report-table__item-wrap {
  display: flex;
}

.report-table__sticky {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  /* left: 0; */
  /* background: #f6faff; */
  background: #fff;
  /* border: #2d3757 2px solid; */
  /* border-right: 1px solid #CCC; */
  /* border-left: 1px solid #CCC; */
  left: 0;
  z-index: 4;
  display: table-cell !important; 
  box-sizing: border-box;
}

td.report-table__sticky {
  background: #FFF;
}

th.report-table__col-pencil {
  background: #fff;
}
.report-table__sticky > div {
  /* border: #2d3757 2px solid; */
}

.report-table__sticky > div {
  /* border: #2d3757 2px solid; */
}

.report-table__sticky > div::after,
.report-table__sticky > div::before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}

.report-table__sticky > div::before {
  top: -1px;
  border-top: 1px solid #d3dfed;
  /* margin-left: -1px; */
}

th.report-table__sticky > div::before {
  border-top: 0px solid #d3dfed;
  /* margin-left: -1px; */
}

.report-table__sticky > div::after {
  bottom: -1px;
  border-bottom: 1px solid #d3dfed;
}

.report-table__sticky::after,
.report-table__sticky::before {
  content: '';
  position: absolute;
  top: -1px;
  height: 100%;
}

.report-table__sticky::before {
  left: -1px;
  border-left: 1px solid #d3dfed4c;
  /* margin-left: -1px; */
}

.report-table__sticky::after {
  right: -1px;
  border-right: 1px solid #d3dfed4c;
}

th.report-table__sticky::before {
  left: -1px;
  border-left: 1px solid #f6faff4c;
  /* margin-left: -1px; */
}

th.report-table__sticky::after {
  right: 0;
  border-right: 1px solid #f6faff4c;
}


.switch-bar {
  display: flex;
}

.switch-bar__item {
  flex: 1 1 auto;
  text-align: center;
  border-bottom: 1px solid #d3dfed;
  padding: 8px 0;
  cursor: pointer;
}

.switch-bar__item_active {
  border-bottom: 2px solid #2d3757;
}

.switcher-content {
  /* display: flex;
  flex-wrap: wrap; */
}

.switcher-content__choose {
  display: flex;
  flex-wrap: wrap;
}

.switcher-content__item {
  box-sizing: border-box;
  padding: 20px 20px;
  width: 50%;
  min-width: 100px;
}

.switcher-content__item_100 {
  width: 100%;
}

.switcher-content__item_25 {
  width: 25%;
}

.switcher-content__title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;
}

.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  width: 100%;
}

.report-table__item-formula_other {
  /* background: rgba(238, 238, 238, 0.5); */
  padding: 15px;
  margin: 0 5px;
  /* cursor: not-allowed; */
}

.report-table__item-text-multiple-choice-wrap {
  /* min-width: 200px; */
  position: relative;
  padding: 5px 15px;
  /* z-index: 99; */
}

.report-table__item-boolean-wrap {
  position: relative;
  padding: 5px 15px;
  /* z-index: 99; */
}

.multiselect {
  /* z-index: 999; */
}

.multiselect-dropdown {
  /* z-index: 1000; */
}

.red {
  background: red;
}

.green {
  background: green;
}

.blue {
  background: blue;
}

/* .LightOrange {
  background-color: #deecfc;
} */

.LightBlueAlwaysShow {
  background-color: #b9ceeb;
  border-right: 1px solid #d3dfed;
}
.LightYellowAlwaysShow {
  background-color: #FFFFF2;
  border-right: 1px solid #d3dfed;
}

.slightlyGreenAlwaysShow {
  background-color: #c2e5b4;
  border-right: 1px solid #d3dfed;
}

.slightlyBlueAlwaysShow {
  background-color: #deecfc;
  border-right: 1px solid #d3dfed;
}

.LightGrayAlwaysShow {
  background-color: #ECEEEA;
  border-right: 1px solid #d3dfed;
}

.LightVioletAlwaysShow {
  background-color: #c3b4d2;
  border-right: 1px solid #d3dfed;
}

.slightlyYellowAlwaysShow {
  background-color: #f8f4ec;
  border-right: 1px solid #d3dfed;
}

.LightOrangeAlwaysShow {
  background-color: #FEEDE2 !important;
  border-right: 1px solid #d3dfed;
}

.CoralAlwaysShow {
  background-color: #ffc891 !important;
  border-right: 1px solid #d3dfed;
}

.Coral {
  background-color: #ffc891 !important;
}

/*.LightBlueAlwaysShow ~ .LightBlue {
  display: none;
}

.LightYellowAlwaysShow ~ .LightYellow {
  display: none;
}

.slightlyBlueAlwaysShow ~ .slightlyBlue {
  display: none;
}

.slightlyGreenAlwaysShow ~ .slightlyGreen {
  display: none;
}

.LightVioletAlwaysShow ~ .LightGreen {
  display: none;
}

.LightGrayAlwaysShow ~ .LightGray {
  display: none;
}

.slightlyYellowAlwaysShow ~ .slightlyYellow {
  display: none;
}

.LightOrangeAlwaysShow ~ .LightOrange {
  display: none;
}*/

.LightYellow {
  background-color: #FFFFF2;
}

.slightlyBlue {
  background-color: #deecfc;
} 

.LightBlue {
  background-color: #b9ceeb;
}

.slightlyGreen {
  background-color: #c2e5b4;
} 

.slightlyYellow {
  background-color: #f8f4ec;
} 

.LightGreen {
  background-color: #c3b4d2;
}

.LightGray {
  background-color: #ECEEEA;
}
.LightOrange {
  background-color: #FEEDE2 !important;
}

.report-table tbody tr:hover td{
  background: #f2f7fc;
}

.report-table tbody tr:hover td.LightYellow,
.report-table tbody tr:hover td.LightYellowAlwaysShow {
  background-color: #FFFFFF;
}

.report-table tbody tr:hover td.LightOrange,
.report-table tbody tr:hover td.LightOrangeAlwaysShow {
  background-color: #FFFCFB !important;
}

.report-table tbody tr:hover td.slightlyBlue,
.report-table tbody tr:hover td.slightlyBlueAlwaysShow {
  background-color: #F2F7FE;
}

.report-table tbody tr:hover td.LightBlue,
.report-table tbody tr:hover td.LightBlueAlwaysShow {
  background-color: #E9EFF9;
}
/* E9EFF9 */
.report-table tbody tr:hover td.slightlyGreen,
.report-table tbody tr:hover td.slightlyGreenAlwaysShow {
  background-color: #E8F8E0;
}

.report-table tbody tr:hover td.LightGreen,
.report-table tbody tr:hover td.LightVioletAlwaysShow {
  background-color: #EEEAF3;
}

.report-table tbody tr:hover td.LightGray,
.report-table tbody tr:hover td.LightGrayAlwaysShow {
  background-color: #F7F8F7;
}

.report-table tbody tr:hover td.slightlyYellow,
.report-table tbody tr:hover td.slightlyYellowAlwaysShow {
  background-color: #fdfcfa;
}

.report-table tbody tr:hover td.Coral,
.report-table tbody tr:hover td.CoralAlwaysShow {
  background-color: #ffede8 !important;
}

th.slightlyGreen {
  height: 95px !important;
}

th.hidden {
  padding-left: 0 !important;
  padding-right: 0 !important;
  /* transition: padding-left .7s linear, padding-right .7s linear !important; */
}

.hidden {
  max-width: 0 !important;
  overflow: hidden;
  border: none;
  letter-spacing: -3px !important;
  color: transparent !important;
  /* transition: max-width .7s linear, letter-spacing .3s linear, color .3s linear !important; */
}

.hidden .report-table__item {
  color: transparent !important;
  /* transition: color .7s linear !important; */
}
.report-table__sticky.contract-type-style > div {
  width: 158px;
}

.report-table__sticky.contract-type-style .contract-document-wrap {
  /* display: grid;
  gap: 7px;
  grid-template-columns: repeat(1, 1fr); */
  display: block;
}

.contract-document-wrap {
  /* display: grid; */
  gap: 5px;
  /* grid-template-columns: repeat(4, 1fr); */
}

.report-table__sticky.contract-type-style .contract-document:not(:last-child) {
  margin-bottom: 2px;
}

.buyer-name-style {
  white-space: nowrap;
}

.contract-type-buyer-name-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0;
}

.contract-type-buyer-name-wrap > .contract-type-wrap:first-child {
  min-width: 190px;
  width: max-content;
}

th .contract-type-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

th .buyer-name-wrap {
  display: flex;
  justify-content: center;
  border: none;
  flex-grow: 1;
}

.contract-type-wrap {
  
}

.buyer-name-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-left: 1px solid;
  min-width: 100px;
  padding-left: 15px;
}

.cnf-start,
.cnf-end,
.fob-start,
.fob-end {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid;
  min-width: 145px;
  padding-left: 15px;
}

.contract-type-style .buyer-name-wrap,
.contract-type-style .cnf-start,
.contract-type-style .cnf-end,
.contract-type-style .fob-start,
.contract-type-style .fob-end {
  border-color: #d3dfed;
  transition: border-color .7s linear;
}

th .cnf-start,
th .cnf-end,
th .fob-start,
th .fob-end {
  display: flex;
  justify-content: center;
  border: none;
  flex-grow: 1;
}

.contract-type-style.hidden .buyer-name-wrap {
  border-color: transparent;
}

.report-table__item-formula-wrap {
  width: 100%;
}

.report-table tbody tr:hover td.report-table__col-pencil {
  background: none;
}

.report-table tbody tr th.report-table__col-pencil {
  background: #fff;
}

.report-table__col-pencil {
  border: 0;
}

.report-table__sticky > div::after,
.report-table__sticky > div::before {
  border: 0;
}

.report-table tbody tr:hover td.report-table__col-pencil .record__edit-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0;
  flex-basis: 16px;
  background: url(@/assets/icon-pencil.svg) no-repeat center center;
  background-size: 16px 16px;
  cursor: pointer;
}

.report-table tbody tr:hover td.report-table__col-pencil {
  background: #fff;
}

.contract-document {
  cursor: pointer;
  background: #eef0f3;
  display: inline-block;
  border-radius: 15px;
  padding: 3px 10px;
  width: max-content;
}

</style>

<style>
.Vibrant {
  cursor: pointer;
  display: inline-block;
  padding: 5px 15px;
  /* display: block; */
  border-radius: 5px;
  margin: 5px;
  color: #fff;
  text-decoration: none;
}

.OpenAsPopup {
  padding: 5px 15px;
  display: block;
  border-radius: 5px;
  margin: 5px;
  color: #fff;
  text-decoration: none;
}

.record__edit-button {
  width: 32px;
  height: 32px;
  /* background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 16px;
  background: url(@/assets/pencil.png) no-repeat center center;
  background-size: 16px 16px;
  cursor: pointer; */
}

.report__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  color: #2d3757;
  padding-top: 30px;
  padding-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.report-table__item-formula-rich-text-wrap {
  /* padding: 15px; */
  padding-left: 15px;
  padding-right: 15px;
  transition: color .7s linear !important;
}
</style>

<style>
.rich-text__484 div a {
  padding: 8px 8px;
  text-decoration: none;
  /* color: #6c6f7d; */

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  cursor: default;
}

.rich-text__956 {
  height: 100%;
}

.rich-text__956 div {
  padding: 8px 8px;
  text-decoration: none;
  /* color: #6c6f7d; */

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  cursor: default;
}
</style>
