// import { createStore } from 'vuex'

import actions from './actions'
import mutations from './mutations'

const state = {
  step: 0,
  sessionID: null,
  records: [],
}

const getters = {
  step: state => state.step,
  sessionID: state => state.sessionID,
  records: state => state.records,
  getRecordByStep: (state) => () => {
    console.log("getRecordByStep");
    return state.records[state.step];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}