import * as Mutation from './mutation-types'
// import { getEventIndexById } from './utils'

export default {
  [Mutation.ADD_RECORDS] (state, {body}) {
    console.log("Mutation.ADD_RECORDS");
    console.log(body);
    for (const key in body) {
      const element = body[key];
      state.records.push(element);
    }
    // return state.records = body;
    // return state.records;
  },
  [Mutation.GET_RECORDS] (state) {
    return state.records;
  },

  [Mutation.COUNT_RECORDS] (state) {
    return state.records.length;
  },

  [Mutation.SET_STEP] (state, newStep) {
    state.step = newStep;
  },

  [Mutation.SET_SESSION_ID] (state, newSessionID) {
    state.sessionID = newSessionID;
  },
}
