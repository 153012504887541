import * as Mutation from './mutation-types'
import SessionRecord from '@/api/SessionRecord';

export default {
  async getRecordsBySessionID ({ commit }, {sessionID}) {
    try {
      console.log(commit);
      console.log("getSessionRecordReport " + sessionID);
      SessionRecord.getSessionRecordBySessionID(sessionID).then(response => {
        console.log("response getSessionRecordReport " + sessionID);
        console.log(response);
        commit(Mutation.ADD_RECORDS, {body: response.data});
        return Promise.resolve(response.data);
      });
    } catch (error) {
      return Promise.reject(error); // У разі помилки повертаємо відхилений проміс
    }
  },
  setStep({ commit }, newStep) {
    commit(Mutation.SET_STEP, newStep);
  },
  setSessionID({ commit }, newSessionID) {
    commit(Mutation.SET_SESSION_ID, newSessionID);
  }
}
