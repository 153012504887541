import ApiHelper from "@/api/ApiHelper"

export default {
    async update(items) {
        var headers = {
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
            data: items,
        })
        // return Api.put("/v1/ownerSizeZone", 
        // data, headers);

        let url = "/v1/ownerSizeZone";
        return await ApiHelper.getResult(url, data, headers);
    },
}