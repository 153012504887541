import ApiHelper from "@/api/ApiHelper"

export default {
    async getUsers() {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + localStorage.token, },
        };

        let data = JSON.stringify({
        });

        let url = "/v1/users";

        return await ApiHelper.getResult(url, data, headers);
    },
}