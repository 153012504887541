<template>
  <div class="modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <div class="modal__container">
          <div class="modal__header">
            <div class="modal__header-content">
              <div class="modal__title">
                Edit User
              </div>
              <div>
              </div>
              <div class="modal__header-panel">
                <div
                  class="modal__delete-button"
                  v-on:click="() => this.showDeleteConfirmation()">
                  <span>Delete</span>
                </div>
                <div
                  class="modal__close-button"
                  v-on:click="
                    () => this.$router.go(-1)
                  "
                >
                  <div class="close-button__img"></div>
                  <span>Cancel</span>
                </div>
                <div class="modal__save-button" v-on:click="() => this.sendToQB()">
                  <CustomLoader v-if="sendingToQB" />
                  <div v-else class="save-button__body">
                    <div class="save-button__img"></div>
                    <span>Save</span>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="modal__body">
            <div class="switch-bar modal__switch-bar">
              <template>
                <div>
                </div>
              </template>
            </div>
            <div class="switcher-content">
              <div class="switcher-content__choose">
                <div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="field-title">Name</div>
                            <input
                              v-model="formData.name"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="field-title">Role</div>
                            <Multiselect
                              v-model="formData.role"
                              :options="['Admin', 'Client']"
                              :searchable="true"
                            />
                            <!-- <input
                              v-model="formData.role"
                              type="text"
                              class="switcher-content__data-input"
                            /> -->
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="field-title">Approved</div>
                            <input
                              v-model="formData.approved"
                              type="checkbox"
                              class="custom-checkbox"
                              :checked="formData.approved"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="field-title">Email</div>
                            <input
                              v-model="formData.email"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="field-title">Password</div>
                            <input
                              v-model="formData.password"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </td>
                        <td v-if="formData.role == 'Client' && showMultiselect">
                          <div>
                            <div class="field-title">Charterer(s)</div>
                              <div class="switcher-content__data-multiselect">
                                <Multiselect
                                  v-model="formData.charterers"
                                  :options="charterers"
                                  :label="'name'"
                                  :searchable="true"
                                  :multiple="true"
                                  mode="tags"
                                />
                              </div>
                          </div>
                        </td>
                        <td v-if="formData.role == 'Client' && showMultiselect">
                          <div>
                            <div class="field-title">Default Charterer</div>
                              <div class="switcher-content__data-multiselect">
                                <Multiselect
                                  v-model="formData.default_charterer"
                                  :options="charterers"
                                  label="name"
                                  :searchable="true"
                                  :multiple="true"
                                />
                              </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="field-title">Add Lifting</div>
                            <input
                              v-model="formData.add_lifting"
                              type="checkbox"
                              class="custom-checkbox"
                              :checked="formData.add_lifting"
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="field-title">Edit Lifting</div>
                            <input
                              v-model="formData.edit_lifting"
                              type="checkbox"
                              class="custom-checkbox"
                              :checked="formData.edit_lifting"
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="field-title">View Lifting</div>
                            <input
                              v-model="formData.view_lifting"
                              type="checkbox"
                              class="custom-checkbox"
                              :checked="formData.view_lifting"
                            />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
           
              </div>
            </div>
          </div>

          <div class="modal__footer">
            
          </div>
        </div>
      </div>
    </div>

    <DeleteConfirmation
      :on-confirm="confirmDelete"
      :on-cancel="cancelDelete"
      :is-report="false"
      v-if="showConfirmation"
    />

    <UpdateRecordPopUp
      v-if="showUpdateRecordPopUp"
      record-name="User"
      :open="showUpdateRecordPopUp"
      :updatedData="this.updatedData"
    />

  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import CustomLoader from "@/components/loader/CustomLoader.vue";
import UpdateRecordPopUp from "@/components/pop-up/UpdateRecordPopUp.vue";
import DeleteConfirmation from "@/components/pop-up/DeleteConfirmation.vue";


// import { mapState } from "vuex";

// import OwnerFields  from "@/model/contacts/OwnerFields";

// import IndividualsForOwner from "@/components/fields/IndividualsForOwner.vue";

// import IndividualApi from "@/api/tradingPlatform/individual";
// import OwnerApi from "@/api/tradingPlatform/owner";

export default {
  name: "EditUserRecord",
  components: {
    Multiselect,
    CustomLoader,
    UpdateRecordPopUp,
    DeleteConfirmation
  },
  props: {
    // id: Number,
  },
  emits: ["showStatus", "updateRecord"],

  data: function () {
    return {
      id: null,
      charterers: [],
      formData: {
        name: "",
        role: "",
        approved: false,
        add_lifting: false,
        edit_lifting: false,
        view_lifting: false,
        email: "",
        password: "",
        rid: null,
        default_charterer: "",
        charterers: []
      },
       showUpdateRecordPopUp: false,
       updatedData: false,
       showConfirmation: false,
       sendingToQB: false,
       showMultiselect: false,
    };
  },

  mounted() {
    const storedId = localStorage.getItem('userId');
    if (storedId) {
      this.id = Number(storedId);
      this.getData();
    } else {
      console.error('Error');
    }

    // this.getData();

    this.getCharterers();
  },

  updated() {

  },

  computed: {
 
  },

  watch: {

  },

  methods: {

    showDeleteConfirmation() {
      this.showConfirmation = true;
    },

    async confirmDelete() {
      var requestUsers = {
        method: "DELETE",
        redirect: "follow",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.token
        },
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + `/v1/users/${this.id}`,
        requestUsers
      )
        .then((response) => response.json())
        .then((result) => {
          const data = result["data"];
          console.log("data", data);     
        })
        .catch((error) => {
          console.log("Error", error);
        });
      
      this.showConfirmation = false; 
      this.$router.push({ name: 'Users' });
    },

    cancelDelete() {
      this.showConfirmation = false;
    },


    getData() {
      
      console.log("this.id", this.id)

      var requestUsers = {
        method: "GET",
        redirect: "follow",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.token
        },
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + `/v1/users/${this.id}`,
        requestUsers
      )
        .then((response) => response.json())
        .then((result) => {
          this.updateFormData(result["data"]);
          console.log("RecordData", result);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    getCharterers() {
      var requestUsers = {
        method: "GET",
        redirect: "follow",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.token
        },
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + `/v1/fm/charterers`,
        requestUsers
      )
        .then((response) => response.json())
        .then((result) => {
   
          const data = result["data"];

          this.charterers = data.map(elem => ({
            ...elem,
            value: elem.id
          }));

          this.showMultiselect = true;

          console.log("this.charterers", this.charterers);
          
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    async sendToQB() {

      this.sendingToQB = true;

      let data = {
          "name": this.formData.name,
          "role": this.formData.role,
          "email": this.formData.email,
          "approved": this.formData.approved == 1 ? true : false,
          "add_lifting": this.formData.add_lifting == 1 ? true : false,
          "edit_lifting": this.formData.edit_lifting == 1 ? true : false,
          "view_lifting": this.formData.view_lifting == 1 ? true : false,
          "rid": this.formData.rid,
          "charterers": this.formData.role == "Client" ? this.formData.charterers.slice() : "",
          "default_charterer": this.formData.role == "Client" ? this.formData.default_charterer : ""
        }

        if (this.formData.password) {
          data.password = this.formData.password;
        }

        console.log("DATA", data)
        console.log("ID", this.id)

      var requestOptions = {
        method: "PUT",
        redirect: "follow",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.token
        },
        body: JSON.stringify(data), 
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + `/v1/users/${this.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("resultUpdateUser", result);
          this.sendingToQB = false;
          this.updatedData = true;

          this.showUpdateRecordPopUp = true;

          setTimeout(() => {
            this.showUpdateRecordPopUp = false;
            this.$router.go(-1)
          }, 3000);

        })
        .catch((error) => {
          this.sendingToQB = false;
          this.updatedData = false;


          this.showUpdateRecordPopUp = true;

          setTimeout(() => {
            this.showUpdateRecordPopUp = false;
          }, 3000);

          console.log("error", error);
        });

    },

    updateFormData(data) {
      this.formData.name = data.name || "";
      this.formData.role = data.role || "";
      this.formData.approved = data.approved; 
      this.formData.add_lifting = data.add_lifting; 
      this.formData.edit_lifting = data.edit_lifting; 
      this.formData.view_lifting = data.view_lifting; 
      this.formData.email = data.email || "";
      this.formData.password = ""; 
      this.formData.rid = data.rid || null;
      this.formData.charterers = data.charterers || [];
      this.formData.default_charterer = data.default_charterer || "";
    }

  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* @import url('vue-multiselect/dist/vue-multiselect.css'); */
/* * {
  box-sizing: border-box;
} */
* {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
}

.switcher-content * {
  font-size: 14px;
}
.tables-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 20px;
}
.tables-items {
  display: flex;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.modal__mask {
  display: flex;
  flex-grow: 1;
  overflow-y: hidden;
  flex-direction: column;
}

.modal__wrapper {
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.modal__container {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal__container::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.modal__container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.modal__container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.modal__container-loader {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 1680px) {
  .modal__container {
    /* overflow-y: scroll; */
    /* width: 300px; */
    /* width: 1070px; */
  }
}

@media screen and (max-width: 1200px) {
  .modal__container {
    /* overflow-y: scroll; */
    /* width: 300px; */
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    width: 100%;
    /* max-width: 800px; */
  }
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid #d3dfed; */
  background: #F6FAFF;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  flex: 0 0 auto;
}

.modal__header-content {
  padding-top: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  align-items: center;
}

.modal__header-panel {
  display: flex;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #2d3757;
  max-width: 70%;
  justify-items: center;
  text-align: left;
  flex-grow: 1;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  flex: 1 1 auto;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
}

.modal__default-button {
  float: right;
}

.modal__footer {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
  flex: 0 0 auto;
}

.modal__delete-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgba(218, 52, 0, 1)
}

.modal__delete-button:hover {
  color: #fff;
  background: rgba(218, 52, 0, 1);
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.modal__close-button:hover {
  background-color: #50629b;
  color: white;
}

.modal__close-button:hover .close-button__img {
  filter: invert(100%) brightness(1000%);
}

.close-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-close.svg) no-repeat center center;
  margin-right: 10px;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
  border: 1px solid transparent;
  box-sizing: border-box;
  position: relative;
  width: 114px;
}

.modal__save-button:hover {
  background-color: #50629b;
  border: 1px solid transparent;
  outline: none;
}

.save-button__body {
  display: flex;
  align-items: center;
}

.save-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-save.svg) no-repeat center center;
  margin-right: 10px;
}

.preloader {
  display: flex;
  justify-content: center;
}

.report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.item-modal__img {
  margin-left: 12px;
  height: 20px;
  width: 20px;
  background: url(@/assets/share.svg) no-repeat center center;
}

.switch-bar {
  display: flex;
  flex: 0 0 auto;
  background: #F6FAFF;
  padding: 0 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #D3DFED;
}

.switch-bar__item {
  /* flex: 1 1 auto; */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
  margin-right: 15px;
  border: 1px solid transparent;
}

.switch-bar__item:hover {
  background: #ffffff;
  border: 1px solid #5D88BE;
  border-radius: 2px;
}

.switch-bar__item_active {
  /* border-bottom: 2px solid #2d3757; */
  
  background: #D3DFED;
  border: 1px solid #5D88BE;
  border-radius: 2px;
}

.switcher-content {
  flex: 1 1 auto;
  max-height: 100%;
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.switcher-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f9f9fd;
}

.switcher-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.switcher-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.switcher-content__choose {
  /* display: flex; */
  /* flex-wrap: wrap; */
}

.switcher-content__sub-tab-body {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: end; */
  /* overflow: auto; */
  /* overflow-x: auto; */
}

.disabled {
  background: #eee;
}

.switcher-content__sub-tab-title {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  border-bottom: #2d3757 1px solid;
}

.switcher-content__item {
  box-sizing: border-box;
  padding: 20px 20px;
  width: 50%;
  min-width: 100px;
}

.switcher-content__item_100 {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px;
}

.switcher-content__item_25 {
  box-sizing: border-box;
  width: 25%;
  padding: 20px 20px;
}

.switcher-content__title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;
}

.field-title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;

  display: flex;
  align-items: center;
}

.field-title__btn-log {
  cursor: pointer;
  margin-right: 5px;
  border-radius: 50px;
  background:  #2d3757;
  color: #fff;
  padding: 2px 7px;
}

.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  /* width: 100%; */
  font-size: 16px;
  font-family: "Lato";
}

.switcher-content__data-input.checkbox {
  width: auto;
}

.switcher-content__text-data-textarea {
  /* box-sizing: content-box; */
  display: flex;
  box-sizing: border-box;
  vertical-align: bottom;
  /* box-sizing: initial; */
  /* display: table-column; */
  padding: 10px;
  /* word-wrap: break-word; */
  white-space: pre;
  /* padding-bottom: 0; */
  /* width: 100%; */
  font-size: 16px;
  font-family: "Lato";
}

.switcher-content__text-data-textarea.disabled {
  /* resize: none; */
  outline: none;
  /* border: none; */
  /* border-left: 1px solid #777; */
  /* border-right: 1px solid #777; */
  /* border-bottom: 1px solid #777; */
  /* margin: 0; */
  /* padding: 0; */
  /* position: absolute; */
  /* top: 40px; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0;  */
  /* width:-moz-availabel; height:100%;  */
  resize: none;
}

.custom-checkbox {
  appearance: none;
  width: 15px;
  height: 15px;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.custom-checkbox:checked {
  background-color: #3b82f6;
  border-color: #3b82f6;
  box-shadow: 0px 0px 5px 1px rgba(59, 130, 246, 0.7);
}

.custom-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 47%;
  left: 50%;
  width: 2px;
  height: 7px;
  border-right: 2.25px solid white;
  border-bottom: 2.25px solid white;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 1px;
}

.url-btn {
  display: block;
  border: 1px solid #2d3757;
  background: #2d375715;
  text-decoration: none;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  text-align: center;
}
.url-btn:hover {
  color: #fff;
  background: #333;
}

.switcher-content__sub-tab-table {
  /* width: 100%; */
}

.switcher-content__sub-tab-table td {
  padding: 5px;
  border: 5px solid transparent;
  vertical-align: top;
}

/* .switcher-content__sub-tab-table td * {
  box-sizing: border-box;
  max-width: 100%;
} */

.switcher-content__sub-tab-table td .field-title {
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
}


.switcher-content__sub-tab-table td .switcher-content__data-input {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 240px;
  max-width: 240px;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect {
  box-sizing: border-box;
  min-width: 240px;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect-document-filter {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 100%;
  max-width: 320px;
}


.switcher-content__sub-tab-table td .switcher-content__data-input.checkbox {
  width: auto;
}

.switcher-content__text-data-textarea {
  min-width: 240px;
  border: 1px solid #d3dfed;
  border-radius: 2px;
}

.v-toolbar {
  z-index: 20 !important;
}
.multiselect--active {
  z-index: 20 !important;
}
.multiselect__content-wrapper {
  z-index: 20 !important;
}


.btn-log {
}

.btn-log__body {
  display: none;
  width: max-content;
  position: absolute;
  background: #2d3757;
  color: #fff;
  z-index: 5;
  padding: 5px;
  left: 0;
  bottom: 30px;
  border-radius: 2px;
  box-shadow: -1px -1px 10px 0px #2d375780;
  border: 1px solid #d3dfed;
}

.btn-log:hover .btn-log__body {
  display: block;
}

.loader {
  top: 46%;
  left: 46%;
}

</style>

<style>
.rich-text__484 div a {
  padding: 8px 8px;
  text-decoration: none;
  /* color: #6c6f7d; */

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  cursor: default;
}

.dp-custom-input {
  font-size: 14px !important;
  width: auto !important;
}
.v-toolbar {
  z-index: 20 !important;
}
.multiselect--active {
  z-index: 20 !important;
}
.multiselect__content-wrapper {
  z-index: 20 !important;
}

.btn-apply {
  /* display: flex; */
  margin-left: 20px;
  /* margin-top: 1px; */
}

.btn-apply__body {
  /* background: rgb(106, 141, 255); */
  color: #fff;
  /* font-size: 1.25em; */
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;

  background: #2d3757;
  border-radius: 2px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 19px; */

  color: #ffffff;
}

.report-table__item-text-multiple-choice-wrap {
  min-width: 240px;
}

.document-report-wrap {
  /* max-width: 1185px; */
  /* width: 1185px; */
}

/* .report-body {
  width: 1100px;
}

@media screen and (max-width: 1200px) {
  .report-body {
    width: 700px;
  }
} */


.google-drive {
  display: flex;
  align-items: center;
}

.google-drive__left-img {
  width: 16px;
  height: 16px;
  background: url(@/assets/link.svg) no-repeat center center;
  margin-right: 8px;
}

</style>

<style scoped>
.dp__input_wrap .dp__input {
  font-size: 14px;
  padding: 8px 12px;
  padding-left: 35px;
  border: 1px solid #d3dfed !important;
  border-radius: 2px !important;
}

.multiselect {
  min-height: 43px !important;
  border: 1px solid #d3dfed !important;
  border-radius: 2px !important;
}
</style>

<style scoped src="@vueform/multiselect/themes/default.css"></style>