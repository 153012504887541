<template>
  <div class="header-gorup-menu">
    <div v-if="this.isAdmin" class="header-left-bar">
      <div
        class="header-bar__item"
        v-bind:class="{
          'header-bar__item_active': this.page.logout === activePage,
        }"
      >
        <!-- <div class="header-bar__item-img header-bar__item-img_user"></div> -->
        <div class="header-bar__item-text">Opportunities</div>
        <div class="header-bar__item-img header-bar__item-img_right"></div>
        <div class="menu__wrap">
          <div class="menu">
            <div class="menu__body">
              <router-link
                to="/opportunities/cargoes"
                style="text-decoration: none"
              >
                <div class="menu__item">
                  <!-- <div class="menu__item-img"></div> -->
                  <div class="header-bar__sub-item-text">Cargoes</div>
                </div>
              </router-link>

              <router-link
                to="/opportunities/offers"
                style="text-decoration: none"
              >
                <div class="menu__item">
                  <!-- <div class="menu__item-img"></div> -->
                  <div class="header-bar__sub-item-text">Offers</div>
                </div>
              </router-link>

              <router-link
                to="/opportunities/voyages"
                style="text-decoration: none"
              >
                <div class="menu__item">
                  <!-- <div class="menu__item-img"></div> -->
                  <div class="header-bar__sub-item-text">Voyages</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class="header-bar__item"
        v-bind:class="{
          'header-bar__item_active': this.page.logout === activePage,
        }"
      >
        <!-- <div class="header-bar__item-img header-bar__item-img_user"></div> -->
        <div class="header-bar__item-text">Contacts</div>
        <div class="header-bar__item-img header-bar__item-img_right"></div>
        <div class="menu__wrap menu-wrap-contact">
          <div class="menu">
            <div class="menu__body">
              <router-link to="/contacts/owners" style="text-decoration: none">
                <div class="menu__item">
                  <!-- <div class="menu__item-img"></div> -->
                  <div class="header-bar__sub-item-text">Owners</div>
                </div>
              </router-link>

              <router-link
                to="/contacts/individuals"
                style="text-decoration: none"
              >
                <div class="menu__item">
                  <!-- <div class="menu__item-img"></div> -->
                  <div class="header-bar__sub-item-text">Individuals</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else></div>
    <div class="header-bar header__bar">
      <div
        class="header-bar__item"
        v-bind:class="{
          'header-bar__item_active': this.page.summary === activePage,
        }"
        @click="() => this.$router.push('/')"
      >
        <div class="header-bar__item-img header-bar__item-img_list"></div>
        <div class="header-bar__item-text">Summary</div>
      </div>
      <div
        class="header-bar__item"
        v-bind:class="{
          'header-bar__item_active': this.page.calendar === activePage,
        }"
        @click="() => this.$router.push('/calendar')"
      >
        <div class="header-bar__item-img header-bar__item-img_calendar"></div>
        <div class="header-bar__item-text">Calendar</div>
      </div>
      <div
        class="header-bar__item"
        v-bind:class="{
          'header-bar__item_active': this.page.binco === activePage,
        }"
        @click="() => (this.showModalAddBimcoHolidays = true)"
      >
        <div class="header-bar__item-img header-bar__item-img_flag"></div>
        <div class="header-bar__item-text">Bimco Holidays</div>
      </div>
      <div v-if="this.isAdmin"
        class="header-bar__item"
        v-bind:class="{
          'header-bar__item_active': this.page.reports === activePage,
        }"
      >
        <div class="header-bar__item-img header-bar__item-img_settings"></div>
        <div class="header-bar__item-text">Set Up</div>
        <div class="header-bar__item-img header-bar__item-img_right"></div>
        <div class="menu__wrap menu-wrap-report ">
          <div class="menu">
            <div class="menu__body">
              <router-link to="/generator/reports" style="text-decoration: none">
                <div class="menu__item">
                  <div class="setup-reports-img"></div>
                  <div class="header-bar__sub-item-text">Setup Reports</div>
                </div>
              </router-link>

              <router-link
                to="/users"
                style="text-decoration: none"
              >
                <div class="menu__item">
                  <div class="setup-users-img"></div>
                  <div class="header-bar__sub-item-text">Setup Users</div>
                </div>
              </router-link>

              <router-link
                to="/charterers"
                style="text-decoration: none"
              >
                <div class="menu__item">
                  <div class="setup-users-img"></div>
                  <div class="header-bar__sub-item-text">Setup Charterers</div>
                </div>
              </router-link>

              <router-link
                to="/session-records"
                style="text-decoration: none"
              >
                <div class="menu__item">
                  <div class="setup-users-img"></div>
                  <div class="header-bar__sub-item-text">Session Records</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class="header-bar__item log-out"
        v-bind:class="{
          'header-bar__item_active': this.page.logout === activePage,
        }"
      >
        <div class="header-bar__item-img header-bar__item-img_user"></div>
        <div class="header-bar__item-text">{{ userEmail }}</div>
        <div class="header-bar__item-img header-bar__item-img_right"></div>
        <div class="menu__wrap menu__wrap_align-right">
          <div class="menu">
            <div class="menu__body">
              <div class="menu__item" @click="() => logout()">
                <div class="menu__item-img"></div>
                <div class="header-bar__item-text">Sign Out</div>
              </div>
            </div>
            <div class="menu__footer">© 2024. Newton Commodities.</div>
          </div>
        </div>
      </div>
    </div>
    <ShowBimcoHolidaysPopUp
      v-if="showModalAddBimcoHolidays"
      :open="showModalAddBimcoHolidays"
      :recordKey="keyForTasks"
      @showStatus="showStatusAddBimcoHolidaysPopUp"
    />
  </div>
</template>

<script>
import ShowBimcoHolidaysPopUp from "@/components/pop-up/ShowBimcoHolidaysPopUp.vue";

export default {
  name: "HeaderMenu",
  components: {
    ShowBimcoHolidaysPopUp,
  },
  props: {},
  data: function () {
    return {
      showModalAddBimcoHolidays: false,
      page: {
        binco: "binco",
        summary: "summary",
        calendar: "calendar",
        logout: "logout",
        reports: "reports",
      },
    };
  },
  watch: {},
  mounted() {},
  computed: {
    role() {
      if (localStorage.role) {
        return localStorage.role;
      } else {
        return "";
      }
    },
    isAdmin() {
      if (this.role == "Admin") {
        return true;
      } else {
        return false;
      }
    },
    currentRouteName() {
      return this.$route.path;
      // return this.$route.name;
    },
    activePage() {
      if (this.showModalAddBimcoHolidays) return this.page.binco;
      switch (this.currentRouteName) {
        case "/":
          return this.page.summary;
        case "/calendar":
          return this.page.calendar;
        case "/logout":
          return this.page.logout;
        case "/generator/reports":
          return this.page.reports;
        default:
          return this.page.summary;
      }
    },
    userEmail() {
      return localStorage.email;
    },
  },
  methods: {
    logout() {
      localStorage.token = "";
      this.$router.push("/signin");
    },
    showStatusAddBimcoHolidaysPopUp(status) {
      this.showModalAddBimcoHolidays = status;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="@/styles/components/menu/header-menu.css"></style>
