import Api from "./Api";

export default {
    async getSessionRecords() {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { 
                "Content-Type": "application/json", 
                Authorization: "Bearer " + localStorage.token, 
            },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        return Api.get("/v1/session-records", 
        data, headers);
    },
    async getSessionRecordBySessionID(sessionID) {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { 
                "Content-Type": "application/json", 
                Authorization: "Bearer " + localStorage.token, 
            },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        return Api.get("/v1/session-records/" + sessionID, 
        data, headers);
    },
}