import Api from "./Api";

export default {
    reportById(reportId) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            // filter: this.filter,
            table_id: "bstkuuphz",
            token: "12345",
        })

        return Api.post("/v1/qb/reports/" + reportId + "/bh/run", data, headers);
    }
}