import ApiHelper from "@/api/ApiHelper"

export default {
    async getCalendarRecords(table, reportId, filter) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + localStorage.token, },
        };

        let data = JSON.stringify({
            filter: filter,
            token: localStorage.token,
        })

        // return Api.post("/v1/qb/trading/reports/" + reportId + "/run?table_id=" + table, 
        // data, headers);

        let url = "/v1/calendar";
        return await ApiHelper.getResult(url, data, headers);
    },
}