import { createStore, createLogger } from 'vuex'
import holidays from './modules/holidays/index'
import liftings from './modules/liftings/index'
import cargoes from './modules/tradingPlatform/cargoes/index'
import offers from './modules/tradingPlatform/offers/index'
import voyages from './modules/tradingPlatform/voyages/index'
import owners from './modules/tradingPlatform/owners/index'
import individuals from './modules/tradingPlatform/individuals/index'
import sessionRecord from './modules/sessionRecord/index'
// import products from './modules/products'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    sessionRecord,
    holidays,
    liftings,
    cargoes,
    offers,
    voyages,
    owners,
    individuals,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})