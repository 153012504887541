<template>
  <div class="popup-wrap">
    <div class="popup-content">
      <div class="modal-title" v-if="recordDeleted">{{ recordName }} was deleted successfully.</div>
      <div class="modal-title" v-else>Something went wrong. Record was not deleted.</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    recordDeleted: Boolean,
    recordName: String,
  },

  data: function () {
    return {

    };
  },

  mounted() {

  },

  updated() {

  },

  computed: {

  },

  watch: {
  
  },

  methods: {

  },
};
</script>

<style scoped>
.popup-wrap {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.popup-content {
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
}

.modal-title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 48px;
  color: #2d3757;
}

</style>