import socketService from "@/service/SocketService";
import Api from "./Api";
import app from "@/main";
import store from "@/store";

class ApiHelper {
    static async getResult(url, data, headers) {
        let sessionID = store.state.sessionRecord.sessionID;

        if (!sessionID) {
            const routeFullPath = app.config.globalProperties.$getRoute().fullPath;

            let response = null;

            if (headers.method == "POST") {
                response = await Api.post(url,
                    data, headers);
            } else if (headers.method == "PUT") {
                response = await Api.put(url,
                    data, headers);
            } else if (headers.method == "DELETE") {
                response = await Api.delete(url, headers);
            } else if (headers.method == "GET") {
                response = await Api.get(url, headers);
            } else {
                response = await Api.get(url, headers);
            }


            console.log("websocket test " + url);
            console.log({
                url: url,
                method: headers.method,
                response: JSON.stringify(response),
                request_header: JSON.stringify(headers),
                request_body: JSON.stringify(data),
                route_full_path: routeFullPath,
            });
            socketService.emit("session-record-request", {
                url: url,
                method: headers.method,
                response: JSON.stringify(response),
                request_header: JSON.stringify(headers),
                request_body: JSON.stringify(data),
                route_full_path: routeFullPath,
            });

            return response;
        } else {
            let sessionRecordID = store.getters['sessionRecord/getRecordByStep']().id;

            let response = await Api.get(`/v1/session-record/request?url=${url}&session_id=${sessionID}&session_record_id=${sessionRecordID}&method=${headers.method}`,
                data, headers);
            return JSON.parse(response.data.response);
        }
    }
}

export default ApiHelper;