import ApiHelper from "@/api/ApiHelper"

export default {
    async reportById(table, reportId, filter) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + localStorage.token, },
        };

        let data = JSON.stringify({
            filter: filter,
            token: localStorage.token,
        })

        // return Api.post("/v1/qb/trading/reports/" + reportId + "/run?table_id=" + table, 
        // data, headers);
        let url = "/v1/qb/trading/reports/" + reportId + "/run?table_id=" + table;
        return await ApiHelper.getResult(url, data, headers);
    },
    async updateOffers(offers) {
        var headers = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
            offers: offers,
        })
        // return Api.put("/v1/offers", 
        // data, headers);

        let url = "/v1/offers";
        return await ApiHelper.getResult(url, data, headers);
    },
    async addOffers(offers) {
        var headers = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
            offers: offers,
        })
        // return Api.post("/v1/offers", 
        // data, headers);

        let url = "/v1/offers";
        return await ApiHelper.getResult(url, data, headers);
    },

    async deleteByRid(rid) {
        var headers = {
            method: "DELETE",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        // return Api.delete("/v1/offers/rid/" + rid, 
        // data, headers);

        let url = "/v1/offers/rid/" + rid;
        return await ApiHelper.getResult(url, data, headers);
    },

    async getOffersByCargoId(id) {
        var headers = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        // return Api.get("/v1/cargos/" + id + "/offers", 
        // data, headers);

        let url = "/v1/cargos/" + id + "/offers";
        return await ApiHelper.getResult(url, data, headers);
    },

    async addOfferQuotes(quotes) {
        var headers = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
            quotes: quotes,
        })
        // return Api.post("/v1/offers/quotes", 
        // data, headers);

        let url = "/v1/offers/quotes";
        return await ApiHelper.getResult(url, data, headers);
    },
}