import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import ReportView from '../views/ReportView.vue'
import ReportGeneratorMainView from '../views/reportGenerator/MainView.vue'
import ReportGeneratorCreateView from '../views/reportGenerator/CreateView.vue'
import ReportGeneratorEditView from '../views/reportGenerator/EditView.vue'
import MainView from '../views/MainView.vue'
import CalendarView from '../views/CalendarView.vue'
import Singin from '../views/SigninView.vue'

import CreateLiftingRecord from '../components/create/liftings/CreateLiftingRecord.vue'

import LiftingRecord from '../components/pop-up/EditReportRecord.vue'
import IndividualRecord from '../components/edit/contacts/IndividualRecord.vue'
import OwnerRecord from '../components/edit/contacts/OwnerRecord.vue'
import OfferRecord from '../components/edit/oportunities/OfferRecord.vue'
import VoyageRecord from '../components/edit/oportunities/VoyageRecord.vue'
import CargoRecord from '../components/edit/oportunities/CargoRecord.vue'

import CreateCargoRecord from '../components/create/oportunities/CargoRecord.vue'
import CreateOfferRecord from '../components/create/oportunities/OfferRecord.vue'
import CreateVoyageRecord from '../components/create/oportunities/VoyageRecord.vue'
import CreateOwnerRecord from '../components/create/contacts/OwnerRecord.vue'
import CreateIndividualRecord from '../components/create/contacts/IndividualRecord.vue'

import CargoesView from '../views/oportunities/CargoesView.vue'
import OffersView from '../views/oportunities/OffersView.vue'
import VoyagesView from '../views/oportunities/VoyagesView.vue'
import IndividualsView from '../views/contacts/IndividualsView.vue'
import OwnersView from '../views/contacts/OwnersView.vue'

import Users from '../views/users/UsersView.vue'
import CreateUserRecord from '../views/users/CreateUserRecord.vue'
import EditUserRecord from '../views/users/EditUserRecord.vue'

import Charterers from '../views/charterers/CharterersView.vue'
import CreateCharterersRecord from '../views/charterers/CreateCharterersRecord.vue'
import EditCharterersRecord from '../views/charterers/EditCharterersRecord.vue'

import SessionRecordsView from '../views/sessionRecord/MainView.vue'

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainView
  },
  {
    path: '/signin',
    name: 'Singin',
    component: Singin
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportView
  },
  {
    path: '/generator/reports',
    name: 'reportGeneratorMainView',
    component: ReportGeneratorMainView
  },
  {
    path: '/generator/report/create',
    name: 'reportGeneratorCreateView',
    component: ReportGeneratorCreateView
  },
  {
    path: '/generator/report/:id/edit',
    name: 'reportGeneratorEditView',
    component: ReportGeneratorEditView,
    props: true,
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/lifting/record/:rid',
    name: 'LiftingRecord',
    component: LiftingRecord,
    props: true
  },

  {
    path: '/opportunities/cargoes',
    name: 'cargoes-view',
    component: CargoesView
  },
  {
    path: '/opportunities/cargo/record/:rid',
    name: 'CargoRecord',
    component: CargoRecord,
    props: true
  },

  {
    path: '/opportunities/offers',
    name: 'offers-view',
    component: OffersView
  },
  {
    path: '/opportunities/offers/record/:rid',
    name: 'OfferRecord',
    component: OfferRecord,
    props: true
  },

  {
    path: '/opportunities/voyages',
    name: 'voyages-view',
    component: VoyagesView
  },
  {
    path: '/opportunities/voyages/record/:rid',
    name: 'VoyageRecord',
    component: VoyageRecord,
    props: true
  },

  {
    path: '/contacts/owners',
    name: 'owners-view',
    component: OwnersView
  },
  {
    path: '/contacts/owners/record/:rid',
    name: 'OwnerRecord',
    component: OwnerRecord,
    props: true
  },

  {
    path: '/contacts/individuals',
    name: 'individuals-view',
    component: IndividualsView
  },
  {
    path: '/contacts/individuals/record/:rid',
    name: 'IndividualRecord',
    component: IndividualRecord,
    props: true
  },
  
  // Create
  {
    path: '/opportunities/cargo/create',
    name: 'CreateCargoRecord',
    component: CreateCargoRecord,
  },
  {
    path: '/opportunities/offer/create',
    name: 'CreateOfferRecord',
    component: CreateOfferRecord,
    props: true
  },
  {
    path: '/opportunities/voyage/create',
    name: 'CreateVoyageRecord',
    component: CreateVoyageRecord,
    props: true
  },

  {
    path: '/contacts/individuals/create',
    name: 'CreateIndividualRecord',
    component: CreateIndividualRecord,
    props: true
  },

  {
    path: '/contacts/owners/create',
    name: 'CreateOwnerRecord',
    component: CreateOwnerRecord,
    props: true
  },

  // Users
  {
    path: '/users',
    name: 'Users',
    component: Users,
    props: true
  },
  {
    path: '/users/create',
    name: 'CreateUserRecord',
    component: CreateUserRecord,
    props: true
  },
  {
    path: '/users/edit',
    name: 'EditUserRecord',
    component: EditUserRecord,
    props: true
  },

  // Charterers
  {
    path: '/charterers',
    name: 'Charterers',
    component: Charterers,
    props: true
  },
  {
    path: '/charterers/create',
    name: 'CreateCharterersRecord',
    component: CreateCharterersRecord,
    props: true
  },
  {
    path: '/charterers/edit',
    name: 'EditCharterersRecord',
    component: EditCharterersRecord,
    props: true
  },

   // Liftings
  {
    path: '/lifting/create',
    name: 'CreateLiftingRecord',
    component: CreateLiftingRecord,
    props: true
  },

  // Session Records
  {
    path: '/session-records',
    name: 'SessionRecords',
    component: SessionRecordsView,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
